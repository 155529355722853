import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import 'moment-timezone';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
	Button,
	DialogContent,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import { AccessTime, Close, ExpandMore } from '@material-ui/icons';

import { convertTimeToInputTime } from '../../services/timeStampService';
import { generateUUID } from '../../utils/commonUtil';
import { getLastIndexCatalogId } from '../../services/locationPathServices';
import { workflowTypes } from '../../constants/workflowTypes';
import { createLiveMediaFromChannel } from '../../actions/workflowActions';
import { useDispatch, useSelector } from 'react-redux';
import { messageTypes } from '../../constants/mediaConstants';
import { showMessage } from '../../actions/globalActions';

import './ScheduleLiveEventDialog.scss';
import { refreshMedias } from '../../actions/publishActions';

interface ScheduleLiveEventDialogProps {
	open: boolean;
	liveChannels?: any[];
	liveEventTimeSlot?: any;
	onClose?: () => void;
	onScheduleEvent?: (_id: string) => void;
}

const baseClassName = 'schedule-live-event';
const defaultTZ = moment.tz.guess();
moment.tz.setDefault(defaultTZ);

const ScheduleLiveEventDialog = ({
	open,
	liveChannels,
	onClose,
	onScheduleEvent,
	liveEventTimeSlot,
}: ScheduleLiveEventDialogProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const { defaultAccountId } = useSelector((state) => (state as any).session);
	const notLegacyChannels = (liveChannels ?? []).filter((c) => !c?.id?.includes('legacy'));

	// const [selectedCoverTab, setSelectedCoverTab] = useState<string>('Thumbnail');
	const [selectedChannel, setSelectedChannel] = useState<any | undefined>(notLegacyChannels[0]);
	const [eventTitle, setEventTitle] = useState<string>('');
	const [eventDescription, setEventDescription] = useState<string>('');

	const [eventStartDate, setEventStartDate] = useState<Date>(
		liveEventTimeSlot?.start ? moment(liveEventTimeSlot?.start).toDate() : new Date()
	);
	const [eventStartTime, setEventStartTime] = useState<string>("");
	const [eventDuration, setEventDuration] = useState<string>('02:00');

	const handleOnChannelChange = useCallback(
		(event: React.ChangeEvent<{}>) => {
			const selectedChannelId = (event.target as any).value;
			if (selectedChannel?.id === selectedChannelId) {
				return;
			}
			const newSelectedChannel = (liveChannels ?? []).find((c) => c?.id === selectedChannelId);
			setSelectedChannel(newSelectedChannel);
		},
		[liveChannels, selectedChannel?.id]
	);
	const handleOnTitleChange = useCallback(
		(event: React.ChangeEvent<{}>) => setEventTitle((event.target as any).value),
		[]
	);
	const handleOnDescriptionChange = useCallback(
		(event: React.ChangeEvent<{}>) => setEventDescription((event.target as any).value),
		[]
	);

	const handleEventStartDateChange = (date: Date | null) => {
		if (!date) {
			return;
		}
		setEventStartDate(date);
	};

	const handleEventStartTimeChange = (event: React.ChangeEvent<{}>) => {
		setEventStartTime((event.target as any).value);
	};

	const handleOnDurationChange = (event: React.ChangeEvent<{}>) => {
		setEventDuration((event.target as any).value);
	};

	const resetEventForm = useCallback(() => {
		setSelectedChannel(notLegacyChannels[0]);
		setEventDescription('');
		setEventTitle('');
		const eventDate = new Date();
		setEventStartDate(eventDate);
		setEventStartTime(convertTimeToInputTime(`${eventDate.getHours()}:${eventDate.getMinutes()}`));
		setEventDuration('02:00');
	}, [notLegacyChannels]);

	const handleCloseDialog = useCallback(() => {
		onClose?.();
		resetEventForm();
	}, [onClose, resetEventForm]);

	const handleScheduleEvent = useCallback(() => {
		if (!eventTitle || !selectedChannel?.id) {
			return;
		}

		let mediaId = generateUUID();
		let selectedCatalog = getLastIndexCatalogId();

		const eventStartTimeArray = eventStartTime.split(':');
		const finalEventStartDate = eventStartDate;
		finalEventStartDate.setHours(parseInt(eventStartTimeArray[0]));
		finalEventStartDate.setMinutes(parseInt(eventStartTimeArray[1]));

		let body = {
			workflow: {
				id: workflowTypes.CREATE_LIVE_EVENT_FROM_CHANNEL.ID,
				scope: workflowTypes.CREATE_LIVE_EVENT_FROM_CHANNEL.SCOPE,
			},
			input: {
				channelId: selectedChannel?.id,
				mediaId: mediaId,
				mediaTitle: eventTitle,
				mediaDescription: eventDescription,
				mediaCustom: {
					"event-start": finalEventStartDate,
					"event-duration": eventDuration,
					livemanager: { SaveAsOnDemand: true },
				},
				catalogId: selectedCatalog,
			},
		};

		dispatch(createLiveMediaFromChannel(defaultAccountId, body)).then((res: any) => {
			if (res) {
				handleCloseDialog()
				dispatch(showMessage(t('MODAL_CREATE_NEW_LIVE_EVENT_LIVE_MEDIA_CREATED'), messageTypes.success));
				setTimeout(() => {
					onScheduleEvent?.(mediaId);
					dispatch(refreshMedias());
				}, 2000);
			}
		});
	}, [defaultAccountId, dispatch, eventDescription, eventDuration, eventStartDate, eventStartTime, eventTitle, handleCloseDialog, onScheduleEvent, selectedChannel?.id, t]);

	// const handleOnCoverTabsChange = useCallback(
	// 	(_: React.ChangeEvent<{}>, value: string) => {
	// 		setSelectedCoverTab(value);
	// 	},
	// 	[setSelectedCoverTab]
	// );

	useEffect(() => {
		const eventDate = liveEventTimeSlot?.start ? moment(liveEventTimeSlot?.start).toDate() : new Date();
		setEventStartDate(eventDate);
		setEventStartTime(convertTimeToInputTime(`${eventDate.getHours()}:${eventDate.getMinutes()}`));
	}, [liveEventTimeSlot?.start]);

	return (
		<>
			<Dialog
				fullWidth
				maxWidth={'sm'}
				aria-labelledby="alert-dialog-title"
				open={open}
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle className="dialogTitle" disableTypography>
					<Typography variant="h6">{t('MODAL_CREATE_NEW_LIVE_EVENT_TITLE')}</Typography>
					<IconButton onClick={handleCloseDialog}>
						<Close />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<FormControlLabel
						className={`${baseClassName}__field ${baseClassName}__field-fullwidth`}
						control={
							<Select value={selectedChannel?.id ?? ' '} variant="outlined">
								<MenuItem key={'none'} value={' '}>
									{t('COMMON_LIVE_CHANNEL_PLACEHOLDER') as any}
								</MenuItem>
								{notLegacyChannels.map((channel) => (
									<MenuItem key={channel.id} value={channel.id}>
										{channel.metadata && channel.metadata.title
											? channel.metadata.title
											: channel.id}
									</MenuItem>
								))}
							</Select>
						}
						onChange={handleOnChannelChange}
						label={t('COMMON_LIVE_CHANNEL')}
						labelPlacement="start"
					/>

					<FormControlLabel
						className={`${baseClassName}__field ${baseClassName}__field-fullwidth`}
						control={
							<OutlinedInput value={eventTitle} fullWidth placeholder={t('COMMON_TITLE_PLACEHOLDER')} />
						}
						onChange={handleOnTitleChange}
						label={`${t('COMMON_TITLE')}*`}
						labelPlacement="start"
					/>

					<FormControlLabel
						className={`${baseClassName}__field ${baseClassName}__field-fullwidth`}
						control={
							<TextField
								variant="outlined"
								fullWidth
								value={eventDescription}
								multiline
								maxRows={10}
								minRows={3}
								placeholder={t('COMMON_DESCRIPTION_PLACEHOLDER')}
							/>
						}
						onChange={handleOnDescriptionChange}
						label={`${t('COMMON_DESCRIPTION')}`}
						labelPlacement="start"
					/>
				</DialogContent>

				<div className={`${baseClassName}__divider`} />
				<DialogContent>
					<FormLabel className={`${baseClassName}__field-label`}>Schedule time and duration</FormLabel>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<Grid container justifyContent="space-between" className={`${baseClassName}__flex-container`}>
							<Grid item xs={5}>
								<KeyboardDatePicker
									className={`${baseClassName}__field ${baseClassName}__picker`}
									disableToolbar
									variant="inline"
									format="dd/MM/yyyy"
									margin="normal"
									id="date-picker-inline"
									key="date-picker"
									value={eventStartDate}
									defaultValue={eventStartDate}
									onChange={handleEventStartDateChange}
									label={`${t('COMMON_DATE')}`}
								/>
							</Grid>
							<Grid item xs={4}>
								<FormControlLabel
									className={`${baseClassName}__field ${baseClassName}__picker`}
									style={{ width: '100%' }}
									control={
										<TextField
											id="time"
											type="time"
											value={eventStartTime}
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 300, // 5 min
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton>
															<AccessTime htmlColor="rgba(0, 0, 0, 0.8)" />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									}
									onChange={handleEventStartTimeChange}
									label={'Time'}
									labelPlacement="top"
								/>
							</Grid>
							<Grid item xs={3}>
								<FormControlLabel
									className={`${baseClassName}__field ${baseClassName}__picker`}
									style={{ width: '100%' }}
									control={
										<TextField
											id="time"
											type="time"
											value={eventDuration}
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												step: 300, // 5 min
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton>
															<ExpandMore htmlColor="rgba(0, 0, 0, 0.8)" />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									}
									onChange={handleOnDurationChange}
									label={t('COMMON_DURATION')}
									labelPlacement="top"
								/>
							</Grid>
						</Grid>
					</MuiPickersUtilsProvider>
				</DialogContent>

				{/* <div className={`${baseClassName}__divider`} />
				<DialogContent>
					<FormLabel className={`${baseClassName}__field-label`}>
						Covers (thumbnail, pre- and post cover)
					</FormLabel>
					<Tabs
						value={selectedCoverTab}
						onChange={handleOnCoverTabsChange}
						className={`${baseClassName}__tabs`}
						TabIndicatorProps={{
							style: {
								color: '#ffffff',
								backgroundColor: 'transparent',
							},
						}}
					>
						{['Thumbnail', 'Pre-cover', 'Post-cover'].map((label) => (
							<Tab
								key={label}
								label={label}
								value={label}
								className={joinClassNames([
									`${baseClassName}__tab`,
									label === selectedCoverTab ? `${baseClassName}__tab--selected` : '',
								])}
							/>
						))}
					</Tabs>

					<Dropzone
						accept=".jpg, .jpeg, .gif, .png, .bmp"
						// in case replacement only allow to select 1 file
						multiple
						onDrop={(_acceptedFiles, _fileRejections) => {}}
					>
						{({ getRootProps, getInputProps }) => (
							<div {...getRootProps({ className: `${baseClassName}__dropzone` })}>
								<input {...getInputProps()} />
								<div className={`mediaUploadContentArea ${baseClassName}__dropzone-content`}>
									<IconButton>
										<AddCircleOutlineOutlined htmlColor="#909CA5" />
									</IconButton>
									<span style={{ marginBottom: '5px' }} className="mediaUploadContentArea__message">
										{t('COMMON_BROWSE_OR_DRAG')}
									</span>
									<span className="mediaUploadContentArea__message" style={{ fontWeight: '600' }}>
										<InfoOutlined htmlColor="#909CA5" style={{ marginRight: '5px' }} />
										{t('SCENARIO_LABEL_UPLOAD_IMAGE_TYPES_ACCEPTED')}
									</span>
								</div>
							</div>
						)}
					</Dropzone>
				</DialogContent> */}

				<div className={`${baseClassName}__divider`} />
				<DialogActions>
					<Button
						variant="contained"
						className="defaultActionBtn"
						onClick={handleScheduleEvent}
						disabled={!(selectedChannel?.id && eventTitle)}
					>
						{t('COMMON_SCHEDULE')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ScheduleLiveEventDialog;
