import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	IconButton,
	Menu,
	MenuItem,
	Select,
	Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import * as H from 'history';
import { useHistory } from 'react-router';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { getInitials, stringToColor } from './utils';
import { useTranslation } from 'react-i18next';
import { LocalStorageService } from '../../services/localStorageService';
import FeaturesService from '../../services/FeaturesService';
import { useDispatch, useSelector } from 'react-redux';
import { sendEventToGa } from '../../actions/googleAnalyticsActions';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../constants/routesPath';
import { changeRouteAfterInspectScreen } from '../../actions/publishActions';
import { localStorageKeys } from '../../constants/localStorageKeys';
import { supportUrl } from '../../utils/config';
import { adminPortalUrl } from '../../utils/config';
import { Close } from '@material-ui/icons';
import { logoutUserSession, switchToAccount } from '../../actions/sessionActions';
import Avatar from 'react-avatar';
import { resetMediaLibraryPagination } from '../../screens/PublishScreen/MediaLibrary/utils';
import { buildRoutePath } from '../../utils/commonUtil';

const profileMenuKeyList = {
	profile: 'profile',
	support: 'support',
	account: 'account',
	adminUI: 'admin',
	logoutImpersonatedUser: 'logoutImpersonatedUser',
	logout: 'logout',
};

type ProfileMenuItemProps = {
	// eslint-disable-next-line no-unused-vars
	handleMenuItemClick?: (key: string) => void;
	translator: any;
};

const ProfileMenuItem = ({ handleMenuItemClick, translator }: ProfileMenuItemProps) => {
	const accounts = LocalStorageService.getAccounts()
		? JSON.parse(LocalStorageService.getAccounts() ?? '')
		: LocalStorageService.getAccounts();

	const menuItems = [
		{ key: profileMenuKeyList.profile, text: 'HEADER_USER_INFORMATION_MENU_SETTINGS', visible: true },
		{ key: profileMenuKeyList.support, text: 'HEADER_USER_INFORMATION_MENU_SUPPORT', visible: true },
		{
			key: profileMenuKeyList.account,
			text: 'HEADER_USER_INFORMATION_SWITCH_ACCOUNT',
			visible: accounts && accounts.length > 1 ? true : false,
		},
		{
			key: profileMenuKeyList.adminUI,
			text: 'HEADER_USER_INFORMATION_ADMIN_PORTAL',
			visible: FeaturesService.IsEnabled(LocalStorageService.getUserPermissions(), 'Administration'),
		},
		{
			key: profileMenuKeyList.logoutImpersonatedUser,
			text: 'HEADER_USER_INFORMATION_LOGOUT_IMPERSONATED',
			visible: LocalStorageService.getImpersonateAccessToken() ? true : false,
		},
		{ key: profileMenuKeyList.logout, text: 'HEADER_USER_INFORMATION_LOGOUT', visible: true },
	];
	const visibleItems = menuItems.filter(({ visible }) => visible);
	return (
		<>
			{visibleItems.map(
				({ key, text, visible }) =>
					visible && (
						<MenuItem className="userProfileMenu" key={key} onClick={() => handleMenuItemClick?.(key)}>
							{translator(text)}
						</MenuItem>
					)
			)}
		</>
	);
};

interface SwitchProfileDialogProps {
	open: boolean;
	onClose: () => void;
}

const SwitchProfileDialog = ({ open, onClose }: SwitchProfileDialogProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;
	const history = useHistory();

	const accounts = LocalStorageService.getAccounts()
		? JSON.parse(LocalStorageService.getAccounts() ?? '')
		: LocalStorageService.getAccounts();

	const currentAcc = LocalStorageService.getDefaultAccountId();

	const onSwitchAccount = useCallback(
		(event: any) => {
			const selectedAccountId = event.target.value;
			const selectedAccount = accounts.find((acc: any) => acc.accountId === selectedAccountId);

			const defaultAccountId = localStorage.getItem(localStorageKeys.DEFAULT_ACCOUNT_ID);
			const interactStorageKey = 'interact_introduction_video_' + defaultAccountId;

			localStorage.removeItem(interactStorageKey);
			localStorage.setItem('defaultAccountId', selectedAccount.accountId);
			localStorage.setItem('defaultAccountName', selectedAccount.name);
			dispatch(switchToAccount(selectedAccount.accountId, selectedAccount.name)).then(() => onClose?.());

			if (!window.location.href.includes('/settings')) {
				resetMediaLibraryPagination({ history, defaultAccountId: selectedAccount.accountId });
			} else {
				const redirectRoute = buildRoutePath(
					`${screenRoutes.SETTINGS}`,
					ACCOUNT_ID_ROUTE_PLACEHOLDER,
					selectedAccount.accountId
				);
				history.push(redirectRoute);
			}
		},
		[accounts, dispatch, history, onClose]
	);

	return (
		<Dialog fullWidth open={open && accounts}>
			<DialogTitle
				className="dialogTitle"
				disableTypography
				style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
			>
				<Typography variant="h6">{t('COMMON_ACCOUNT')}</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<div className="dialogContentWrapper" style={{ paddingBottom: '24px' }}>
					<FormControl fullWidth>
						<label className="headerTitle">
							{t('HEADER_USER_INFORMATION_SWITCH_ACCOUNT_CHOOSE_ACCOUNT')}
						</label>
						<Select variant="standard" value={currentAcc} onChange={onSwitchAccount}>
							{accounts &&
								accounts.map((acc: any) => (
									<MenuItem value={acc.accountId} key={acc.accountId}>
										{acc.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</div>
			</DialogContent>
		</Dialog>
	);
};

type UserInformationProps = {
	history: H.History;
	mobile?: boolean;
};

const UserInformation = ({ history, mobile }: UserInformationProps) => {
	const [profileAnchor, setProfileAnchor] = useState(null);

	const dispatch = useDispatch();
	const { t: translator } = useTranslation();
	const { isInspectScreenModified } = useSelector((state: any) => state.publish);
	const defaultAccountId = localStorage.getItem('defaultAccountId');

	const [showSwitchProfileDialog, setShowSwitchProfileDialog] = useState(false);

	const username = LocalStorageService.getUserName();
	const defaultAccountName = LocalStorageService.getDefaultAccountName();

	const handleMenuItemClick = useCallback(
		(key: string) => {
			dispatch(sendEventToGa('Profile', key, key));
			setProfileAnchor(null);

			switch (key) {
				case profileMenuKeyList.profile:
					if (isInspectScreenModified) {
						dispatch(changeRouteAfterInspectScreen(screenRoutes.SETTINGS));
					} else {
						history.push(
							buildRoutePath(screenRoutes.SETTINGS, ACCOUNT_ID_ROUTE_PLACEHOLDER, defaultAccountId)
						);
					}
					break;
				case profileMenuKeyList.support:
					window.open(supportUrl, '_blank');
					break;
				case profileMenuKeyList.account:
					setShowSwitchProfileDialog(true);
					break;
				case profileMenuKeyList.adminUI:
					window.open(adminPortalUrl, '_blank');
					break;
				case profileMenuKeyList.logoutImpersonatedUser:
					LocalStorageService.removeItem(localStorageKeys.IMPERSONATED_ACCESS_TOKEN);
					history.push(
						buildRoutePath(
							screenRoutes.PUBLISH_MEDIA_LIBRARY,
							ACCOUNT_ID_ROUTE_PLACEHOLDER,
							defaultAccountId
						)
					);
					break;
				case profileMenuKeyList.logout:
					dispatch(logoutUserSession());
					break;
				default:
					break;
			}
		},
		[defaultAccountId, isInspectScreenModified]
	);

	return (
		<>
			<div
				className="user-information"
				onClick={(e) => {
					setProfileAnchor(e.currentTarget as any);
				}}
			>
				<Avatar
					title={username ?? undefined}
					color={stringToColor(username)}
					name={getInitials(username)}
					size="26"
					round={true}
				/>
				{!mobile && (
					<label className="user-information__account">
						{defaultAccountName != null ? defaultAccountName : ''}
					</label>
				)}
				<KeyboardArrowDownIcon />
			</div>
			<Menu
				id="topMenuSystem"
				className="profileMenuList"
				anchorEl={profileAnchor}
				open={Boolean(profileAnchor)}
				onClose={() => {
					setProfileAnchor(null);
				}}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			>
				<div />
				<div className="infoContentWrapper">
					<label className="loggedMenu">{translator('HEADER_USER_INFORMATION_LOGGED_IN_AS')}</label>
					<label className="userNameMenu">{username}</label>
					<label className="accountNameMenu">{defaultAccountName != null ? defaultAccountName : ''}</label>
				</div>
				<Divider />
				<ProfileMenuItem handleMenuItemClick={handleMenuItemClick} translator={translator} />
			</Menu>
			<SwitchProfileDialog open={showSwitchProfileDialog} onClose={() => setShowSwitchProfileDialog(false)} />
		</>
	);
};

export default UserInformation;
