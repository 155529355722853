import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
	getAssignedRestrictionRules,
	loadMediasByQueryService,
	loadMediasPerNavigationWithTotalCount,
	loadTrashedMediasPerNavigationWithTotalCount,
} from '../../../services/mediaService';
import {
	mediaFiltersQueries,
	mediaLibraryKeys,
	mediaNames,
	mediaNamesQuery,
	mediaTypes,
	sortTypes,
} from '../../../constants/mediaConstants';
import { _getChannels } from '../../../services/channelService';

type DataProps = {
	mediaType?: string;
	offset?: number;
	limit?: number;
	sortType?: string;
	mediaFilters?: object;
	searchQuery?: string;
	catalogId?: string;
	isReFetched?: boolean;
	loadChannels?: boolean;
	shouldCheckRefetch?: boolean;
	setLoading?: (_value: boolean) => void;
};

export const useFetchLibraryData = ({
	mediaType = mediaNames.medias,
	offset = 0,
	limit,
	sortType = sortTypes.type,
	searchQuery = '',
	catalogId = '',
	isReFetched,
	shouldCheckRefetch,
	mediaFilters,
	loadChannels,
	setLoading,
}: DataProps): { items: any[]; count: number; liveChannels: any[]; thumbnails: any[]; restrictions: any[] } => {
	const [count, setCount] = useState<number | null>(null);
	const [items, setItem] = useState<Array<object> | null>(null);
	const [thumbnails, setThumbnails] = useState<Array<object> | null>(null);
	const [liveChannels, setLiveChannels] = useState<Array<object> | null>(null);
	const [restrictions, setRestrictions] = useState<Array<object> | null>(null);
	const accountId = useSelector((state: any) => state.session.defaultAccountId);
	const refreshMedias = useSelector((state) => (state as any).publish.refreshMedias);
	let abortController = new AbortController();
	const abortControllerRef = useRef(abortController);

	const getBGMediaOfScenarios = useCallback(
		(items: any[]) => {
			if (mediaType !== 'scenarios' || !items || items.length === 0) {
				return [];
			}

			const thumbnailMedias: any[] = [];
			items.map((scenario) => {
				// GET BG VIDEO OF SCENARIO
				const homeSceneId = scenario.defaults?.sceneId;
				if (!scenario.scenes || scenario.scenes.length === 0) {
					return;
				}

				const homeScene = homeSceneId
					? scenario.scenes.find((s: any) => s.id === homeSceneId)
					: scenario.scenes[0];
				let layout = homeScene?.layouts?.find((l: any) => l.type === 'desktop');
				layout =
					layout === undefined || (layout.boxes ?? [])[0]?.widgets?.length === 0
						? homeScene?.layouts?.find((l: any) => (l.boxes ?? [])[0].widgets.length > 0)
						: layout;
				const boxes = layout?.boxes ?? [];

				if (boxes.length > 0) {
					let widgets: any[] = [];
					boxes.forEach((box: any) => {
						box.widgets?.forEach((w: any) => widgets.push(w.widgetTemplateId));
					});
					homeScene.widgetTemplates.forEach((tmp: any) => {
						if (!widgets.includes(tmp.id)) {
							return;
						}
						if (tmp.type === 'video') {
							thumbnailMedias.push({
								id: tmp.settings?.mediaId,
								sceneId: homeSceneId,
								scenarioId: scenario.id,
							});
						}
					});
				}
			});

			return thumbnailMedias;
		},
		[mediaType]
	);

	useEffect(() => {
		if (abortControllerRef.current && !abortControllerRef.current.signal.aborted) {
			abortControllerRef.current.abort();
			abortControllerRef.current = new AbortController();
		}
		if (shouldCheckRefetch && !isReFetched) {
			return;
		}

		const fetchAdditionalThumbnails = async (data: any[], medias: any[]) => {
			const additionalThumbnails = [];
			for (const media of medias) {
				if (!media) {
					return;
				}

				const { data } = (await fetchMediaByIdHandler({
					accountId,
					media: media,
				})) as any;
				additionalThumbnails.push(data[0]);
			}

			setThumbnails([...data, ...additionalThumbnails]);
		};

		const fetchThumbnails = async (scenarios: any) => {
			const thumbnailMediaWithScenarios = getBGMediaOfScenarios(scenarios);
			if (thumbnailMediaWithScenarios.length === 0) {
				return [];
			}

			const mediaIds = thumbnailMediaWithScenarios.map((m: any) => m.id).filter(Boolean);
			const { data } = (await fetchMediasByIdsHandler({
				accountId,
				mediaIds,
				thumbnailMediaWithScenarios,
			})) as any;

			const medias = thumbnailMediaWithScenarios
				.filter((t: any) => !(data ?? []).filter(Boolean).find((d: any) => d?.id === t?.id))
				.filter(Boolean);
			if (medias.length > 0) {
				fetchAdditionalThumbnails((data ?? []).filter(Boolean), medias);
			} else {
				setThumbnails((data ?? []).filter(Boolean));
			}
		};

		const fetchRestrictions = async () => {
			const data = await getAssignedRestrictionRules(accountId);
			setRestrictions(data);
		};

		const fetchData = async () => {
			let result;

			const usedMediaFilter =
				mediaFilters ??
				(mediaType === mediaNames.medias
					? {
							[mediaTypes.video]: true,
							[mediaTypes.livevideo]: true,
					  }
					: {});

			switch (mediaType) {
				case mediaNames.catalogs:
					result = await catalogFetchingDataHandler({
						accountId,
						catalogId,
						mediaFilters: usedMediaFilter,
						offset,
						limit,
						sortType,
						searchQuery,
						signal: abortControllerRef.current.signal,
					});
					break;
				case mediaNames.medias:
				case mediaNames.unsortedMedia:
				case mediaNames.editedMedias:
					result = await mediaFetchingDataHandler({
						mediaType,
						accountId,
						mediaFilters: usedMediaFilter,
						offset,
						limit,
						sortType,
						searchQuery,
						signal: abortControllerRef.current.signal,
					});
					break;
				case mediaNames.trashedMedias:
					result = await trashedMediaFetchingDataHandler({
						mediaType,
						accountId,
						mediaFilters: usedMediaFilter,
						offset,
						limit,
						sortType,
						searchQuery,
						signal: abortControllerRef.current.signal,
					});
					break;
				// smart filter use key from mediaLibraryKeys obj and query media
				case mediaLibraryKeys.smartFilter:
					if (searchQuery === '') {
						result = null;
						break;
					}

					result = await loadMediasPerNavigationWithTotalCount({
						accountId,
						mediaPerPage: limit,
						offset,
						mediaName: `${mediaNames.medias}?`,
						sortType: sortType === sortTypes.type ? sortTypes.newest : sortType,
						query: searchQuery,
						signal: abortControllerRef.current.signal,
					});
					break;
				case mediaNames.portals:
					result = await loadMediasPerNavigationWithTotalCount({
						accountId,
						mediaPerPage: limit,
						offset,
						mediaName: `${mediaNames.portals}?`,
						sortType: sortType === sortTypes.type ? sortTypes.newest : sortType,
						query:
							searchQuery && searchQuery !== 'q=*'
								? `${searchQuery}&IsPublished=${false}`
								: `IsPublished=${false}`,
						signal: abortControllerRef.current.signal,
					});
					break;
				default:
					result = await loadMediasPerNavigationWithTotalCount({
						accountId,
						mediaPerPage: limit,
						offset,
						mediaName: `${mediaType}?`,
						sortType: sortType === sortTypes.type ? sortTypes.newest : sortType,
						query: searchQuery,
						signal: abortControllerRef.current.signal,
					});
			}

			if (!result) {
				throw new Error('Not found', { cause: 404 });
			}

			const { data, count } = result as any;

			fetchThumbnails(data);
			fetchRestrictions();
			setTimeout(() => setLoading?.(false), 100);
			setItem(data);
			setCount(Number(count));
		};

		if (loadChannels) {
			const fetchChannels = async () => {
				let _liveChannels: any[] | undefined = [];
				_liveChannels = await _getChannels(accountId);

				if (!_liveChannels) {
					throw new Error('Channels not found', { cause: 404 });
				}

				setLiveChannels(_liveChannels ?? []);
			};

			fetchChannels().catch((error) => {
				const { cause: code } = error;
				if (Number(code) === 404) {
					setLiveChannels([]);
				}
			});
		}

		setLoading?.(true);
		fetchData().catch((error) => {
			const { cause: code } = error;
			if (Number(code) === 404) {
				setTimeout(() => setLoading?.(false), 100);
				setItem([]);
				setCount(0);
				setLiveChannels([]);
			}
		});
	}, [
		accountId,
		mediaType,
		offset,
		limit,
		sortType,
		searchQuery,
		catalogId,
		isReFetched,
		shouldCheckRefetch,
		mediaFilters,
		refreshMedias,
		loadChannels,
		getBGMediaOfScenarios,
		setLoading,
	]);

	return {
		items: items ?? [],
		count: count ?? 0,
		liveChannels: liveChannels ?? [],
		thumbnails: thumbnails ?? [],
		restrictions: restrictions ?? [],
	};
};

interface CatalogFetchingDataHandlerInterface {
	catalogId: string;
	offset: number;
	limit?: number;
	sortType: string;
	searchQuery: string;
	accountId: string;
	mediaFilters: object;
	signal: object;
}
const catalogFetchingDataHandler = async ({
	catalogId,
	searchQuery,
	mediaFilters,
	offset,
	limit,
	sortType,
	accountId,
	signal,
}: CatalogFetchingDataHandlerInterface) => {
	let childOfCatalogId: { data: any; count: string } | void = { data: [], count: '0' };
	let mediaOfCatalogs: { data: any; count: string } | void = { data: [], count: '0' };

	try {
		childOfCatalogId = await loadMediasPerNavigationWithTotalCount({
			accountId,
			mediaPerPage: limit,
			offset,
			mediaName: getCatalogQueryString(catalogId, searchQuery),
			sortType: sortType === sortTypes.type ? sortTypes.newest : sortType,
			query: undefined,
			signal,
		});

		if (!childOfCatalogId) {
			throw new Error('Child catalog not found', { cause: 404 });
		}
	} catch (error) {
		const { cause: code } = error as any;

		if (Number(code) === 404) {
			childOfCatalogId = { data: [], count: '0' };
		}
	}

	try {
		if (catalogId && catalogId !== '') {
			mediaOfCatalogs = await mediaFetchingDataHandler({
				mediaType: mediaNames.medias,
				accountId,
				mediaFilters,
				offset,
				limit,
				sortType,
				searchQuery: searchQuery
					? `${searchQuery} AND catalog.id:"${catalogId}"`
					: `q=catalog.id:"${catalogId}"`,
				signal,
			});

			if (!mediaOfCatalogs) {
				throw new Error('Media of catalog not found', { cause: 404 });
			}
		} else {
			mediaOfCatalogs = { data: [], count: '0' };
		}
	} catch (error) {
		const { cause: code } = error as any;

		if (Number(code) === 404) {
			mediaOfCatalogs = { data: [], count: '0' };
		}
	}

	const { data: childOfCatalogIdData, count: childOfCatalogIdCount } = childOfCatalogId as any;
	const { data: mediaOfCatalogsData, count: mediaOfCatalogsCount } = mediaOfCatalogs as any;

	return {
		data: [...childOfCatalogIdData, ...mediaOfCatalogsData],
		count: Number(childOfCatalogIdCount) + Number(mediaOfCatalogsCount),
	};
};

const getCatalogQueryString = (catalogId: string, searchQuery: string) => {
	if (!searchQuery) {
		return catalogId !== '' ? `${mediaNames.catalogs}?q=parentId:` + `"${catalogId}"` : `${mediaNames.catalogs}?`;
	}

	return catalogId !== ''
		? `${mediaNames.catalogs}?` + searchQuery + 'AND parentId:' + `"${catalogId}"`
		: `${mediaNames.catalogs}?` + searchQuery;
};

interface MediaFetchingDataHandlerInterface {
	mediaType: string;
	mediaFilters: object;
	offset: number;
	limit?: number;
	sortType: string;
	searchQuery: string;
	accountId: string;
	signal: object;
}

const mediaFetchingDataHandler = async ({
	mediaType,
	mediaFilters,
	offset,
	limit,
	sortType,
	searchQuery,
	accountId,
	signal,
}: MediaFetchingDataHandlerInterface) => {
	try {
		const mediaResult = (await loadMediasPerNavigationWithTotalCount({
			accountId,
			mediaPerPage: limit,
			offset,
			mediaName: getMediaQueryString(mediaType, mediaFilters, searchQuery),
			sortType: sortType === sortTypes.type ? sortTypes.newest : sortType,
			query: undefined,
			signal,
		})) as any;

		if (!mediaResult) {
			throw new Error('Media not found', { cause: 404 });
		}

		return mediaResult;
	} catch (error) {
		const { cause: code } = error as any;

		if (Number(code) === 404) {
			return { data: [], count: '0' };
		}

		throw error;
	}
};

const trashedMediaFetchingDataHandler = async ({
	mediaType,
	mediaFilters,
	offset,
	limit,
	sortType,
	searchQuery,
	accountId,
	signal,
}: MediaFetchingDataHandlerInterface) => {
	try {
		const mediaResult = (await loadTrashedMediasPerNavigationWithTotalCount({
			accountId,
			mediaPerPage: limit,
			offset,
			query: getMediaQueryString(mediaType, mediaFilters, searchQuery),
			sortType: sortType === sortTypes.type ? sortTypes.newest : sortType,
			signal,
		})) as any;

		if (!mediaResult) {
			throw new Error('Media not found', { cause: 404 });
		}

		return mediaResult;
	} catch (error) {
		const { cause: code } = error as any;

		if (Number(code) === 404) {
			return { data: [], count: '0' };
		}

		throw error;
	}
};

const fetchMediasByIdsHandler = async ({
	accountId,
	mediaIds,
	thumbnailMediaWithScenarios,
}: {
	accountId: string;
	mediaIds: string[];
	thumbnailMediaWithScenarios: any[];
}) => {
	try {
		const result = (await loadMediasByQueryService({
			accountId,
			mediaName: mediaNames.medias,
			query:
				mediaIds.filter(Boolean).length === 1
					? `/${mediaIds.filter(Boolean)[0]}`
					: '?q=id:(' + encodeURIComponent(mediaIds.filter(Boolean).join(',')) + ')',
		})) as any;

		if (!result) {
			throw new Error('Media not found', { cause: 404 });
		}

		let mediaResult = result;
		if (!result.map) {
			mediaResult = [result];
		}

		const data = thumbnailMediaWithScenarios
			.map((t: any) => {
				const media = mediaResult.find((m: any) => t.id === m.id);
				if (!media) {
					return undefined;
				}
				return {
					...t,
					...media,
				};
			})
			.filter(Boolean);

		return { data: data, count: data.length };
	} catch (error) {
		const { cause: code } = error as any;

		if (Number(code) === 404) {
			return { data: [], count: '0' };
		}

		throw error;
	}
};

const fetchMediaByIdHandler = async ({ accountId, media }: { accountId: string; media: any }) => {
	try {
		const result = (await loadMediasByQueryService({
			accountId,
			mediaName: mediaNames.medias,
			query: `/${media.id}`,
		})) as any;

		if (!result) {
			throw new Error('Media not found', { cause: 404 });
		}

		let mediaResult = result;
		if (!result.map) {
			mediaResult = [result];
		}

		const data: any[] = mediaResult.map((m: any) => ({
			...m,
			scenarioId: media.scenarioId,
		}));

		return { data: data, count: '1' };
	} catch (error) {
		const { cause: code } = error as any;

		if (Number(code) === 404) {
			return { data: [], count: '0' };
		}

		throw error;
	}
};

const getMediaQueryString = (mediaType: string, mediaFilters: object, searchQuery: string) => {
	// if VOD, Live both selected, just using media type to query
	let buildMediaQueryFromFilter =
		(mediaFilters as any)[mediaTypes.video] && (mediaFilters as any)[mediaTypes.livevideo]
			? // TODO: Should be using mediaNamesQuery.allMediasWithoutImage here but temporary using empty because couldn't make this query work with search term
			  // will update asa find out how
			  ''
			: Object.entries(mediaFilters).reduce((convertedQueryString, [filterKey, isSelected]) => {
					let queryRelatedToFilter: string | null = isSelected ? mediaFiltersQueries[filterKey].query : null;

					if (queryRelatedToFilter) {
						convertedQueryString =
							convertedQueryString === ''
								? `${queryRelatedToFilter}`
								: `${convertedQueryString} OR ${queryRelatedToFilter}`;
					}

					return convertedQueryString;
			  }, '');

	if ((mediaFilters as any)[mediaTypes.uncatalogedMedias]) {
		buildMediaQueryFromFilter =
			buildMediaQueryFromFilter === ''
				? `${mediaNamesQuery.uncatalogedMedias}`
				: `${buildMediaQueryFromFilter} AND ${mediaNamesQuery.uncatalogedMedias}`;
	}

	if (mediaType === mediaNames.editedMedias) {
		buildMediaQueryFromFilter = `${buildMediaQueryFromFilter} AND ${mediaNamesQuery.editedMedias}`;
	}

	let query = mediaType === mediaNames.trashedMedias ? '' : `${mediaNames.medias}?`;

	if (searchQuery) {
		query = `${query}${searchQuery}${
			buildMediaQueryFromFilter !== '' ? ` AND (${buildMediaQueryFromFilter})` : ''
		}`;
	} else {
		query = `${query}${buildMediaQueryFromFilter !== '' ? `q=(${buildMediaQueryFromFilter})` : ''}`;
	}

	return query;
};
