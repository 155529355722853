import React, { CSSProperties, useEffect } from 'react';
import {
	CreateNewFolderOutlined,
	DeleteForever,
	DeleteOutlined,
	OpenInNewOutlined,
	PlaylistAddOutlined,
	Restore,
	VisibilityOutlined,
	EditOutlined,
	CropOutlined,
	FlipOutlined,
} from '@material-ui/icons';
import Skeleton from 'react-loading-skeleton';

import { ViewType } from '../../screens/PublishScreen/MediaLibrary/utils';
import { joinClassNames } from '../../services/elementHelperService';
import { ReactComponent as Copy } from '../../assets/svg/Copy.svg';
import { customScreenType, mediaActionKeys, mediaViews } from '../../constants/mediaConstants';

import { GridMediasDisplay } from './GridMediasDisplay';
import { NoMediaDisplayProps, NoMediaDisplay } from './NoMediaDisplay';
import { ListMediasDisplay } from './ListMediasDisplay';

import './MediasDisplay.scss';
import { checkPermissionGrantedFor } from '../../services/componentReusableService';
import { useDispatch, useSelector } from 'react-redux';
import { getRestrictionRules } from '../../actions/publishActions';
import CalendarMediasDisplay, { CalendarMediasDisplayProps } from './CalendarMediasDisplay';

export const ShortcutMenuItems = {
	video: [
		{ text: 'COMMON_ADD_TO_PLAYLIST', icon: <PlaylistAddOutlined />, value: mediaActionKeys.addToPlaylist },
		{ text: 'COMMON_ADD_TO_CATALOG', icon: <CreateNewFolderOutlined />, value: mediaActionKeys.addToCatalog },
		{ text: 'COMMON_EDIT_WITH_AE_EDITOR', icon: <EditOutlined />, value: mediaActionKeys.editWithAE },
		{ text: 'COMMON_TRIM_WITH_AE_EDITOR', icon: <FlipOutlined />, value: mediaActionKeys.trimVideoWithAE },
		{
			text: 'COMMON_RESIZE_WITH_AE_EDITOR',
			icon: <CropOutlined />,
			value: mediaActionKeys.resizeVideoWithAE,
		},
		{
			text: 'COMMON_OPEN_AND_REPLACE_AE_PROJECT',
			icon: <EditOutlined />,
			value: mediaActionKeys.openAndReplaceAEProject,
		},
		{ text: 'COMMON_OPEN_NEW_AE_PROJECT', icon: <EditOutlined />, value: mediaActionKeys.openAEProject },
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_COPY_MEDIA',
			icon: <Copy />,
			value: mediaActionKeys.duplicateMedia,
			style: { padding: '1px' },
		},
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_OPEN_IN_NEW_WINDOW',
			icon: <OpenInNewOutlined />,
			value: mediaActionKeys.openMediaInNewWindow,
		},
		{ text: 'COMMON_MOVE_TO_BIN', icon: <DeleteOutlined />, value: mediaActionKeys.moveToBin },
	],
	livevideo: [
		{ text: 'COMMON_ADD_TO_PLAYLIST', icon: <PlaylistAddOutlined />, value: mediaActionKeys.addToPlaylist },
		{ text: 'COMMON_ADD_TO_CATALOG', icon: <CreateNewFolderOutlined />, value: mediaActionKeys.addToCatalog },
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_COPY_MEDIA',
			icon: <Copy />,
			value: mediaActionKeys.duplicateMedia,
			style: { padding: '1px' },
		},
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_OPEN_IN_NEW_WINDOW',
			icon: <OpenInNewOutlined />,
			value: mediaActionKeys.openMediaInNewWindow,
		},
		{ text: 'COMMON_MOVE_TO_BIN', icon: <DeleteOutlined />, value: mediaActionKeys.moveToBin },
	],
	playlists: [
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_OPEN_IN_NEW_WINDOW',
			icon: <OpenInNewOutlined />,
			value: mediaActionKeys.openMediaInNewWindow,
		},
		{ text: 'COMMON_DELETE_PLAYLISTS', icon: <DeleteForever />, value: mediaActionKeys.deleteMedia },
	],
	unknown: [
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_OPEN_IN_NEW_WINDOW',
			icon: <OpenInNewOutlined />,
			value: mediaActionKeys.openMediaInNewWindow,
		},
		{ text: 'COMMON_DELETE_MEDIA', icon: <DeleteForever />, value: mediaActionKeys.deleteMedia },
	],
	catalog: [
		{
			text: 'CATALOG_TREE_ACTION_INSPECT_CATALOG_LABEL',
			icon: <VisibilityOutlined />,
			value: mediaActionKeys.inspectCatalog,
		},
		{
			text: 'CATALOG_TREE_ACTION_EDIT_ACCESS_CATALOG_LABEL',
			icon: <EditOutlined />,
			value: mediaActionKeys.editAccessCatalog,
		},
		{ text: 'COMMON_ADD_TO_CATALOG', icon: <CreateNewFolderOutlined />, value: mediaActionKeys.addToCatalog },
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_OPEN_IN_NEW_WINDOW',
			icon: <OpenInNewOutlined />,
			value: mediaActionKeys.openMediaInNewWindow,
		},
		{ text: 'COMMON_DELETE_CATALOG', icon: <DeleteForever />, value: mediaActionKeys.deleteMedia },
	],
	scenario: [
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_COPY_MEDIA',
			icon: <Copy />,
			value: mediaActionKeys.duplicateMedia,
		},
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_OPEN_IN_NEW_WINDOW',
			icon: <OpenInNewOutlined />,
			value: mediaActionKeys.openMediaInNewWindow,
		},
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_DELETE_INTERACT_SINGLE',
			icon: <DeleteForever />,
			value: mediaActionKeys.deleteMedia,
		},
	],
	image: [
		{ text: 'COMMON_ADD_TO_CATALOG', icon: <CreateNewFolderOutlined />, value: mediaActionKeys.addToCatalog },
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_COPY_MEDIA',
			icon: <Copy />,
			value: mediaActionKeys.duplicateMedia,
			style: { padding: '1px' },
		},
		{ text: 'COMMON_EDIT_WITH_AE_EDITOR', icon: <EditOutlined />, value: mediaActionKeys.editWithAE },
		{ text: 'COMMON_OPEN_NEW_AE_PROJECT', icon: <EditOutlined />, value: mediaActionKeys.openAEProject },
		{
			text: 'COMMON_RESIZE_WITH_AE_EDITOR',
			icon: <CropOutlined />,
			value: mediaActionKeys.resizeImageWithAE,
		},
		{
			text: 'MEDIA_LIBRARY_MEDIA_DISPLAY_OPEN_IN_NEW_WINDOW',
			icon: <OpenInNewOutlined />,
			value: mediaActionKeys.openMediaInNewWindow,
		},
		{ text: 'COMMON_MOVE_TO_BIN', icon: <DeleteOutlined />, value: mediaActionKeys.moveToBin },
	],
	trashedMedias: [
		{ text: 'COMMON_RESTORE', icon: <Restore />, value: mediaActionKeys.restore },
		{ text: 'COMMON_DELETE_MEDIA', icon: <DeleteForever />, value: mediaActionKeys.deleteMedia },
	],
	portal: [{ text: 'COMMON_DELETE_MEDIA', icon: <DeleteForever />, value: mediaActionKeys.deleteMedia }],
};

export interface MediaItemProps {
	isMiniLibrary?: boolean;
	media?: any;
	mediaType?: string;
	isSelected?: boolean;
	isMobileView?: boolean;
	thumbnail?: any;
	isAllowAEOption?: boolean;
	restrictions?: any[];
	onSelectMedia?: (_mediaDetails: any) => void; // param: mediaDetails
	onMediaActionMenuClick?: (_mediaDetails: any, _action: string, _isImage?: boolean) => void; // params: mediaDetails, actionId
	onLibraryViewChange?: (_mediaType: string, _mediaId?: string) => void;
	onMediaItemClick?: (_mediaDetails: any) => void;
}

export interface MediasDisplayProps {
	children?: React.ReactNode;
	medias: any[];
	selectedMedias?: any[];
	mediaType?: string;
	loading?: boolean;
	screenType?: string;
	isMiniLibrary?: boolean;
	isSelectAll?: boolean;
	thumbnails?: any[];
	classNames?: string;
	isAllowAEOption?: boolean;
	shortcutLabel?: string;
	onShortcutActionClick?: () => void;
	onLibraryViewChange?: (_mediaType: string, _mediaId?: string) => void;
	onSelectMedia?: (_mediaDetails: any) => void; // param: mediaDetails
	onMediaActionMenuClick?: (_mediaDetails: any, _action: string, _isImage?: boolean) => void; // params: mediaDetails, actionId
	onSelectAllMediaItemsPerPage?: () => void;
	onMediaItemClick?: (_mediaDetails: any) => void;
	emptyContainer?: React.ReactNode;
	restrictions?: any[];
}

type MediasDisplayType = MediasDisplayProps &
	NoMediaDisplayProps &
	CalendarMediasDisplayProps & {
		view?: ViewType;
		style?: CSSProperties;
	};

export const MediasDisplay: React.FC<MediasDisplayType> = ({
	loading,
	medias,
	style,
	view,
	mediaType,
	selectedMedias,
	screenType,
	isMiniLibrary,
	isSelectAll,
	thumbnails,
	searchQuery,
	classNames,
	restrictions,
	onSelectMedia,
	onMediaActionMenuClick,
	showCreateScenarioBox,
	showUploadMediaDialogBox,
	onSelectAllMediaItemsPerPage,
	onLibraryViewChange,
	onMediaItemClick,
	onSelectTimeSlot,
}) => {
	const dispatch = useDispatch();
	const { userPermissions } = useSelector((state) => (state as any).session);
	const { defaultAccountId } = useSelector((state) => (state as any).session);

	useEffect(() => {
		if (!restrictions) {
			dispatch(getRestrictionRules(defaultAccountId));
		}
	}, [defaultAccountId, dispatch, restrictions]);

	if (loading || !view) {
		return (
			<div className={'medias-display-wrapper'} style={style}>
				<LoadingMedia view={view} />
			</div>
		);
	}

	const isAllowAEOption = checkPermissionGrantedFor(userPermissions, 'adobeExpress');

	return (
		<div
			className={joinClassNames(
				'medias-display-wrapper',
				view === mediaViews.calendar ? '' : 'medias-display-wrapper--background',
				medias && medias.length > 0 ? '' : 'medias-display-wrapper--no-media',
				isMiniLibrary ? 'medias-display-wrapper--mini' : ''
			)}
			style={style}
		>
			{view === mediaViews.calendar ? (
				<CalendarMediasDisplay onSelectTimeSlot={onSelectTimeSlot} />
			) : medias && medias.length > 0 ? (
				view === mediaViews.list ||
				screenType === customScreenType.small ||
				screenType === customScreenType.mobile ? (
					<ListMediasDisplay
						restrictions={restrictions}
						classNames={classNames}
						screenType={screenType}
						medias={medias}
						mediaType={mediaType}
						onSelectMedia={onSelectMedia}
						selectedMedias={selectedMedias}
						onMediaActionMenuClick={onMediaActionMenuClick}
						isMiniLibrary={isMiniLibrary}
						onSelectAllMediaItemsPerPage={onSelectAllMediaItemsPerPage}
						isSelectAll={isSelectAll}
						onLibraryViewChange={onLibraryViewChange}
						thumbnails={thumbnails}
						isAllowAEOption={isAllowAEOption}
						onMediaItemClick={onMediaItemClick}
					/>
				) : (
					<GridMediasDisplay
						restrictions={restrictions}
						classNames={classNames}
						medias={medias}
						mediaType={mediaType}
						onSelectMedia={onSelectMedia}
						selectedMedias={selectedMedias}
						onMediaActionMenuClick={onMediaActionMenuClick}
						isMiniLibrary={isMiniLibrary}
						onLibraryViewChange={onLibraryViewChange}
						thumbnails={thumbnails}
						isAllowAEOption={isAllowAEOption}
						onMediaItemClick={onMediaItemClick}
					/>
				)
			) : (
				<NoMediaDisplay
					searchQuery={searchQuery}
					showCreateScenarioBox={showCreateScenarioBox}
					showUploadMediaDialogBox={showUploadMediaDialogBox}
				/>
			)}
		</div>
	);
};

interface LoadingMediaProps {
	view?: ViewType;
}

const LoadingMedia = ({ view }: LoadingMediaProps) => {
	return view === 'grid' ? (
		<div className="medias-display-skeleton medias-display-skeleton__grid">
			{[...Array(50)].map((x, i) => (
				<div className="medias-display-skeleton__item" key={i}>
					<Skeleton height={120} />
					<Skeleton height={20} />
					<Skeleton height={20} />
				</div>
			))}
		</div>
	) : (
		<div className="medias-display-skeleton medias-display-skeleton__list">
			{[...Array(50)].map((x, i) => (
				<Skeleton width={'100%'} height={50} key={i} />
			))}
		</div>
	);
};
