import { localStorageKeys } from './../constants/localStorageKeys';

export const LocalStorageService = {
	updateLocalStorage(data) {
		//use this method to only update data from iframe
		localStorage.setItem(localStorageKeys.ACCESS_TOKEN, data.access_token);
		localStorage.setItem(localStorageKeys.EXPIRES_IN, data.expires_in);
		localStorage.setItem(localStorageKeys.TOKEN_ID, data.id_token);
		localStorage.setItem(localStorageKeys.SCOPE, data.scope);
		localStorage.setItem(localStorageKeys.SESSION_STATE, data.session_state);
		localStorage.setItem(localStorageKeys.STATE, data.state);
		localStorage.setItem(localStorageKeys.TOKEN_TYPE, data.token_type);
	},

	addItem(key, value) {
		localStorage.setItem(key, value);
	},

	removeItem(key) {
		localStorage.removeItem(key);
	},

	getItem(key) {
		localStorage.getItem(key);
	},

	getChatSessionDetails() {
		return localStorage.getItem(localStorageKeys.LIVE_MANAGER_CHAT);
	},
	setChatSessionDetails(sessionDetails) {
		localStorage.setItem(localStorageKeys.LIVE_MANAGER_CHAT, sessionDetails);
	},
	clearLocalStorage() {
		localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
		localStorage.removeItem('expires_in');
		localStorage.removeItem('scope');
		localStorage.removeItem('session_state');
		localStorage.removeItem('state');
		localStorage.removeItem('token_type');
		localStorage.removeItem('userId');
		localStorage.removeItem('username');
		localStorage.removeItem('nonce');
		localStorage.removeItem('id_token');
		localStorage.removeItem('defaultAccountId');
		localStorage.removeItem('defaultAccountName');
		localStorage.removeItem('twitterAuthenticated');
		localStorage.removeItem(localStorageKeys.LIVE_MANAGER_CHAT);
		localStorage.clear();
	},
	getAccessToken() {
		let token = null;

		try {
			token = JSON.parse(localStorage.getItem(localStorageKeys.IMPERSONATED_ACCESS_TOKEN));
		} catch (e) {
			console.error('Impersonated token missing ', e);
		}
		return token ? token : localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
	},
	getUploadAccessToken() {
		let token = null;

		try {
			token = JSON.parse(localStorage.getItem(localStorageKeys.UPLOAD_ACCESS_TOKEN));
		} catch (e) {
			console.error('Upload token missing ', e);
		}
		return token ? token : LocalStorageService.getAccessToken();
	},
	getImpersonateAccessToken() {
		return JSON.parse(localStorage.getItem(localStorageKeys.IMPERSONATED_ACCESS_TOKEN));
	},
	getLastTokenReceived() {
		return localStorage.getItem(localStorageKeys.LAST_TOKEN_RECEIVED);
	},
	getUserId() {
		return localStorage.getItem(localStorageKeys.USER_ID);
	},
	getUserName() {
		return localStorage.getItem(localStorageKeys.USER_NAME);
	},
	getAccounts() {
		return localStorage.getItem(localStorageKeys.ACCOUNTS);
	},
	getScope() {
		return localStorage.getItem(localStorageKeys.SCOPE);
	},
	getDefaultAccountId() {
		return localStorage.getItem(localStorageKeys.DEFAULT_ACCOUNT_ID);
	},
	getDefaultAccountName() {
		return localStorage.getItem(localStorageKeys.DEFAULT_ACCOUNT_NAME);
	},
	getUserPermissions() {
		return localStorage.getItem(localStorageKeys.USER_PERMISSIONS);
	},
	getTokenId() {
		return localStorage.getItem(localStorageKeys.TOKEN_ID);
	},
	getExpiresIn() {
		return localStorage.getItem(localStorageKeys.EXPIRES_IN);
	},
};
