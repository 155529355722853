import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';

import { mediaNames, mediaTypes } from '../../constants/mediaConstants';
import { useFetchLibraryData } from '../../hooks/screens/library/useFetchLibraryData';
import { formatDateToSearchRange, getCurrentMonthSearchRange } from '../../services/timeStampService';
import { setInspectingMediaData } from '../../actions/inspectScreenActions';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarMediasDisplay.scss';

const baseClassName = 'calendar-media-display';
const defaultTZ = moment.tz.guess();

export interface CalendarMediasDisplayProps {
	onSelectTimeSlot?: (_slotInfo: any) => void;
}

const CalendarMediasDisplay = ({ onSelectTimeSlot }: CalendarMediasDisplayProps) => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState<string>('');

	const setLoadingCallback = useCallback((value: boolean) => setLoading(value), []);

	const isLiveScreen = useCallback(() => {
		const locationArray = window.location.hash.split('/');
		return locationArray.findIndex((i) => i === 'livemedia') !== -1;
	}, []);

	const { items } = useFetchLibraryData({
		mediaType: mediaNames.medias,
		mediaFilters: {
			[mediaTypes.video]: false,
			[mediaTypes.livevideo]: true,
			[mediaTypes.uncatalogedMedias]: false,
		},
		searchQuery,
		offset: 0,
		isReFetched: isLiveScreen() && loading,
		shouldCheckRefetch: true,
		setLoading: setLoadingCallback,
	});

	const events: any[] = useMemo(() => {
		const result = items.map((item) => {
			if (!item.custom?.['event-start'] && !item.custom?.livemanager?.start) {
				return undefined;
			}

			const alldayEvent = !item.custom?.livemanager?.stop && !item.custom?.['event-duration'];
			const eventStart = new Date(item.custom?.livemanager?.start ?? item.custom?.['event-start']);
			const eventEnd = moment(item.custom?.livemanager?.stop ?? eventStart);
			if (!item.custom?.livemanager?.stop && item.custom?.['event-duration']) {
				const eventDuration = moment.duration(item.custom?.['event-duration']);
				eventEnd.add(eventDuration);
			}

			return {
				id: item.id,
				title: item.metadata?.title ?? '',
				start: eventStart,
				end: alldayEvent ? eventStart : eventEnd.toDate(),
				allDay: alldayEvent,
				resource: item
			};
		});

		return result.filter(Boolean);
	}, [items]);

	const { getNow, localizer, myEvents, scrollToTime, views, eventPropGetter } = useMemo(() => {
		moment.tz.setDefault(defaultTZ);
		// Set Monday as start of the week
		moment.locale(defaultTZ, {
			week: {
				dow: 1,
				doy: 1,
			},
		});
		return {
			getNow: () => moment().toDate(),
			localizer: momentLocalizer(moment),
			myEvents: [...events],
			scrollToTime: moment().toDate(),
			eventPropGetter: (_event: any, _start: Date, _end: Date, _isSelected: boolean) => ({
				className: `${baseClassName}__event`,
			}),
			views: [Views.MONTH, Views.WEEK, Views.DAY],
		};
	}, [events]);

	const getSearchQuery = useCallback((range: { start: Date; end: Date }) => {
		const dateSearchRange = formatDateToSearchRange(range);
		let newSearchQuery = `q=*${encodeURIComponent(
			' AND '
		)}(custom.event-start:${dateSearchRange}${encodeURIComponent(
			' OR '
		)}custom.livemanager.start:${dateSearchRange})`;
		setSearchQuery(newSearchQuery);
		setLoading(true);
	}, []);

	const handleRangeChange = useCallback(
		(range: any) => {
			const newStart = range.start || range[0];
			const newEnd = range.end || range[range.length - 1];
			getSearchQuery({ start: newStart, end: newEnd });
		},
		[getSearchQuery]
	);

	useEffect(() => {
		getSearchQuery(getCurrentMonthSearchRange());
	}, [getSearchQuery]);

	const handleSelectSlot = (slotInfo: any) => {
		if (slotInfo.action === "doubleClick") {
			onSelectTimeSlot?.(slotInfo);
		}
	};

	const handleSelectEvent = (event: any) => {
		const mediaDetail = event.resource;
		dispatch(
			setInspectingMediaData({
				id: mediaDetail.id,
				mediaDetails: mediaDetail,
				mediaName: mediaDetail?.metadata?.title,
				type: mediaNames.medias,
			})
		);
	}

	return (
		<Calendar
			showMultiDayTimes
			defaultView={Views.MONTH}
			views={views}
			events={myEvents}
			localizer={localizer}
			scrollToTime={scrollToTime}
			startAccessor="start"
			endAccessor="end"
			allDayMaxRows={3}
			elementProps={{ id: `${baseClassName}__wrapper` }}
			getNow={getNow}
			eventPropGetter={eventPropGetter}
			onRangeChange={handleRangeChange}
			onSelectSlot={handleSelectSlot}
			onSelectEvent={handleSelectEvent}
			selectable
		/>
	);
};

export default CalendarMediasDisplay;
