import React, { Component, createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Collapse, IconButton, Typography } from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import Avatar from 'react-avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import down_arrow_black from './../../../../assets/images/down_arrow_black.svg';
import IconsStore from './../../../../reusable/IconsStore/IconsStore';
import { IconsList } from './../../../../reusable/IconsStore/IconsList';
import up_down_arrow from './../../../../assets/images/up_down_arrow.svg';

import { ListingMenuDropDown } from './../../../../reusable/ListingMenuDropDown/ListingMenuDropDown';
import {
	listAllUsers,
	userDetails,
	userDelete,
	userUpdate,
	createNewUser,
	addUserToAnAccount,
} from './../../../../actions/profileActions';
import {
	addPermission,
	updatePermission,
	deletePermission,
	getPermission,
} from './../../../../actions/permissionActions';
import { getAccountFeatures } from './../../../../actions/accountFeaturesActions';
import { LocalStorageService } from './../../../../services/localStorageService';
import FeaturesService from './../../../../services/FeaturesService';
import { showMessage } from '../../../../actions/globalActions';
import { messageTypes } from '../../../../constants/mediaConstants';

import UserPermissionsList from './UserPermissionsList';
import CatalogTree from '../../../../reusable/CatalogTree/CatalogTree';
import { ACCESS_LEVEL } from '../../../../reusable/CatalogTree/CatalogLimitedAccess';
import { updateCatalogAccessLimit } from '../../../../actions/publishActions';
import { featurePermissionsList } from '../../../../constants/featurePermissionsConstant';

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			featurePermissions: [],
			customerId: null,
			accounts: [],
			userToEdit: {},
			userToCreate: {
				features: [],
			},
			currentlySelectedUser: {},
			searchKeyword: '',
			sortByAscending: true,
			showEditDialog: false,
			catalogCollapse: false,
			permissionFromUsers: {},
			infoMessage: '',
		};
		this.catalogTreeRef = createRef();
	}

	componentDidMount = () => {
		this.getCustomerId();
		this.loadAccountFeatures();
		this.loadPermissionForAllUsers();
	};

	componentDidUpdate(prevProps) {
		if (prevProps.users !== this.props.users) {
			this.loadPermissionForAllUsers();
		}
	}

	getInitials = (str) => {
		let result = 'notfound';
		try {
			let firstLetter = str.charAt(0);
			let afterDot = str.substr(str.indexOf('.'));
			let secondLetter = afterDot.charAt(1);
			result = firstLetter + ' . ' + secondLetter;
		} catch (err) {
			console.error(err);
		}
		return result;
	};

	stringToColor = (str) => {
		var hash = 0;
		try {
			for (var i = 0; i < str.length; i++) {
				hash = str.charCodeAt(i) + ((hash << 5) - hash);
			}
		} catch (err) {
			console.error(err);
		}
		var h = hash % 260;
		return 'hsl(' + h + ', ' + 39 + '%, ' + 50 + '%)';
	};

	showEditDialogBoxHandler = (data) => {
		this.setState({ showEditDialog: true });

		let user = this.state.userToEdit || {};
		user.userId = data.userId;
		user.login = data.login;

		this.setState({ userToEdit: user });

		this.getUserAccounts(data.userId);
		this.getUserPermissions(data.userId);
	};

	getUserAccounts = (userId) => {
		let user = this.state.userToEdit;
		const { defaultAccountId } = this.props;
		this.props.userDetails(defaultAccountId, userId).then((data) => {
			user.accounts = data;
			this.setState({ userToEdit: user });
		});
	};

	getUserPermissions = (userId) => {
		let user = this.state.userToEdit;
		const { defaultAccountId } = this.props;
		this.props.getPermission(defaultAccountId, userId).then((data) => {
			user.features = data && data.permissions;
			this.setState({ userToEdit: user });
		});
	};

	handleSorting = (_) => this.setState({ sortByAscending: !this.state.sortByAscending });
	handleNewUserDialogBox = (_) => this.setState({ showNewUserDialog: true });
	hideNewUserDialogHandler = (_) =>
		this.setState({ showNewUserDialog: false }, () => {
			this.resetUserToEdit();
		});
	hideEditDialogHandler = () =>
		this.setState({ showEditDialog: false, infoMessage: '' }, () => {
			this.resetUserToEdit();
		});

	getCustomerId = () => {
		const { defaultAccountId } = this.props;
		var accounts = JSON.parse(LocalStorageService.getAccounts());

		var account = accounts && accounts.find((a) => a.accountId === defaultAccountId);
		this.setState({ customerId: account.customerId });
	};

	loadAccountFeatures = () => {
		const { defaultAccountId, getAccountFeatures } = this.props;
		getAccountFeatures(defaultAccountId).then((features) => {
			let featurePermissions = features?.permissions ?? [];
			featurePermissions = _.sortBy(featurePermissions, ['name']);
			this.setState({
				featurePermissions: featurePermissions,
				userToCreate: {
					features: featurePermissions,
				},
			});
		});
	};

	handleUserToEdit = () => {
		const { defaultAccountId, deletePermission, addPermission, updateCatalogAccessLimit, showMessage, t } =
			this.props;
		const { userToEdit } = this.state;
		let totalFeaturesToRemove = userToEdit.featuresToRemove && userToEdit.featuresToRemove.length;

		userToEdit.featuresToRemove &&
			userToEdit.featuresToRemove.forEach((feature, i) => {
				setTimeout(() => {
					deletePermission(defaultAccountId, userToEdit.userId, feature.featureId).then(() => {});
				}, i++ * 1200);
			});

		userToEdit.featuresToAdd &&
			userToEdit.featuresToAdd.length > 0 &&
			setTimeout(() => {
				addPermission(defaultAccountId, userToEdit.userId, userToEdit.featuresToAdd).then(() => {});
			}, totalFeaturesToRemove * 1250);

		this.setState({ showEditDialog: false, catalogCollapse: false, infoMessage: '' });

		const accessCatalogNeedToBeUpdated = this.catalogTreeRef.current?.getCatalogsAcessNeedToUpdate();

		if (accessCatalogNeedToBeUpdated.length !== 0) {
			const promisseList = accessCatalogNeedToBeUpdated.map(({ id, access }) => {
				const standdardlizeAcces = Object.entries(access).reduce((all, [userId, accessLevel]) => {
					return {
						...all,
						[userId]: { id: userId, accessLevel: accessLevel === 'admin' ? 'FULL' : 'LIMITED' },
					};
				}, {});
				return updateCatalogAccessLimit(defaultAccountId, id, standdardlizeAcces);
			});

			Promise.all(promisseList)
				.then(() => {
					showMessage(`${t('SETTING_USER_INFORMATION_UPDATED')}.`, messageTypes.success);
				})
				.catch((e) => {
					showMessage(`${t('SETTING_USER_ERROR_UPDATE_USER_CATALOG')}.`, messageTypes.error);
					console.error(e);
				});
		}
		this.catalogTreeRef.current?.resetCatalogUpdateList();
	};

	handleUserCreation = () => {
		const { defaultAccountId, createNewUser, addUserToAnAccount, addPermission, showMessage, t } = this.props;
		const { userToCreate } = this.state;

		createNewUser(userToCreate.login, userToCreate.IpRestrictions).then((data) => {
			let userId = data?.userId ?? "";
			let userIds = { userIds: [userId] };
			let permissions = [];

			userToCreate.features &&
				userToCreate.features.forEach((feature) => {
					permissions.push({ featureId: feature.featureId, accessLevel: 2 });
				});

			// Add user to current account
			addUserToAnAccount(defaultAccountId, userIds).then((res) => {
				if (!res) {
					showMessage(
						`${t('SETTING_USER_CANNOT_ADD_PRE')} ${userToCreate.login} ${t(
							'SETTING_USER_CANNOT_ADD_POST'
						)}.`,
						messageTypes.error
					);
					return;
				}

				setTimeout(() => {
					permissions.length > 0 &&
						addPermission(defaultAccountId, userId, permissions).then(() => {
							// Refresh user list
							this.getUserList();
						});
				});
			});

			userToCreate.accounts &&
				userToCreate.accounts.forEach((account) => {
					addUserToAnAccount(account.accountId, userIds).then(() => {});
					addPermission(account.accountId, userId, permissions).then(() => {});
				});
		});
		this.setState({ showNewUserDialog: false });
		this.resetUserToCreate();
	};

	handleSearchKeyword = (event) => {
		let keyword = event.target.value;
		this.setState({ searchKeyword: keyword });
	};

	handleEmailChange = (event) => {
		let user = this.state.userToCreate;
		user.login = event.target.value.toLowerCase();
		this.setState({ userToCreate: user });
	};

	handleIPRestrictionChange = (event) => {
		let user = this.state.userToCreate;
		user.IpRestrictions = event.target.value;
		this.setState({ userToCreate: user });
	};

	handleAccountsChange = (event) => {
		let user = this.state.userToCreate;
		if (event.target.checked) {
			if (!Array.isArray(user.accounts)) {
				user.accounts = [];
			}
			user.accounts && user.accounts.push({ accountId: event.target.value });
		} else {
			user.accounts =
				user.accounts &&
				user.accounts.filter((account) => {
					return account.accountId != event.target.value;
				});
		}
		this.setState({ userToCreate: user });
	};

	handleFeaturesChange = (event) => {
		let user = this.state.userToCreate;
		if (event.target.checked) {
			if (!Array.isArray(user.features)) {
				user.features = [];
			}
			user.features && user.features.push({ featureId: event.target.value, accessLevel: 2 });
		} else {
			user.features =
				user.features &&
				user.features.filter((f) => {
					return f.featureId != event.target.value;
				});
		}
		this.setState({ userToCreate: user });
	};

	handleEmailChangeInEdit = (event) => {
		let user = this.state.userToEdit;
		user.login = event.target.value;
		this.setState({ userToEdit: user });
	};

	handleIPRestrictionChangeInEdit = (event) => {
		let user = this.state.userToEdit;
		user.IpRestrictions = event.target.value;
		this.setState({ userToEdit: user });
	};

	handleFeaturesChangeInEdit = (event) => {
		let user = this.state.userToEdit;

		if (!Array.isArray(user.featuresToAdd)) {
			user.featuresToAdd = [];
		}
		if (!Array.isArray(user.featuresToRemove)) {
			user.featuresToRemove = [];
		}

		if (event.target.checked) {
			let alreadyExist =
				user.featuresToAdd &&
				user.featuresToAdd.find((f) => {
					return f.featureId === event.target.value;
				});
			!alreadyExist &&
				user.featuresToAdd &&
				user.featuresToAdd.push({ featureId: event.target.value, accessLevel: 2 });
			user.featuresToRemove =
				user.featuresToRemove &&
				user.featuresToRemove.filter((f) => {
					return f.featureId != event.target.value;
				});
		} else {
			let alreadyExist =
				user.featuresToRemove &&
				user.featuresToRemove.find((f) => {
					return f.featureId === event.target.value;
				});
			!alreadyExist &&
				user.featuresToRemove &&
				user.featuresToRemove.push({ featureId: event.target.value, accessLevel: 0 });
			user.featuresToAdd =
				user.featuresToAdd &&
				user.featuresToAdd.filter((f) => {
					return f.featureId != event.target.value;
				});
		}
		this.setState({ userToEdit: user });
	};

	handleFeatureExist = (featureId) => {
		const { userToEdit } = this.state;
		const exist =
			userToEdit.features &&
			userToEdit.features.find((e) => {
				return e.featureId === featureId && e.accessLevel === 2;
			});
		const existInFeaturesToBeRemove =
			userToEdit.featuresToRemove &&
			userToEdit.featuresToRemove.find((e) => {
				return e.featureId === featureId;
			});
		const existInFeaturesToBeAdded =
			userToEdit.featuresToAdd &&
			userToEdit.featuresToAdd.find((e) => {
				return e.featureId === featureId;
			});
		return (exist && !existInFeaturesToBeRemove) || existInFeaturesToBeAdded ? true : false;
	};

	featureExistInCurrentAccount = (featureId) => {
		const { userPermissions } = this.props;
		const permissions = userPermissions && userPermissions.length > 0 ? JSON.parse(userPermissions) : [];

		const exist =
			permissions &&
			permissions.find((e) => {
				return e.featureId === featureId;
			});
		return exist ? true : false;
	};

	handlePermisionCheckBoxForCreateNewAccount = (featureId) => {
		const {
			userToCreate: { features },
		} = this.state;

		return !!(features && features.find((f) => f.featureId === featureId));
	};

	handleDeleteUserDialogHandler = (user) =>
		this.setState({ deleteUserDialogStatus: true, currentlySelectedUser: user });

	showActionMenuItems = (user, e) => this.setState({ currentlySelectedUser: user, actionAnchor: e.target });

	handleMenuClose = (menuAction, user) => {
		if (menuAction === 'remove') {
			if (user?.userId === LocalStorageService.getUserId()) {
				return;
			}
			this.handleDeleteUserDialogHandler(user);
		} else if (menuAction === 'details') {
			this.showEditDialogBoxHandler(user);
		}
		this.setState({ actionAnchor: null });
	};

	handleHideDeleteUserDialog = (_) => this.setState({ deleteUserDialogStatus: false });

	handleUserDelete = (_) => {
		const { defaultAccountId, deleteUser } = this.props;
		const { currentlySelectedUser } = this.state;
		if (currentlySelectedUser?.userId === LocalStorageService.getUserId()) {
			this.handleHideDeleteUserDialog();
			return;
		}

		deleteUser(defaultAccountId, currentlySelectedUser.userId).then(() => {
			this.getUserList();
		});
		this.handleHideDeleteUserDialog();
	};

	getUserList = (_) => {
		const { defaultAccountId, listAllUsers } = this.props;

		listAllUsers(defaultAccountId).then(() => {
			//this.props.users = users
		});
	};

	isFeatureEnabled = (featureName) => {
		const { userPermissions } = this.props;
		return FeaturesService.IsEnabled(userPermissions, featureName);
	};

	resetUserToEdit = (_) => {
		this.setState({ userToEdit: {}, catalogCollapse: false });
	};

	resetUserToCreate = (_) => {
		this.setState(prevState => ({ userToCreate: { features: prevState.featurePermissions }, catalogCollapse: false }));
	};

	getFeatureName = (feature) => {
		if (feature.featureId === featurePermissionsList.scenarios.featureId) {
			return featurePermissionsList.scenarios.name;
		}
		if (feature.featureId === featurePermissionsList.interact.featureId) {
			return featurePermissionsList.interact.name;
		}

		if (feature.name === 'InteractiveEditor') {
			return this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_INTERACTIVE_MANAGER');
		}
		return feature.name;
	};

	loadPermissionForAllUsers = () => {
		const { users, defaultAccountId, getPermission } = this.props;

		const usersPermissionPromisses = users.map(({ userId }) => {
			return new Promise((resolve) => {
				getPermission(defaultAccountId, userId)
					.then((data) => {
						if (!data) {
							resolve({
								[userId]: [],
							});
							return;
						}
						const { permissions } = data;
						resolve({
							[userId]: permissions,
						});
					})
					.catch(() => {
						resolve({
							[userId]: [],
						});
					});
			});
		});

		usersPermissionPromisses &&
			Promise.all(usersPermissionPromisses).then((result) => {
				const hashed = result.reduce((all, curr) => {
					return { ...all, ...curr };
				}, {});
				this.setState({ permissionFromUsers: hashed });
			});
	};

	render() {
		const {
			showNewUserDialog,
			showEditDialog,
			userToEdit,
			userToCreate,
			invalidEmail,
			deleteUserDialogStatus,
			currentlySelectedUser,
			actionAnchor,
			searchKeyword,
			sortByAscending,
			featurePermissions,
			catalogCollapse,
			permissionFromUsers,
			infoMessage,
		} = this.state;

		const { t, users = [] } = this.props;

		let searchResults = [];
		searchResults = users.filter((user) => {
			return (
				!searchKeyword ||
				(searchKeyword && searchKeyword.trim().length === 0) ||
				(searchKeyword && user.login.toLocaleLowerCase().includes(searchKeyword.trim().toLocaleLowerCase()))
			);
		});

		searchResults = searchResults.sort((a, b) => {
			return sortByAscending
				? b.login.toUpperCase() <= a.login.toUpperCase()
					? 1
					: -1
				: b.login.toUpperCase() > a.login.toUpperCase()
				? 1
				: -1;
		});

		const qbrickCMSRegex = /cms@qbrick.com$/g; // Hide QBrick's cms accounts
		searchResults = searchResults.filter((r) => !r.login.match(qbrickCMSRegex));

		return (
			this.isFeatureEnabled('Users and features') && (
				<>
					<div className="usersContentWrapper">
						<div className="usersTableHeading">
							<h5>
								{searchResults.length} {t('SETTINGS_LIST_USER_LABEL')}{' '}
							</h5>
						</div>
						<div className="usersListingTopHeader">
							<input
								type="text"
								placeholder={t('SETTINGS_LIST_USER_SEARCH_LABEL')}
								className="searchTextInput"
								value={searchKeyword}
								onChange={(event) => this.handleSearchKeyword(event)}
							/>
							<Button
								variant="contained"
								className="newUserBtn defaultActionBtn"
								onClick={this.handleNewUserDialogBox}
								startIcon={<IconsStore iconName={IconsList.PLUS_NEW} color="#fff" />}
							>
								{t('COMMON_NEW')}
							</Button>
						</div>
						<div className="usersTableWrapper">
							<div className="usersTableRowCollection usersTableHeader">
								<div className="usersTableRow ">
									<div className="usersTableItem">
										<label> </label>
									</div>
									<div className="usersTableItem">
										<label>{t('COMMON_EMAIL')}</label>
										<div className="sort" onClick={this.handleSorting}>
											<img src={up_down_arrow} className="userSortIcon" />
											<label style={{ fontWeight: 'normal' }}>
												{sortByAscending ? 'A-Z' : 'Z-A'}
											</label>
										</div>
									</div>
									<div className="usersTableItem">
										<label>{t('SETTINGS_USER_LIST_APP_PERMISSION_LABEL')}</label>
									</div>
									<div className="usersTableItem">
										<label>{t('SETTINGS_USER_LIST_IP_RESTRICTIONS_LABEL')}</label>
									</div>
									<div className="usersTableItem">
										<label> </label>
									</div>
								</div>
							</div>
							<div className="usersTableRowCollection usersTableItems">
								{searchResults.map((user, i) => {
									return (
										<div onClick={() => {}} key={i}>
											<div key={i} className="usersTableRow">
												<div className="usersTableItem">
													<Avatar
														className="headerIcon"
														color={this.stringToColor(user.login)}
														name={this.getInitials(user.login)}
														size="50"
													/>
												</div>
												<div className="usersTableItem" data-header={t('COMMON_EMAIL')}>
													{user.login}
												</div>
												<div
													className="usersTableItem"
													data-header={t('SETTINGS_USER_LIST_APP_PERMISSION_LABEL')}
												>
													<UserPermissionsList
														userId={user.userId}
														featurePermissions={featurePermissions}
														userPermissions={permissionFromUsers[user.userId]}
													/>
												</div>
												<div
													className="usersTableItem"
													data-header={t('SETTINGS_USER_LIST_IP_RESTRICTIONS_LABEL')}
												>
													{user.IpRestrictions}
												</div>
												<div className="usersTableItem">
													<button
														className="actionButton"
														onClick={(e) => this.showActionMenuItems(user, e)}
													>
														{t('SETTINGS_USER_ACTIONS_BUTTON_LABEL')}
														<img src={down_arrow_black} />
													</button>
													{actionAnchor && currentlySelectedUser.userId === user.userId && (
														<ListingMenuDropDown
															attr={'editUserDetailDropdown'}
															menuList={[
																{
																	menuAction: 'details',
																	menuTitle: t('SETTINGS_EDIT_USER_DETAILS'),
																},
																user.userId === LocalStorageService.getUserId()
																	? undefined
																	: {
																			menuAction: 'remove',
																			menuTitle: t(
																				'SETTINGS_REMOVE_USER_FROM_ACCOUNT'
																			),
																	  },
															]}
															objectParams={currentlySelectedUser}
															menuAnchor={actionAnchor}
															handleMenuClose={this.handleMenuClose}
														/>
													)}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<Dialog
						open={showNewUserDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle className="dialogTitle" disableTypography>
							<Typography variant="h6">{t('SETTINGS_NEW_USER_LABEL')}</Typography>
							<IconButton onClick={this.hideNewUserDialogHandler}>
								<Close />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<div className="dialogContentWrapper">
								<div className="dialogBoxInternalBlock">
									<label>
										{t('COMMON_EMAIL') + '*'}
										<span className="invalidEmailSpan">
											{invalidEmail ? t('SETTINGS_INVALID_EMAIL_MESSAGE') : ''}
										</span>
									</label>
									<input
										type="email"
										className="genericTextInput"
										value={userToCreate.login}
										onChange={(event) => this.handleEmailChange(event)}
										required
										placeholder="email@qbrick.com"
									/>
								</div>
								<div className="dialogBoxInternalBlock">
									<label>{t('SETTINGS_USER_LIST_IP_RESTRICTIONS_LABEL')}</label>
									<input
										type="text"
										className="genericTextInput"
										value={userToCreate.IpRestrictions}
										onChange={(event) => this.handleIPRestrictionChange(event)}
										placeholder={'000.000.000.000'}
									/>
								</div>
								<div className="dialogBoxInternalBlock features userFeatures">
									<label className="userAppLabel">{t('SETTINGS_USER_APP_PERMISSION_LABEL')}</label>
									{featurePermissions &&
										featurePermissions.map((feature, i) => (
											<div key={i} className="userAppPermission">
												<Checkbox
													id={feature.featureId}
													style={{ color: '#126CFC', paddingLeft: '0px' }}
													onChange={this.handleFeaturesChange}
													value={feature.featureId}
													checked={this.handlePermisionCheckBoxForCreateNewAccount(
														feature.featureId
													)}
												/>
												<label htmlFor={feature.featureId}>
													{this.getFeatureName(feature)}
												</label>
											</div>
										))}
								</div>
							</div>
						</DialogContent>
						<DialogActions>
							<div className="dialogActionsWrapper">
								<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
									<Button
										variant="contained"
										disabled={!userToCreate.login}
										className="defaultActionBtn"
										onClick={this.handleUserCreation}
									>
										{t('SETTINGS_SAVE_USER_LABEL')}
									</Button>
								</div>
							</div>
						</DialogActions>
					</Dialog>
					<Dialog
						open={showEditDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle className="dialogTitle" disableTypography>
							<Typography variant="h6">{t('SETTINGS_EDIT_USER')}</Typography>
							<IconButton onClick={this.hideEditDialogHandler}>
								<Close />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<div className="dialogContentWrapper">
									<div className="dialogBoxInternalBlock">
										<label>
											{t('COMMON_EMAIL')}
											<span className="invalidEmailSpan">
												{invalidEmail ? t('SETTINGS_INVALID_EMAIL_MESSAGE') : ''}
											</span>
										</label>
										<input
											type="email"
											className="genericTextInput"
											value={userToEdit.login}
											onChange={(event) => this.handleEmailChangeInEdit(event)}
											required
										/>
									</div>
									<div className="dialogBoxInternalBlock">
										<label>{t('SETTINGS_USER_LIST_IP_RESTRICTIONS_LABEL')}</label>
										<input
											type="text"
											className="genericTextInput"
											value={userToEdit.IpRestrictions}
											onChange={(event) => this.handleIPRestrictionChangeInEdit(event)}
											placeholder={'000.000.000.000'}
										/>
									</div>

									<div className="dialogBoxInternalBlock features userFeatures">
										<label className="userAppLabel">
											{t('SETTINGS_USER_APP_PERMISSION_LABEL')}
										</label>
										{featurePermissions &&
											featurePermissions.map((feature, i) => {
												const isDisabled =
													userToEdit?.userId === LocalStorageService.getUserId();
												return (
													<div key={i} className="userAppPermission">
														<Checkbox
															id={feature.featureId}
															style={
																isDisabled
																	? undefined
																	: { color: '#126CFC', paddingLeft: 0 }
															}
															onChange={
																isDisabled ? () => {} : this.handleFeaturesChangeInEdit
															}
															value={feature.featureId}
															checked={this.handleFeatureExist(feature.featureId)}
															disabled={isDisabled}
														/>
														<label htmlFor={feature.featureId}>
															{this.getFeatureName(feature)}
														</label>
													</div>
												);
											})}
									</div>
									{userToEdit.accounts && (
										<div className="dialogBoxInternalBlock">
											<label>{t('SETTINGS_ACCOUNT_PERMISSIONS')}</label>
											<div className="accounts">
												{userToEdit.accounts.map((account) => {
													return (
														<>
															<span className={'accountTitle'}>{account.name}</span>
														</>
													);
												})}
											</div>
										</div>
									)}
									<div className="dialogBoxInternalBlock">
										<Button
											onClick={() => {
												this.setState({ catalogCollapse: !catalogCollapse });
											}}
											endIcon={catalogCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
										>
											<label className="userAppLabel">{t('SETTINGS_CATALOG_ACCESS')}</label>
										</Button>
										<Collapse in={catalogCollapse}>
											<div className="catalogs">
												<CatalogTree
													ref={this.catalogTreeRef}
													showCheckbox
													showDropdown
													dropdownMenuItem={ACCESS_LEVEL}
													hiddenAllCatalogsOpt
													editingUser={userToEdit}
													setInfoMessage={(message) => {
														this.setState({
															infoMessage: `${t(
																'SETTING_USER_CATALOG_EXPOSE_TO_EVERYONE'
															)}: ${message}`,
														});
													}}
												/>
											</div>
										</Collapse>
									</div>
								</div>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<div className="dialogActionsWrapper">
								<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
									<Button
										variant="contained"
										disabled={!userToEdit.login}
										className="defaultActionBtn"
										onClick={this.handleUserToEdit}
									>
										{t('SETTINGS_SAVE_USER_LABEL')}
									</Button>
									{infoMessage && (
										<Box display="flex" alignItems="center" padding="24px">
											<InfoOutlined />
											<Typography style={{ paddingLeft: 10 }} variant="caption">
												{infoMessage}
											</Typography>
										</Box>
									)}
								</div>
							</div>
						</DialogActions>
					</Dialog>

					<Dialog
						open={deleteUserDialogStatus}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle className="dialogTitle" disableTypography>
							<Typography variant="h6">{t('SETTING_LABEL_ARE_YOU_SURE_TO_DELETE_USER')}</Typography>
							<IconButton onClick={this.handleHideDeleteUserDialog}>
								<Close />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<div className="dialogContentWrapper">
									<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
										{<label>{currentlySelectedUser.login}</label>}
									</div>
								</div>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<div className="dialogActionsWrapper">
								<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
									<Button
										variant="contained"
										className="defaultActionBtn neutralBtn"
										onClick={this.handleHideDeleteUserDialog}
									>
										{t('COMMON_BACK')}
									</Button>
									<Button
										variant="contained"
										className="defaultActionBtn deleteButtonWithIcon"
										onClick={this.handleUserDelete}
									>
										<IconsStore iconName={IconsList.TRASHCAN} />
										{t('COMMON_DELETE')}
									</Button>
								</div>
							</div>
						</DialogActions>
					</Dialog>
				</>
			)
		);
	}
}

const mapStateToProps = ({ session, profile, accountFeatures }) => ({
	loading: session.loading,
	defaultAccountId: session.defaultAccountId,
	userPermissions: session.userPermissions,
	users: profile.users,
	features: accountFeatures.features,
});

const mapDispatchToProps = (dispatch) => ({
	listAllUsers: (accountId) => dispatch(listAllUsers(accountId)),
	createNewUser: (email, ip) => dispatch(createNewUser(email, ip)),
	deleteUser: (accountId, userId) => dispatch(userDelete(accountId, userId)),
	userDetails: (accountId, userId) => dispatch(userDetails(accountId, userId)),
	userUpdate: (userId, data) => dispatch(userUpdate(userId, data)),
	addUserToAnAccount: (accountId, data) => dispatch(addUserToAnAccount(accountId, data)),
	addPermission: (accountId, userId, data) => dispatch(addPermission(accountId, userId, data)),
	updatePermission: (accountId, userId, data) => dispatch(updatePermission(accountId, userId, data)),
	getPermission: (accountId, userId) => dispatch(getPermission(accountId, userId)),
	deletePermission: (accountId, userId, featureId) => dispatch(deletePermission(accountId, userId, featureId)),
	getAccountFeatures: (accountId) => dispatch(getAccountFeatures(accountId)),
	showMessage: (mess, type) => dispatch(showMessage(mess, type)),
	updateCatalogAccessLimit: (defaultAccountId, id, restrictionUsers) =>
		dispatch(updateCatalogAccessLimit(defaultAccountId, id, restrictionUsers)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Users);
