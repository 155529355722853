import React, { useEffect, useState } from 'react';
import { Mosaic, MosaicWindow } from 'react-mosaic-component';
import { useSelector } from 'react-redux';

import { liveManagerPopOutWindowList } from '../../../constants/liveManagerConstants';
import ViewerEngagement from '../../../components/LiveManagerSettings/ViewEngagement/ViewerEngagement';
import { qplayerDomain } from '../../../utils/config';

interface ViewerEngagementModeratorProps {
	mediaDetails: any;
	mediaId: string;
}

const ViewerEngagementModerator = ({ mediaDetails, mediaId }: ViewerEngagementModeratorProps) => {
	const { defaultAccountId } = useSelector((state) => (state as any).session);

	const [mosaicValue, setMosaicValue] = useState<any>({
		direction: 'row',
		first: liveManagerPopOutWindowList.PLAYER,
		second: liveManagerPopOutWindowList.SETTINGS,
		splitPercentage: 67,
	});

	const onChangeMosaicValue = (mosaicValue: any) => {
		setMosaicValue(mosaicValue);
	};

	const renderViewerEngagementContainer = () => (
		<ViewerEngagement
			mediaId={mediaId}
			mediaDetails={mediaDetails}
			reloadData={() => {}}
			moderatorMode
		/>
	);

	const renderTiles = (index: string) => {
		switch (index) {
			case liveManagerPopOutWindowList.PLAYER:
				return <div className="LivePlayerWrapper" id="player-preview-wrapper" />;
			case liveManagerPopOutWindowList.SETTINGS:
				return renderViewerEngagementContainer();
		}
	};

	useEffect(() => {
		if (!mediaId) {
			return;
		}

		setTimeout(() => {
			const playerIframe = document.createElement('iframe');
			playerIframe.src = `${qplayerDomain}?accountId=${defaultAccountId}&mediaId=${mediaId}&configId=Interactive`;
			playerIframe.className = 'iframe-player-preview';
			playerIframe.width = '100%';
			playerIframe.height = '100%';
			playerIframe.setAttribute('allowFullScreen', 'true');

			const wrapper = document.querySelector('#player-preview-wrapper');
			if (wrapper) {
				wrapper.appendChild(playerIframe);
			}
		}, 300);
	}, [defaultAccountId, mediaId]);

	return (
		<div className="LiveManagerScreenWrapper moderatorMode">
			<Mosaic
				renderTile={(count, path) => {
					return (
						<MosaicWindow title={''} path={path}>
							{renderTiles(count)}
						</MosaicWindow>
					);
				}}
				value={mosaicValue}
				onChange={onChangeMosaicValue}
				onRelease={onChangeMosaicValue}
			/>
		</div>
	);
};

export default ViewerEngagementModerator;
