import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import {
	pinMessage,
	saveBlockedUsers,
	saveUnblockedUsers,
	setBlockMessage,
	setBlockUser,
	setUnblockUser,
	unpinMessage,
	updateUnpinMessage,
} from '../../../../actions/liveChatActions';
import { postMessage } from '../../../../actions/liveChatActions';
import { mediaNames, messageTypes } from '../../../../constants/mediaConstants';
import { showMessage } from '../../../../actions/globalActions';
import { setOpenChatSettings, setViewEngagementLoading } from '../../../../actions/viewEngagementActions';
import { chatWebSocketUrl } from '../../../../utils/config';
import { liveWebSocketEvents } from '../../../../constants/liveChatConstant';

import { ViewerEngagementTemplate } from '../ViewerEngagementTemplate';
import { isChatProfileEmpty } from '../utils';

import { ChatContainerTemplate } from './ChatContainerTemplate';
import { useFetchChatData } from './useFetchChatData';

import './ChatContainer.scss';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { getMediaQueryDetails, saveInspectScreenDetails } from '../../../../actions/publishActions';
import { getDetails } from '../../../../services/mediaDisplayService';

const ChatContainer: React.FC<ChatContainerTemplate.ChatContainerProps> = ({
	mediaId,
	onShowCreatePoll,
	reloadData,
	settings,
	mediaDetails,
}) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const webSocketConnection = useRef<any | null>(null);
	const { defaultAccountId, username } = useSelector((state: any) => state.session);

	const [shouldUpdateData, setShouldUpdateData] = useState<boolean>(true);
	const [inputText, setInputText] = useState<string>('');
	const [replyingMessage, setReplyingMessage] = useState<ChatContainerTemplate.Message | undefined>(undefined);
	const [messages, setMessages] = useState<ChatContainerTemplate.Message[]>([]);
	const [isChatActive, setIsChatActive] = useState<boolean>(mediaDetails?.custom?.activatedChat !== false);

	const { blockedUserIds, pinnedMessage } = useFetchChatData({
		defaultAccountId,
		mediaId,
		shouldUpdateData,
		callback: () => {
			setShouldUpdateData(false);
			dispatch(setViewEngagementLoading(false));
		},
		setMessages: (mess: ChatContainerTemplate.Message[]) => setMessages(mess),
	});

	const checkSocket = () => {
		const connection = webSocketConnection.current;
		if (!connection) {
			console.info('No socket connection');
		}
		if (connection.readyState === 0) {
			console.info('Socket connection still in pending');
		}
		if (connection.readyState === 1) {
			console.info('Socket Connected');
		}
		if (connection.readyState === 3) {
			console.info('Socket closed');
		}
		return connection?.readyState === 1;
	};

	const sendData = () => {
		const connection = webSocketConnection.current;
		var keys = [
			liveWebSocketEvents.textCreated,
			liveWebSocketEvents.textUpdated,
			liveWebSocketEvents.textPinned,
			liveWebSocketEvents.textUnPinned,
			liveWebSocketEvents.reactionCreated,
			liveWebSocketEvents.reactionUpdated,
			liveWebSocketEvents.blockMessage,
			liveWebSocketEvents.blockUser,
			liveWebSocketEvents.unblockMessage,
			liveWebSocketEvents.unblockUser,
		];
		var request = {
			key: '',
			body: {
				filter: {
					tenantId: defaultAccountId,
					mediaId: mediaId,
				},
			},
		};

		for (let i = 0; i < keys.length; i++) {
			request.key = keys[i];
			connection.send(JSON.stringify(request));
		}
	};

	const connectWebSocket = () => {
		const count = 0;
		if (count >= 10) {
			dispatch(showMessage('Websocket connection error...', messageTypes.error));
			return;
		}

		if (webSocketConnection.current?.close) {
			webSocketConnection.current?.close();
		}

		webSocketConnection.current = new WebSocket(chatWebSocketUrl ?? '');
		webSocketConnection.current.onopen = () => {
			if (checkSocket()) {
				sendData();
			}
		};

		webSocketConnection.current.onmessage = (evt: any) => {
			// listen to data sent from the websocket server
			const message = JSON.parse(evt.data);
			let key = 'Subscribe.On.' + message.key;

			switch (key) {
				case liveWebSocketEvents.textCreated:
					if (message && message.body && message.body.id && message.body.mediaId === mediaId) {
						setMessages((prevState) => [...prevState, { ...message.body }]);
					}
					break;
				case liveWebSocketEvents.textUpdated:
					if (message && message.body && message.body.id && message.body.mediaId === mediaId) {
						scrollToBottom();
					}
					break;
				case liveWebSocketEvents.textPinned:
				case liveWebSocketEvents.textUnPinned:
					scrollToBottom();
					break;
				case liveWebSocketEvents.blockUser:
					dispatch(saveBlockedUsers(message.body.userIds));
					break;
				case liveWebSocketEvents.unblockUser:
					dispatch(saveUnblockedUsers(message.body.userIds));
					break;
				default:
					break;
			}
		};
		webSocketConnection.current.onerror = () => {
			if (webSocketConnection.current?.close) {
				webSocketConnection.current?.close();
			}
		};

		webSocketConnection.current.onclose = (event: any) => {
			if (event.code === 3001) {
				console.info('Socket closed');
				return;
			}
			setTimeout(() => {
				if (checkSocket()) {
					return;
				}
				connectWebSocket();
			}, 1000);
		};
	};

	useEffect(() => {
		connectWebSocket();
		setViewEngagementLoading(true);
		scrollToBottom();

		return () => webSocketConnection.current?.close(3001);
	}, []);

	useLayoutEffect(() => {
		scrollToBottom();
	}, [messages, pinnedMessage]);

	const scrollToBottom = () => {
		const messageContainerRef = document.getElementsByClassName(
			'viewer-engagement-manager__tab-content__wrapper'
		)[0];
		if (!messageContainerRef) {
			return;
		}

		const allMessages = messageContainerRef.querySelectorAll('.chat-container__message-container');
		const lastMessage = allMessages[allMessages.length - 1];

		if (lastMessage) {
			lastMessage.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	};

	const onInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (!isChatActive) {
				return;
			}

			const value = event.target.value;
			if (value.trim().length > 300) {
				return;
			}
			if (!settings?.adminProfile?.chatName || isChatProfileEmpty(settings?.adminProfile)) {
				dispatch(setOpenChatSettings(true));
			}
			setInputText(value);
		},
		[dispatch, isChatActive, settings?.adminProfile]
	);

	const onInputFocus = useCallback(
		(_event: React.FocusEvent<HTMLInputElement>) => {
			if (!settings?.adminProfile?.chatName || isChatProfileEmpty(settings?.adminProfile)) {
				dispatch(setOpenChatSettings(true));
			}
		},
		[dispatch, settings?.adminProfile]
	);

	const onRefreshClick = useCallback(() => {
		setShouldUpdateData(true);
		dispatch(setViewEngagementLoading(true));
	}, [dispatch]);

	const onSendMessage = useCallback(
		(_: React.MouseEvent<HTMLButtonElement>) => {
			if (!settings?.adminProfile?.chatName || isChatProfileEmpty(settings?.adminProfile)) {
				return;
			}

			if (!inputText.trim()) {
				dispatch(
					showMessage(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_EMPTY_MESSAGE'), messageTypes.error)
				);
				return;
			}

			const body = {
				sender: {
					userId: username,
					name:
						settings && settings.adminProfile && settings.adminProfile.chatName
							? settings.adminProfile.chatName
							: 'admin',
					admin: true,
					email: username,
				},
				message: {
					pined: false,
					text: inputText,
				},
			};

			if (replyingMessage) {
				(body as any).recipient = {
					replyMessageId: replyingMessage.id,
				};
			}

			setInputText('');
			setReplyingMessage(undefined);
			dispatch(postMessage(defaultAccountId, mediaId, body)).then(() => {
				setTimeout(() => setShouldUpdateData(true));
			});
		},
		[inputText, settings, username, replyingMessage, dispatch, defaultAccountId, mediaId, t]
	);

	const onTogglePinnedMessage = useCallback(
		(messageId: string, pinned?: boolean) => {
			if (pinned) {
				if (pinnedMessage && pinnedMessage.id !== messageId) {
					dispatch(updateUnpinMessage(defaultAccountId, mediaId, pinnedMessage.id, pinnedMessage));
				}

				dispatch(pinMessage(defaultAccountId, mediaId, messageId)).then(() => {
					setTimeout(() => setShouldUpdateData(true));
				});
			} else {
				dispatch(unpinMessage(defaultAccountId, mediaId, messageId, pinnedMessage)).then(() => {
					setTimeout(() => setShouldUpdateData(true));
				});
			}
		},
		[defaultAccountId, dispatch, mediaId, pinnedMessage]
	);

	const onReplyMessageClick = useCallback(
		(messId: string) => {
			const mess = messages.find((m: ChatContainerTemplate.Message) => m.id === messId);
			setReplyingMessage(mess);
		},
		[messages]
	);

	const onBlockMessageClick = useCallback(
		(message: ChatContainerTemplate.Message) => {
			dispatch(setBlockMessage(defaultAccountId, mediaId, [message.id]));
			setTimeout(() => setShouldUpdateData(true));
		},
		[defaultAccountId, dispatch, mediaId]
	);

	const onBlockUserClick = useCallback(
		(message: ChatContainerTemplate.Message, block?: boolean) => {
			if (block) {
				dispatch(setBlockUser(defaultAccountId, mediaId, [message.sender.userId]));
			} else {
				dispatch(setUnblockUser(defaultAccountId, mediaId, [message.sender.userId]));
			}
			setTimeout(() => setShouldUpdateData(true), 1500);
		},
		[defaultAccountId, dispatch, mediaId]
	);

	const onToggleChat = useCallback(
		(_: React.ChangeEvent<{}>, checked: boolean) => {
			dispatch(setViewEngagementLoading(true));
			dispatch(getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias)).then((media: any) => {
				let a = getDetails(media, false);
				if (!a) {
					return;
				}

				let mediaDetail = a;
				mediaDetail.custom['activatedChat'] = checked;
				const body = {
					id: mediaDetail.id,
					asset: {
						id: mediaDetail.asset.id,
					},
					catalog: {},
					metadata: mediaDetail.metadata,
					tags: mediaDetail.tags,
					custom: mediaDetail.custom,
				};

				dispatch(saveInspectScreenDetails(defaultAccountId, mediaId, mediaNames.medias, body)).then(
					(data: any) => {
						setTimeout(() => {
							dispatch(setViewEngagementLoading(false));
							const saveSuccessMessage = checked
								? 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_ACTIVATED_SUCCESS'
								: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_INACTIVATED_SUCCESS';
							dispatch(
								showMessage(
									t(
										data
											? saveSuccessMessage
											: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_ACTIVATED_FAILED'
									),
									data ? (checked ? messageTypes.success : messageTypes.info) : messageTypes.error
								)
							);

							if (data) {
								setIsChatActive(checked);
								setTimeout(() => reloadData?.(), 1000);
							}
						}, 1000);
					}
				);
			});
		},
		[defaultAccountId, dispatch, mediaId, reloadData, t]
	);

	useEffect(() => {
		setIsChatActive(mediaDetails?.custom?.activatedChat !== false);
	}, [mediaDetails?.custom]);

	return (
		<>
			<ViewerEngagementTemplate.TabContentWrapper
				isChatTab
				useShortContainer={!!pinnedMessage || !!replyingMessage}
			>
				<FormControl fullWidth className={`chat-container__header`} style={{ flexBasis: 'auto !important' }}>
					<FormControlLabel
						label={
							<>
								<ViewerEngagementTemplate.Indicator isActive={isChatActive} />
								{t(
									isChatActive
										? 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_ACTIVE_LABEL'
										: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_INACTIVE_LABEL'
								)}
							</>
						}
						labelPlacement="start"
						onChange={onToggleChat}
						control={<Switch color="primary" checked={isChatActive} />}
					/>
				</FormControl>

				{messages
					.filter((m: ChatContainerTemplate.Message) => !m.blocked)
					.map((message: ChatContainerTemplate.Message) => {
						const repliedMessage = message.recipient?.replyMessageId
							? messages.find(
									(m: ChatContainerTemplate.Message) => m.id === message.recipient?.replyMessageId
							  )
							: undefined;

						return (
							<ChatContainerTemplate.ChatMessage
								key={message.id}
								message={message}
								repliedMessage={repliedMessage}
								onTogglePinnedMessage={onTogglePinnedMessage}
								onReply={onReplyMessageClick}
								onBlockMessage={onBlockMessageClick}
								onBlockUser={onBlockUserClick}
								blockedUserIds={blockedUserIds}
								pinedMessageId={pinnedMessage?.id}
							/>
						);
					})}
			</ViewerEngagementTemplate.TabContentWrapper>

			<ViewerEngagementTemplate.Input
				placeholder={
					!settings?.adminProfile?.chatName || isChatProfileEmpty(settings?.adminProfile)
						? t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SEND_MESSAGE')
						: `${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SEND_MESSAGE_AS')}${
								settings.adminProfile.chatName
						  }`
				}
				value={inputText}
				onChange={onInputChange}
				onSendClick={onSendMessage}
				onRefreshClick={onRefreshClick}
				onShowCreatePoll={onShowCreatePoll}
				onFocus={onInputFocus}
				disableSendBtn={
					!isChatActive || !settings?.adminProfile?.chatName || isChatProfileEmpty(settings?.adminProfile)
				}
				disabled={!isChatActive}
			>
				{replyingMessage && (
					<ChatContainerTemplate.ChatMessage
						message={replyingMessage}
						replyingMessage
						onTogglePinnedMessage={onTogglePinnedMessage}
					/>
				)}
				{pinnedMessage && (
					<ChatContainerTemplate.ChatMessage
						message={pinnedMessage}
						pinned
						onTogglePinnedMessage={onTogglePinnedMessage}
					/>
				)}
			</ViewerEngagementTemplate.Input>
		</>
	);
};

export default ChatContainer;
