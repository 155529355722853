export const NOCATALOG = 'nocatalog';
export const UPLOAD_FAILED = 'Upload Failed!';
export const UPLOADING_FILE = 'Uploading file';

export const mediaTypes = {
	image: 'image',
	video: 'video',
	audio: 'audio',
	livevideo: 'livevideo',
	liveaudio: 'liveaudio',
	catalog: 'catalog',
	playlists: 'playlists',
	scenario: 'scenario',
	unknown: 'unknown',
	trashedMedias: 'trashedMedias',
	uncatalogedMedias: 'uncatalogedMedias',
	portal: 'portal',
};

export const playlists = {
	image: 'playlists',
};

export const sortTypes = {
	relevance: 'relevance desc',
	newest: 'created desc',
	oldest: 'created asc',
	name_ascending: 'name asc',
	name_descending: 'name desc',
	highest_view: 'hits desc',
	lowest_view: 'hits asc',
	type: 'type cat first',
	//type: 'created desc'
};
// what use for ?
export const typeOptions = {
	allMedia: 'allMedia',
	video: 'video',
	live: 'live',
	audio: 'audio',
	catalog: 'catalog',
};

export const mediaNames = {
	medias: 'medias',
	catalogs: 'catalogs',
	playlists: 'playlists',
	liveMedias: 'liveMedias',
	scenarios: 'scenarios',
	unsortedMedia: 'unsortedMedia',
	editedMedias: 'editedMedias',
	trashedMedias: 'trashedMedias',
	portals: 'portals',
};

export const mediaNamesQuery = {
	medias: 'medias?',
	playlists: 'playlists?',
	scenarios: 'scenarios?',
	liveMedias: 'liveMedias?',
	rootCatalogs: 'catalogs?q=!parentId:*',
	live: '&q=asset.resources.type:livevideo',
	video: '&q=!asset.resources.type:livevideo',
	audio: '&q=asset.resources.type:audio',
	catalog: '&q=asset.resources.type:catalogs',
	image: 'asset.resources.type:* AND !asset.resources.type: livevideo AND !asset.resources.type: video AND !asset.resources.type: audio',
	allMediasWithoutImage:
		'asset.resources.type: livevideo OR asset.resources.type: video OR asset.resources.type: audio OR (!asset.resources.type:*)',
	uncatalogedMedias: '!catalog.id:*',
	editedMedias: 'id:aeedit*',
	noEditedMedias: '!id:aeedit*',
	portals: 'portals?',
};

export const mediaFiltersQueries = {
	[mediaTypes.video]: {
		displayName: 'Video',
		query: 'asset.resources.type: video OR asset.resources.type: audio AND !asset.resources.type: livevideo',
	},
	[mediaTypes.livevideo]: {
		displayName: 'Live event',
		query: 'asset.resources.type: livevideo',
	},
	[mediaTypes.uncatalogedMedias]: {
		displayName: 'No catalog',
		query: '!catalog.id:*',
	},
};

export const playlistsNamesQuery = {
	medias: 'playlists?',
};

export const mediaViews = {
	list: 'list',
	grid: 'grid',
	calendar: 'calendar',
};

export const accessRestrictionRulesType = {
	DATE: 'Date',
	IP: 'Ip',
	TOKEN: 'Token',
};

export const messageTypes = {
	success: 'success',
	error: 'error',
	info: 'info',
	warning: 'warning',
};

export const renditionTypes = {
	IMAGE: 'image',
	VIDEO: 'video',
	AUDIO: 'audio',
	INDEX: 'index',
	SUBTITLE: 'subtitle',
};

export const renditionGroupTypes = {
	IMAGES: 'images',
	VIDEOS: 'videos',
	AUDIOS: 'audios',
	INDEXES: 'indexes',
	SUBTITLES: 'subtitles',
};

export const tableSortTypes = {
	ASC: 'asc',
	DESC: 'desc',
};

export const customMetadataTypes = {
	MULTISELECT: 'multiselect',
	BOOLEAN: 'boolean',
	STRING: 'string',
	NUMBER: 'number',
	DATETIME: 'timestamp',
	DATE: 'date',
};

export const customMetadataPropertiesObject = {
	CATALOG: 'catalog',
	PLAYLIST: 'playlist',
	MEDIA: 'media',
};

export const customScreenSizes = {
	mobile: 480,
	small: 640,
	tablet: 840,
	medium: 1440,
};

export const customScreenType = {
	mobile: 'mobile',
	small: 'small',
	tablet: 'tablet',
	medium: 'medium',
	large: 'large',
};

export const paginationDataLimitPerScreens = {
	default_screens: 50,
	large_screens: 10,
	medium_screens: 5,
	small_screens: 4,
	mobile_screens: 6,
};

export const sortingOptionsDropDownItems = [
	{ key: sortTypes.type, value: 'Type', translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_TYPE' },
	{
		key: sortTypes.newest,
		value: 'Date (Newest first)',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_NEWEST',
	},
	{
		key: sortTypes.oldest,
		value: 'Date (Oldest first)',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_OLDEST',
	},
	{
		key: sortTypes.name_ascending,
		value: 'Title (A-Z)',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_NAME_ASCENDING',
	},
	{
		key: sortTypes.name_descending,
		value: 'Title (Z-A)',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_NAME_DESCENDING',
	},
	/*{ key: sortTypes.highest_view, value: "Sort by view(high first)" },
      { key: sortTypes.lo, value: "Sort by view(low first)" } */
];

export const sortingOptionsDropDownSearchItems = [
	{
		key: sortTypes.relevance,
		value: 'Relevance',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_RELEVANCE',
	},
	{ key: sortTypes.type, value: 'Type', translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_TYPE' },
	{
		key: sortTypes.newest,
		value: 'Date (Newest first)',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_NEWEST',
	},
	{
		key: sortTypes.oldest,
		value: 'Date (Oldest first)',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_OLDEST',
	},
	{
		key: sortTypes.name_ascending,
		value: 'Title (A-Z)',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_NAME_ASCENDING',
	},
	{
		key: sortTypes.name_descending,
		value: 'Title (Z-A)',
		translateKey: 'MEDIA_LIBRARY_SEARCH_AREA_SORTING_OPTION_NAME_DESCENDING',
	},
];
// Use nowhere - need to check
export const sortingOptionsDropDownItemsForCatalogs = [
	{ key: sortTypes.newest, value: 'Sort by Date (Newest first)' },
	{ key: sortTypes.oldest, value: 'Sort by Date (Oldest first)' },
	{ key: sortTypes.name_ascending, value: 'Sort by Title (A-Z)' },
	{ key: sortTypes.name_descending, value: 'Sort by Title (Z-A)' },
];

// Use nowhere - need to check
export const typeOptionsDropDownItems = [
	{ key: typeOptions.allMedia, value: 'Show All Media' },
	{ key: typeOptions.catalog, value: 'Show only Catalogs' },
	{ key: typeOptions.video, value: 'Show only Videos' },
	{ key: typeOptions.live, value: 'Show only Live events' },
	{ key: typeOptions.audio, value: 'Show only Audio' },
];
// Use nowhere - need to check
export const sortingOptionsDropDownItemsForSearchScreen = [
	{ key: sortTypes.relevance, value: 'Relevance' },
	{ key: sortTypes.newest, value: 'Date (Newest first)' },
	{ key: sortTypes.oldest, value: 'Date (Oldest first)' },
	{ key: sortTypes.name_ascending, value: 'Title (A-Z)' },
	{ key: sortTypes.name_descending, value: 'Title (Z-A)' },
	{ key: sortTypes.highest_view, value: 'View (high first)' },
	{ key: sortTypes.lowest_view, value: 'View (low first)' },
];

export const mediaFilterKeys = {
	medias: 'medias',
	playlists: 'playlist',
};

export const mediaPerPageOptions = [50, 100, 200];

export const mediaPerPageOptionsForSmallScreens = [6, 10, 15];

export const listingMediaTypesSearchQuery = [
	{ key: mediaTypes.video, value: 'Video' },
	{ key: mediaTypes.audio, value: 'Audio' },
	{ key: mediaTypes.livevideo, value: 'Live Video' },
];

export const listingMediaTypesQuery = [
	{ key: mediaTypes.catalog, value: 'Catalogs' },
	{ key: mediaTypes.video, value: 'Video' },
	{ key: mediaTypes.livevideo, value: 'Live events' },
	{ key: mediaTypes.audio, value: 'Audio' },
];

export const listingMediaTypesInitialSeletion = [
	mediaTypes.video,
	mediaTypes.audio,
	mediaTypes.livevideo,
	mediaTypes.catalog,
];

export const imageRelTagList = {
	THUMBNAIL: 'thumbnail',
	PRECOVER: 'precover',
	POSTCOVER: 'postcover',
};

export const mediaPropertyNames = {
	TITLE: 'title',
};

export const qbrickPlayerList = {
	shoppingPlayer: 'shoppingplayer',
	qbrickPlayer: 'qbrick-player',
	shoppingCompanion: 'shoppingcompanion',
};

export const miniLibraryViewsId = {
	uploadView: 'uploadView',
	libraryView: 'libraryView',
};

export const mediaLibraryKeys = {
	allMedia: 'allMedia',
	playlists: 'playlists',
	edit: 'edit',
	scenarios: 'scenarios',
	catalogs: 'catalogs',
	smartFilter: 'smartFilter',
	library: 'library',
	unsortedMedia: 'unsortedMedia',
	recycleBin: 'recycleBin',
	live: 'live',
	portal: 'portal',
};

export const INVALID_PROFILES = [
	'MoveToQvpHorizontal',
	'MoveToQvpSquare',
	'MoveToQvpPortrait',
	'MoveToQvpVertical',
	'smil',
	'Live',
	'subtitle',
	'image',
	'Snapshot',
	'multiAudio',
];

export const PART_OF_INVALID_PROFILES = ['-smil', 'image', 'subtitle', 'move', 'live', 'snapshot', 'mutliaudio', 'qvp'];

export const mediaActionKeys = {
	addToPlaylist: 'add-to-playlist',
	removeFromPlaylist: 'remove-from-playlist',
	createPlaylist: 'create-playlist',
	addToCatalog: 'add-to-catalog',
	inspectCatalog: 'inspect-catalog',
	//trigger edit access modal for catalog
	editAccessCatalog: 'edit-access-catalog',
	deleteMedia: 'delete-media',
	uploadMedia: 'upload-media',
	createInteractProject: 'create-interact-project',
	createLiveEvent: 'create-live-event',
	createCatalog: 'create-catalog',
	duplicateMedia: 'duplicate-media',
	openMediaInNewWindow: 'open-media-in-new-window',
	editVideos: 'edit-videos',
	moveToBin: 'move-to-bin',
	restore: 'restore',
	emptyBin: 'empty-bin',
	// import new media to edit with in ae editor
	editWithAE: 'edit-with-ae',
	trimVideoWithAE: 'trim-video-with-ae',
	resizeVideoWithAE: 'resize-video-with-ae',
	resizeImageWithAE: 'resize-image-with-ae',
	// open media that created by ae editor (media id started with aeedit-...) & save as new media content after finish
	openAEProject: 'open-ae-project',
	// open media that created by ae editor (media id started with aeedit-...) & replace media's content after finish
	openAndReplaceAEProject: 'open-and-replace-ae-project',
	screenRecorder: 'screen-recorder',
	createInteractWithAI: 'create-interact-with-ai',
};

export const THEO_PLAYER_PRESET_ID = 'theo';
export const DEFAULT_THEO_PLAYER_EMBED =
	'https://httpcache0-90534-cachedown0.dna.ip-only.net/90534-cachedown0/theo/embed.js';

export const BONNIER_NEW_PRESET_ID = 'bn-video-player';

export const FLOWS = {
	qbrickStandard: 'qbrick-standard',
};
