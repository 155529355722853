import React, { useEffect, useState } from 'react';

import chevron_down from '../../../../../assets/images/chevron-down.svg';
import right_arrow from '../../../../../assets/images/right_arrow.svg';
import refresh from '../../../../../assets/images/arrow-counterclockwise.svg';

import { joinClassNames } from '../../../../../services/elementHelperService';
import './PortalThemes.scss';
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	MenuItem,
	OutlinedInput,
	Select,
	Typography,
} from '@material-ui/core';
import { playerKeys, portalThemeSettingType, stylingKeys } from '../../../../../constants/portalThemesConstant';
import ColorPicker from '../../../../../reusable/ColorPicker/ColorPicker';
import { useTranslation } from 'react-i18next';

const PortalTheme = () => {
	const { t } = useTranslation();

	const [expandedSection, setExpandSection] = useState<string[]>(['defaultThemes', 'advancedSettings']);
	const [defaultThemes, setDefaultThemes] = useState<any[]>([]);
	const [savedThemes, setSavedThemes] = useState<any[]>([]);

	const updateExpandSection = (sectionId: string) => {
		if (expandedSection.includes(sectionId)) {
			setExpandSection((prevState: any) => prevState.filter((s: string) => s !== sectionId));
		} else {
			setExpandSection((prevState: any) => [...prevState, sectionId]);
		}
	};

	useEffect(() => {
		setDefaultThemes([{ id: 'Theme #1' }, { id: 'Theme #2' }]);
		setSavedThemes([{ id: 'Saved theme #1' }]);
	}, []);

	const isDefaultThemesExpands = expandedSection.includes('defaultThemes');

	return (
		<div className="portalThemesWrapper">
			<div className="configurationThemes">
				<div className="configurationThemes__header">Portals themes</div>

				<div
					className="configurationThemes__sub-header"
					onClick={() => {
						updateExpandSection('defaultThemes');
					}}
				>
					<span>Choose a theme</span>
					<img
						className={joinClassNames([
							'playerConfigurations-arrow',
							isDefaultThemesExpands ? 'playerConfigurations-arrow--expanded' : '',
						])}
						src={isDefaultThemesExpands ? chevron_down : right_arrow}
					/>
				</div>

				{isDefaultThemesExpands && (
					<div className="configurationThemes__default-themes">
						{defaultThemes.map(({ id }) => (
							<div key={id} className="theme-item">
								<Typography variant="h6" color="textSecondary" style={{ color: '#ffffff' }}>
									{id}
								</Typography>
								<span>Create preset from this theme</span>
							</div>
						))}
					</div>
				)}

				<div className="configurationThemes__saved-themes">
					<label>Custom themes</label>
					<FormControl fullWidth className="saved-theme-select__control">
						<Select id="saved-theme-selector" value={0} onChange={() => {}} disableUnderline>
							<MenuItem disabled value={0}>
								Choose a preset
							</MenuItem>
							{savedThemes.map(({ id }) => {
								return (
									<MenuItem key={id} value={id}>
										{id}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>

				<div className="configurationTheme-editing-title sub-header">
					<span>Editing: Theme #1</span>
				</div>

				<div className="configurationTheme-editing-name borderBottom">
					<span>Name of preset</span>
					<div className="configurationTheme__input">
						<input value={'Theme #1'} onChange={() => {}} />
					</div>
				</div>

				<div className="configurationThemes-notice">See change in preview window (right)</div>

				<div className="configurationTheme-cards">
					{Object.keys(portalThemeSettingType)
						.sort(
							(keyA: string, keyB: string) =>
								(portalThemeSettingType as any)[keyA] - (portalThemeSettingType as any)[keyB]
						)
						.map((settingKey: any) => {
							return (
								<div key={settingKey} className="configurationTheme-card">
									<div className="configurationTheme-card__header" onClick={() => {}}>
										{settingKey}
										<div className={'playerConfigurations-card__header-actions'}>
											<button
												className="playerConfigurations-card__refresh-btn"
												onClick={() => {}}
											>
												<img src={refresh} /> {t('SETTINGS_PRESET_CONFIG_RESET_TO_DEFAULT')}
											</button>
											<img
												className={joinClassNames([
													'playerConfigurations-arrow',
													'playerConfigurations-arrow--expanded',
												])}
												src={chevron_down}
											/>
										</div>
									</div>
									<div className="configurationTheme-card__content">
										{settingKey === portalThemeSettingType.Styling && (
											<FormGroup>
												{stylingKeys.map((key) => {
													if (key === 'Background color' || key === 'Grid bg color') {
														return (
															<div key={key} className="configurationTheme__control">
																<label
																	id={key + '-select-label'}
																	htmlFor={key + '-select'}
																>
																	{key}
																</label>
																<div className="playerConfigurations-card__color-picker">
																	<span>Color</span>
																	<ColorPicker
																		width="20px"
																		height="20px"
																		borderRadius="4px"
																		padding="2px"
																		color={'#ffffff'}
																		disableAlpha
																		onChangeComplete={() => {}}
																	/>
																	<span>
																		<span>Transparent</span>
																	</span>
																	<input
																		type={'number'}
																		min="1"
																		max="100"
																		className="playerConfigurations-card__opacity"
																		value={100}
																		onChange={() => {}}
																	/>
																	<span style={{ color: 'black' }}>%</span>
																</div>
															</div>
														);
													}

													return (
														<div key={key} className="configurationTheme__control">
															<label id={key + '-select-label'} htmlFor={key + '-select'}>
																{key}
															</label>
															<FormControlLabel
																labelPlacement="start"
																control={
																	<OutlinedInput
																		className="configurationTheme__input"
																		onChange={(_event: any) => {}}
																	/>
																}
																label={null}
															/>
														</div>
													);
												})}
											</FormGroup>
										)}

										{settingKey === portalThemeSettingType.Playback && (
											<FormGroup>
												{playerKeys.map((key) => (
													<div key={key} className="configurationTheme__control">
														<label id={key + '-select-label'} htmlFor={key + '-select'}>
															{key}
														</label>
														<FormControlLabel
															labelPlacement="start"
															control={
																<OutlinedInput
																	className="configurationTheme__input"
																	value={''}
																	onChange={(_event: any) => {}}
																/>
															}
															label={null}
														/>
													</div>
												))}
											</FormGroup>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
			<div className="previewThemes">
				<iframe
					width={'100%'}
					height={'100%'}
					src="https://portals.qbrick.com/?portalId=789a3fa7-5ae8-4e34-a31d-087b0b45862a&playlistId=f321035d-99f7-4682-bcd8-2230256769fc&accountId=123516"
				/>
			</div>
		</div>
	);
};

export default PortalTheme;
