import React from 'react';
import {
	ChatContainer,
	MainContainer,
	MessageInput,
	MessageList,
	Message,
	Avatar,
	TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useQbrickAI } from './useQbrickAI';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import BlubbyAvatar from '../../../assets/images/blubby.png';
import VideocamIcon from '@material-ui/icons/Videocam';

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

type ManuscriptSceneEditorProps = {
	index: number;
	title: string;
	footage: string;
	voiceOver: string;
	handleVideoDataChange: (_index: number, _propertyKey: string, _value: string) => void;
};

const ManuscriptSceneEditor = ({
	index,
	title,
	footage,
	voiceOver,
	handleVideoDataChange,
}: ManuscriptSceneEditorProps) => {
	const handleContentChange = (value: string, propertyKey: string) => {
		handleVideoDataChange(index, propertyKey, value);
	};
	return (
		<Box>
			<Box>
				<Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
					<TextField
						style={{ fontWeight: 'bold', flex: 1, paddingBottom: 10 }}
						value={title}
						minRows={1}
						maxRows={1}
						variant="outlined"
						onChange={(e) => {
							handleContentChange(e.target.value, 'name');
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<MovieFilterIcon htmlColor="#adadad" />
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<TextField
					value={footage}
					minRows={5}
					maxRows={10}
					multiline
					fullWidth
					variant="outlined"
					onChange={(e) => {
						handleContentChange(e.target.value, 'footage');
					}}
					style={{ marginBottom: '10px' }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<VideocamIcon htmlColor="#adadad" />
							</InputAdornment>
						),
					}}
				/>
			</Box>
			<Box>
				<TextField
					value={voiceOver}
					minRows={5}
					maxRows={10}
					multiline
					fullWidth
					variant="outlined"
					onChange={(e) => {
						handleContentChange(e.target.value, 'voice_over');
					}}
					style={{ marginBottom: '10px' }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<RecordVoiceOverIcon htmlColor="#adadad" />
							</InputAdornment>
						),
					}}
				/>
			</Box>
		</Box>
	);
};

type InteractAiCreationDialogProps = {
	open: boolean;
	onClose: () => void;
};

const InteractAiCreationDialog = ({ open, onClose }: InteractAiCreationDialogProps) => {
	const { t } = useTranslation();
	const {
		conversationData,
		videoData,
		isSubmitting,
		handleUserSendMessage,
		handleCreateInteract,
		handleVideoDataChange,
		resetData,
	} = useQbrickAI(onClose);

	const isSubmittingAI = isSubmitting === 'qbrickAI';
	const isCreatingInteract = isSubmitting === 'createInteract';

	const handleCloseDialog = () => {
		resetData();
		onClose();
	};

	return (
		<Dialog className="create-scenario-dialog" open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t('Create interact with AI')}</Typography>
				<IconButton onClick={handleCloseDialog}>
					<Close />
				</IconButton>
			</DialogTitle>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					alignItems: 'stretch',
					minHeight: '300px',
					height: '600px',
					padding: '10px 20px',
				}}
			>
				<Box
					style={{
						flex: 1,
						height: '100%',
						overflowY: 'scroll',
					}}
				>
					{videoData && (
						<Box>
							<Typography variant="h6">Video name: {videoData.video_name}</Typography>
							<Typography variant="subtitle1">Number of scene: {videoData.scenes.length}</Typography>
							<Typography variant="subtitle1" style={{ marginBottom: '10px' }}>
								Overview of scenes:{' '}
							</Typography>
							{videoData.scenes.map(({ footage, name, voice_over }: any, idx: number) => (
								<>
									<ManuscriptSceneEditor
										key={idx}
										title={name}
										footage={footage}
										voiceOver={voice_over}
										index={idx}
										handleVideoDataChange={handleVideoDataChange}
									/>
									{idx !== videoData.scenes - 1 && <Divider style={{ marginBottom: '10px' }} />}
								</>
							))}
						</Box>
					)}
				</Box>
				<Box
					sx={{
						flex: 1,
					}}
				>
					<MainContainer>
						<ChatContainer>
							<MessageList
								typingIndicator={isSubmittingAI && <TypingIndicator content="Blubby is typing" />}
							>
								{conversationData.map((message, index) => {
									if (message.role === 'system') {
										return null;
									}

									const isUserMessage = message.role === 'user';
									const text = message.role === 'user' ? message.content : message.assistantMessage;
									return (
										<Message
											key={index}
											model={{
												message: text,
												sentTime: 'just now',
												sender: message.role,
												direction: isUserMessage ? 'outgoing' : 'incoming',
												position: 'single',
											}}
										>
											{!isUserMessage && (
												<Avatar src={BlubbyAvatar} name="Blubby" status="available" />
											)}
										</Message>
									);
								})}
							</MessageList>
							<MessageInput
								placeholder="Ask Blubby anythings"
								attachButton={false}
								onSend={(_, textContent: string) => {
									handleUserSendMessage(textContent);
								}}
								disabled={Boolean(isSubmitting)}
							/>
						</ChatContainer>
					</MainContainer>
				</Box>
			</Box>

			<DialogActions>
				<Button
					variant="contained"
					onClick={handleCreateInteract}
					color="primary"
					disabled={!conversationData || isCreatingInteract}
					endIcon={isCreatingInteract && <CircularProgress size={10} style={{ color: '#000000' }} />}
				>
					{t('Create interact video')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InteractAiCreationDialog;
