import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as ReactRouter from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import {
	changeRouteAfterInspectScreen,
	getAllCurrentJobs,
	getAllFinishedJobs,
	getEncoderJobs,
	loadUploadingFlows,
	refreshMedias,
	setRefreshMediasIds,
	setCCEverywhere,
	setQbrickStandardFlowsInReducer,
	setPermissionInformationDialog,
	preventUserFromCloseWindow,
} from '../../actions/publishActions';
import { setShowUploadMediasDialog } from '../../actions/uploadingActions';
import { joinClassNames } from '../../services/elementHelperService';
import { FLOWS, customScreenType } from '../../constants/mediaConstants';
import { loadApps, setSelectedApp } from '../../actions/appsActions';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../constants/routesPath';
import { closeDrawer, updateSelectedKey, updateSubSelectedKey } from '../../actions/navActions';
import { PUBLISH_SUBKEY } from '../nav/navigationItems';
import { ReactComponent as HomeLogo } from '../../assets/images/qbricksvg.svg';
import { HeaderContext } from '../../context/HeaderContext';
import { AdobeExpressUtils } from '../../screens/PublishScreen/AdobeExpress/utils';
import { DashboardUtils } from '../../screens/DashboardScreen/DashboardUtils';
import { checkPermissionGrantedFor } from '../../services/componentReusableService';
import useUploadingVideoByUrl from '../../hooks/screens/library/useUploadingVideoByUrl';
import { defaultPaginationRoutePath } from '../../constants/paginationConstant';
import { getAccountSettings } from '../../actions/profileActions';
import { buildRoutePath } from '../../utils/commonUtil';

import UploadMediaDialog from './UploadMediaDialog';
import UploadStatusDialog from './UploadStatusDialog';
import UserInformation from './UserInformation';
import { getMainMenuItem } from './utils';
import { KEYS } from './constants';
import HeaderMainMenu from './HeaderMainMenu';

import './HeaderStyle.scss';

const { PermissionInformationDialog, RecentMediaTypes } = DashboardUtils;

type MainHeaderProps = ReactRouter.RouteComponentProps & {};

const MainHeader: React.FC<MainHeaderProps> = ({ history }) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const { defaultAccountId, userPermissions } = useSelector((state) => (state as any).session);
	const {
		screenType,
		uploadPercentage,
		isInspectScreenModified,
		ccEverywhere,
		qbrickStandardFlows,
		permissionInformationDialog,
	} = useSelector((state) => (state as any).publish);
	const { apps } = useSelector((state) => (state as any).apps);

	const onGoingEncodingIntervalId = useRef<any | undefined>(undefined);
	const [openUploadStatusDialog, setOpenUploadStatusDialog] = useState<boolean>(false);
	const [uploadProgressStatus, setUploadProgressStatus] = useState('');
	const [uploadingFiles, setUploadingFiles] = useState<any[]>([]);
	const [encodingFiles, setEncodingFiles] = useState<any[]>([]);
	const [finishedJobs, setFinishedJobs] = useState<any[]>([]);
	const [encodingGCFiles, setEncodingGCFiles] = useState<any[]>([]);
	const [finishedGCJobs, setFinishedGCJobs] = useState<any[]>([]);
	const [selectedMenuItem, setSelectedMenuItem] = useState<any>(KEYS.dashboard);
	const [shouldShowHeader, setShouldShowHeader] = useState<boolean>(true);
	const [defaultEmails, setDefaultEmails] = useState<any[]>([]);

	const mainMenuItems = useMemo(() => getMainMenuItem(apps, t, userPermissions), [apps, t, userPermissions]);

	const contextValue = useMemo(
		() => ({
			uploadProgressStatus,
			setUploadProgressStatus,
			uploadingFiles,
			setUploadingFiles,
		}),
		[uploadProgressStatus, uploadingFiles]
	);

	const permissions = useMemo(() => {
		return {
			[RecentMediaTypes.interact]: checkPermissionGrantedFor(userPermissions, 'scenarios'),
			[RecentMediaTypes.edits]: checkPermissionGrantedFor(userPermissions, 'adobeExpress'),
			[RecentMediaTypes.record]: checkPermissionGrantedFor(userPermissions, 'adobeExpress'),
			[RecentMediaTypes.live]:
				checkPermissionGrantedFor(userPermissions, 'channels') ||
				checkPermissionGrantedFor(userPermissions, 'legacyLive'),
			[RecentMediaTypes.video]: true,
			[RecentMediaTypes.speechToText]: checkPermissionGrantedFor(userPermissions, 'speech2Text'),
		};
	}, [userPermissions]);

	const onMainMenuClick = useCallback(
		(menuItem: any) => {
			const parentKey = menuItem.key;
			const param = menuItem.param;
			const currentLocation = window.location.hash === '' ? window.location.pathname : window.location.hash;

			switch (parentKey) {
				case KEYS.analytics:
					if (isInspectScreenModified) {
						dispatch(changeRouteAfterInspectScreen(screenRoutes.ANALYTICS));
					} else {
						history.push(
							buildRoutePath(screenRoutes.ANALYTICS, ACCOUNT_ID_ROUTE_PLACEHOLDER, defaultAccountId)
						);
					}
					break;
				case KEYS.publish:
					if (currentLocation === screenRoutes.PUBLISH_ALL_MEDIA) {
						break;
					}

					if (isInspectScreenModified) {
						dispatch(changeRouteAfterInspectScreen(screenRoutes.PUBLISH_ALL_MEDIA));
					} else {
						history.push(
							buildRoutePath(
								`${screenRoutes.PUBLISH_ALL_MEDIA}${defaultPaginationRoutePath}`,
								ACCOUNT_ID_ROUTE_PLACEHOLDER,
								defaultAccountId
							)
						);
					}
					break;
				case KEYS.live:
					if (currentLocation === screenRoutes.PUBLISH_LIVE_MEDIA) {
						break;
					}

					if (isInspectScreenModified) {
						dispatch(changeRouteAfterInspectScreen(screenRoutes.PUBLISH_LIVE_MEDIA));
					} else {
						history.push(
							buildRoutePath(
								`${screenRoutes.PUBLISH_LIVE_MEDIA}${defaultPaginationRoutePath}`,
								ACCOUNT_ID_ROUTE_PLACEHOLDER,
								defaultAccountId
							)
						);
					}
					break;
				case KEYS.interact:
					if (currentLocation === screenRoutes.PUBLISH_SCENARIOS) {
						break;
					}

					if (isInspectScreenModified) {
						dispatch(changeRouteAfterInspectScreen(screenRoutes.PUBLISH_SCENARIOS));
					} else {
						history.push(
							buildRoutePath(
								`${screenRoutes.PUBLISH_SCENARIOS}${defaultPaginationRoutePath}`,
								ACCOUNT_ID_ROUTE_PLACEHOLDER,
								defaultAccountId
							)
						);
					}
					break;
				case KEYS.dashboard:
					if (isInspectScreenModified) {
						dispatch(changeRouteAfterInspectScreen(screenRoutes.DASHBOARD));
					} else {
						history.push(
							buildRoutePath(screenRoutes.DASHBOARD, ACCOUNT_ID_ROUTE_PLACEHOLDER, defaultAccountId)
						);
					}
					break;
				case KEYS.editorApp:
					if (isInspectScreenModified) {
						const keyConf = param.configId.toLowerCase() === 'editor' ? 'projects' : param.configId;
						dispatch(changeRouteAfterInspectScreen(screenRoutes.APPS_EDITOR + '/' + keyConf));
					} else {
						const key = param?.configId.toLowerCase() === 'editor' ? 'projects' : param.configId;

						history.push(
							buildRoutePath(
								screenRoutes.APPS + '/' + param?.appId + '/' + key,
								ACCOUNT_ID_ROUTE_PLACEHOLDER,
								defaultAccountId
							),
							param
						);
					}
					dispatch(updateSelectedKey(KEYS.apps + '_' + param?.appId));
					dispatch(setSelectedApp(param));
					break;
				case KEYS.edit:
					if (currentLocation === screenRoutes.PUBLISH_EDITED_MEDIA) {
						break;
					}
					if (isInspectScreenModified) {
						dispatch(changeRouteAfterInspectScreen(screenRoutes.PUBLISH_EDITED_MEDIA));
					} else {
						history.push(
							buildRoutePath(
								`${screenRoutes.PUBLISH_EDITED_MEDIA}${defaultPaginationRoutePath}`,
								ACCOUNT_ID_ROUTE_PLACEHOLDER,
								defaultAccountId
							)
						);
					}
					break;
				case KEYS.portal:
					history.push(
						buildRoutePath(
							`${screenRoutes.PUBLISH_PORTALS}${defaultPaginationRoutePath}`,
							ACCOUNT_ID_ROUTE_PLACEHOLDER,
							defaultAccountId
						)
					);
					break;
				case KEYS.apps + '_' + param?.appId:
					if (isInspectScreenModified) {
						dispatch(
							changeRouteAfterInspectScreen(
								screenRoutes.APPS + '/' + param?.appId + '/' + param?.configId
							)
						);
					} else {
						history.push(
							buildRoutePath(
								screenRoutes.APPS + '/' + param?.appId + '/' + param?.configId,
								ACCOUNT_ID_ROUTE_PLACEHOLDER,
								defaultAccountId
							),
							param
						);
					}
					dispatch(updateSelectedKey(KEYS.apps + '_' + param?.appId));
					dispatch(setSelectedApp(param));
					break;
				default:
					break;
			}
			setSelectedMenuItem(parentKey);
		},
		[dispatch, history, isInspectScreenModified, defaultAccountId]
	);

	const updateNavKeysBasedOnLocation = useCallback(
		(location: any) => {
			let currentLocation = location.hash === '' ? location.pathname : location.hash;
			currentLocation = currentLocation
				.replace(localStorage.getItem('defaultAccountId'), ACCOUNT_ID_ROUTE_PLACEHOLDER)
				.replace(/\/page.*/, '');
			const startingPoint = localStorage.getItem('startingPoint');

			if (currentLocation) {
				if (currentLocation.match(screenRoutes.DASHBOARD)) {
					dispatch(updateSelectedKey(KEYS.dashboard));
					setSelectedMenuItem(KEYS.dashboard);
					dispatch(closeDrawer());
				} else if (currentLocation.match(screenRoutes.ANALYTICS)) {
					dispatch(updateSelectedKey(KEYS.analytics));
					setSelectedMenuItem(KEYS.analytics);
					dispatch(closeDrawer());
				} else if (
					(currentLocation.match(screenRoutes.PUBLISH_ALL_MEDIA) ||
						currentLocation.match(/\/medias\/.*\/detail/i)) &&
					startingPoint !== 'EDIT'
				) {
					//case all media or media detail
					setSelectedMenuItem(KEYS.publish);
					dispatch(updateSelectedKey(KEYS.publish));
					dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.allMedia));
				} else if (currentLocation.match(screenRoutes.PUBLISH_SCENARIOS)) {
					setSelectedMenuItem(KEYS.interact);
					dispatch(updateSelectedKey(KEYS.interact));
					dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.interact));
				} else if (currentLocation.match(screenRoutes.PUBLISH_PLAYLISTS)) {
					setSelectedMenuItem(KEYS.publish);
					dispatch(updateSelectedKey(KEYS.publish));
					dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.playlists));
				} else if (currentLocation.match(screenRoutes.PUBLISH_CATALOGS)) {
					setSelectedMenuItem(KEYS.publish);
					dispatch(updateSelectedKey(KEYS.publish));
					dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.catalogs));
				} else if (currentLocation.match(screenRoutes.PUBLISH_SMART_FILTER)) {
					setSelectedMenuItem(KEYS.publish);
					dispatch(updateSelectedKey(KEYS.publish));
					dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.smartFilters));
				} else if (currentLocation.match(screenRoutes.CREATE)) {
					setSelectedMenuItem(KEYS.create);
					dispatch(updateSelectedKey(KEYS.create));
				} else if (currentLocation.match(screenRoutes.PUBLISH_LIVE_MEDIA)) {
					setSelectedMenuItem(KEYS.live);
					dispatch(updateSelectedKey(KEYS.live));
				} else if (currentLocation.match(screenRoutes.APPS_EDITOR)) {
					const paths = currentLocation.split('/');
					const index = paths.indexOf('apps') + 1;
					setSelectedMenuItem(KEYS.apps + '_' + paths[index]);
					dispatch(updateSelectedKey(KEYS.editorApp));
					if (currentLocation.indexOf('exports') > 0) {
						dispatch(updateSubSelectedKey(KEYS.editorApp, 'exports'));
					} else if (currentLocation.indexOf('projects') > 0) {
						dispatch(updateSubSelectedKey(KEYS.editorApp, 'projects'));
					} else {
						dispatch(updateSubSelectedKey(KEYS.editorApp, 'editor'));
					}
				} else if (currentLocation.match(screenRoutes.APPS)) {
					const paths = currentLocation.split('/');
					const index = paths.indexOf('apps') + 1;
					setSelectedMenuItem(KEYS.apps + '_' + paths[index]);
					dispatch(updateSelectedKey(KEYS.apps));
				} else if (currentLocation.match(screenRoutes.PUBLISH_EDITED_MEDIA) || startingPoint === 'EDIT') {
					setSelectedMenuItem(KEYS.edit);
					dispatch(updateSelectedKey(KEYS.edit));
					dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.edit));
				} else if (currentLocation.match(screenRoutes.PUBLISH_PORTALS)) {
					setSelectedMenuItem(KEYS.portal);
					dispatch(updateSelectedKey(KEYS.portal));
					dispatch(updateSubSelectedKey(KEYS.portal, KEYS.portal));
				}
			}
		},
		[dispatch]
	);

	const checkForOngoingGCEncoding = useCallback(() => {
		dispatch(getAllCurrentJobs(defaultAccountId)).then((res: any) => {
			const newEncodingGCFile = _.sortBy(res ? res : [], 'jobid');

			setEncodingGCFiles((prevEncodingGCFiles) => {
				const prevEncodingIDs = _.sortBy(prevEncodingGCFiles, 'jobid')
					.map((e) => e.jobid)
					.join(',');
				const currentEncodingIDs = _.sortBy(newEncodingGCFile, 'jobid')
					.map((e) => e.jobid)
					.join(',');
				if (prevEncodingIDs !== currentEncodingIDs) {
					dispatch(refreshMedias());
				}
				return newEncodingGCFile;
			});

			dispatch(getAllFinishedJobs(defaultAccountId)).then((finishedJobs: any) => {
				const newFinishedGCJobs = _.sortBy(finishedJobs ? finishedJobs : [], 'jobid');
				setFinishedGCJobs(newFinishedGCJobs);
			});
		});
	}, [defaultAccountId, dispatch]);

	const checkForOngoingEncoding = useCallback(() => {
		dispatch(getEncoderJobs(defaultAccountId)).then((res: any) => {
			const allJobs = res?.jobs ?? [];
			const newFinishedJobs: any[] = [];
			const newEncodingFiles: any[] = [];
			for (let i = 0; i < allJobs.length; i++) {
				const job = allJobs[i];
				if (job?.status === 'SUCCESS') {
					newFinishedJobs.push(job);
				} else if ((job.steps ?? []).find((s: any) => s.type === 'encode' && s.status === 'SUCCESS')) {
					newFinishedJobs.push(job);
				} else {
					newEncodingFiles.push(job);
				}
			}

			setFinishedJobs(newFinishedJobs);
			setEncodingFiles((prevEncodingFiles) => {
				const prevEncodingMedias = _.sortBy(prevEncodingFiles, 'media_id')
					.map((e) => e.media_id)
					.join(',');
				const currentEncodingMedias = _.sortBy(newEncodingFiles, 'media_id')
					.map((e) => e.media_id)
					.join(',');
				const encodingFilesDone = prevEncodingFiles.filter(
					(e) => !!newFinishedJobs.find((f) => f.media_id === e.media_id)
				);

				if (prevEncodingMedias !== currentEncodingMedias || encodingFilesDone.length > 0) {
					dispatch(setRefreshMediasIds(newFinishedJobs.map((d) => d.media_id)));
					dispatch(refreshMedias());
				}
				return newEncodingFiles;
			});
		});
	}, [defaultAccountId, dispatch]);

	const onClearCompletedFiles = useCallback(() => {
		setUploadingFiles((prevUploadedFile) =>
			prevUploadedFile.filter(
				(u) =>
					!finishedJobs.find((f) => f.media_id === u.media_id) &&
					!finishedGCJobs.find((f) => f.filename === u.filename)
			)
		);
	}, [finishedGCJobs, finishedJobs]);

	const handleUploadButtonClick = useCallback(() => {
		const isEncoding =
			(encodingFiles ?? []).length > 0 ||
			(encodingGCFiles ?? []).length > 0 ||
			(uploadingFiles.filter((u) => !finishedGCJobs.find((f) => f.filename === u.filename)).length > 0 &&
				uploadingFiles.filter((u) => !finishedJobs.find((f) => f.media_id === u.media_id)).length > 0);
		if (isEncoding) {
			setOpenUploadStatusDialog(true);
			return;
		}
		dispatch(setShowUploadMediasDialog(true));
	}, [encodingFiles, encodingGCFiles, uploadingFiles, dispatch, finishedGCJobs, finishedJobs]);

	const renderUploadButton = () => {
		if (uploadProgressStatus === 'processing') {
			return (
				<div className="progress-bar" onClick={handleUploadButtonClick}>
					<label className="plabel">
						<CloudUpload style={{ width: '16px', height: '16px', marginTop: '2px' }} />
						&nbsp;&nbsp;{t('HEADER_UPLOAD_STATUS_PROGRESS_NOW_PROCESSING')}
					</label>
					<div className="filler" style={{ width: '100%' }} />
				</div>
			);
		}

		if (uploadProgressStatus === 'uploading') {
			return (
				<div className="progress-bar" onClick={handleUploadButtonClick}>
					<label className="plabel">
						<CloudUpload style={{ width: '16px', height: '16px', marginTop: '2px' }} />
						&nbsp;&nbsp;{t('COMMON_UPLOADING')}
						{uploadPercentage?.[0]?.percentage ? ` (${uploadPercentage?.[0]?.percentage}%)` : ''}
					</label>
					<div className="filler" style={{ width: uploadPercentage?.[0]?.percentage + '%' }} />
				</div>
			);
		}

		const isEncoding =
			(encodingFiles ?? []).length > 0 ||
			(encodingGCFiles ?? []).length > 0 ||
			(uploadingFiles.filter((u) => !finishedGCJobs.find((f) => f.filename === u.filename)).length > 0 &&
				uploadingFiles.filter((u) => !finishedJobs.find((f) => f.media_id === u.media_id)).length > 0);

		return (
			<div className={joinClassNames(['uploadButtonWrapper', isEncoding ? 'uploadButtonWrapper--encoding' : ''])}>
				{!isEncoding && (
					<IconButton onClick={handleUploadButtonClick} className="uploadButton" title="Upload">
						<CloudUpload style={{ width: '16px', height: '16px', marginTop: '2px' }} />
					</IconButton>
				)}
				{isEncoding && (
					<button onClick={handleUploadButtonClick} className="uploadButton">
						<CircularProgress variant="determinate" size={20} style={{ color: '#eeeeee' }} value={100} />
						<div className="circular-wrapper">
							<CircularProgress variant="indeterminate" size={20} style={{ color: '#1a90ff' }} />
						</div>
					</button>
				)}
			</div>
		);
	};

	const checkShowHeader = (location: any) => {
		const currentLocation = location.hash === '' ? location.pathname : location.hash;
		const shouldNotShowHeader =
			currentLocation.match('liveManager') ||
			currentLocation.match('interactiveManager') ||
			currentLocation.match('speechToText') ||
			currentLocation.match('viewerEngagementModerator') ||
			(currentLocation.match('interact') &&
				((currentLocation.match('scenes') && currentLocation.match('edit')) || currentLocation.match('chart')));
		setShouldShowHeader(!shouldNotShowHeader);
	};

	const checkEncodingProcess = useCallback(
		(flows: any[], force = false) => {
			checkForOngoingGCEncoding();
			if (flows.length > 0) {
				checkForOngoingEncoding();
			}

			const { accountId, intervalId } = onGoingEncodingIntervalId?.current ?? {};
			if (force || !onGoingEncodingIntervalId?.current || accountId !== defaultAccountId) {
				intervalId && clearInterval(intervalId);
				const newIntervalId = setInterval(() => {
					checkForOngoingGCEncoding();
					if (flows.length > 0) {
						checkForOngoingEncoding();
					}
				}, 10000);

				onGoingEncodingIntervalId.current = {
					intervalId: newIntervalId,
					accountId: defaultAccountId,
				};
			}
		},
		[checkForOngoingEncoding, checkForOngoingGCEncoding, defaultAccountId]
	);

	const { uploadVideoByUrl } = useUploadingVideoByUrl();
	const handleOnMessage = useCallback(
		(event: MessageEvent) => {
			if (event.data?.type === 'univid_video_export' && event.data?.content) {
				const { webinarInformation, content } = event.data;
				uploadVideoByUrl({
					mediaUrl: content,
					mediaTitle: webinarInformation?.name || 'Media from Webinar',
					mediaDescription: webinarInformation?.descriptionPlainText ?? '',
					successMessage: t('MEDIA_LIBRARY_NOTIFICATION_GO_TO_LIB_TO_REVIEW_CONTENT'),
					callback: () => {
						setTimeout(() => {
							checkEncodingProcess(qbrickStandardFlows, true);
							setUploadProgressStatus('processing');
						}, 1000);
					},
				});
			}
		},
		[checkEncodingProcess, qbrickStandardFlows, t, uploadVideoByUrl]
	);

	const fetchAccountSetting = useCallback(() => {
		dispatch(getAccountSettings(defaultAccountId)).then((settings: any) => {
			if (settings?.defaultEmails) {
				setDefaultEmails(settings.defaultEmails ?? []);
			}
		});
	}, [defaultAccountId, dispatch]);

	useEffect(() => {
		updateNavKeysBasedOnLocation(window.location);
		checkShowHeader(window.location);

		const historyListener = history.listen((location: any) => {
			updateNavKeysBasedOnLocation(location);
			checkShowHeader(location);
		});
		window.addEventListener('message', handleOnMessage, true);

		return () => {
			window.removeEventListener('message', handleOnMessage, true);
			onGoingEncodingIntervalId?.current?.intervalId &&
				clearInterval(onGoingEncodingIntervalId.current.intervalId);
			historyListener();
		};
	}, []);

	useEffect(() => {
		const init = async () => {
			if (!(window as any).CCEverywhere || ccEverywhere) {
				return;
			}
			const newCcEverywhere = await AdobeExpressUtils.initializeCcEverywhere();
			dispatch(setCCEverywhere(newCcEverywhere));
		};
		init();
	}, [ccEverywhere, dispatch]);

	useEffect(() => {
		if (
			uploadingFiles?.length &&
			uploadingFiles.every((f) => f.type.includes('image')) &&
			uploadProgressStatus !== 'uploaded' &&
			uploadProgressStatus !== ''
		) {
			setTimeout(() => {
				setUploadProgressStatus('uploaded');
			});
		}

		if (uploadPercentage.length === 0 && uploadProgressStatus === 'uploading' && uploadingFiles?.length) {
			setTimeout(() => {
				setUploadProgressStatus('uploaded');
			}, 1000);
		}

		if (uploadPercentage?.[0] && uploadPercentage[0].percentage === 100) {
			setTimeout(() => {
				setUploadProgressStatus('processing');
			}, 1000);
		}

		if (uploadProgressStatus === 'processing') {
			setTimeout(() => {
				setUploadProgressStatus('uploaded');
			}, 3000);
		}

		if (uploadProgressStatus === 'uploaded') {
			setTimeout(() => {
				setUploadProgressStatus('');
				dispatch(refreshMedias());
				dispatch(preventUserFromCloseWindow(false));
			});
		}

		if (
			uploadProgressStatus === '' &&
			uploadPercentage?.[0] &&
			uploadPercentage[0].percentage >= 0 &&
			uploadPercentage[0].percentage < 100
		) {
			setUploadProgressStatus('uploading');
			dispatch(preventUserFromCloseWindow(true));
		}
	}, [dispatch, uploadPercentage, uploadProgressStatus, uploadingFiles]);

	const fetchUploadingFlows = useCallback(
		(callback?: (_flows: any[]) => void) => {
			dispatch(loadUploadingFlows(defaultAccountId)).then((data: any) => {
				const newQbrickStandardFlows =
					!data || typeof data === 'string'
						? []
						: (data?.flows ?? []).filter((f: any) => f.id === FLOWS.qbrickStandard);

				dispatch(setQbrickStandardFlowsInReducer(newQbrickStandardFlows));
				callback?.(newQbrickStandardFlows);
			});
		},
		[defaultAccountId, dispatch]
	);

	useEffect(() => {
		if (onGoingEncodingIntervalId?.current?.accountId === defaultAccountId) {
			return;
		}

		if (defaultAccountId && shouldShowHeader) {
			dispatch(loadApps(defaultAccountId));
			fetchUploadingFlows(checkEncodingProcess);
		} else {
			onGoingEncodingIntervalId?.current?.intervalId &&
				clearInterval(onGoingEncodingIntervalId.current?.intervalId);
		}
	}, [checkEncodingProcess, defaultAccountId, dispatch, fetchUploadingFlows, shouldShowHeader]);

	useEffect(() => {
		fetchAccountSetting();
	}, [defaultAccountId]);

	return (
		<HeaderContext.Provider value={contextValue}>
			{!window.location.href.includes('/details') && (
				<Helmet>
					<title>{t('COMMON_QBRICK_PAGE_TITLE')}</title>
				</Helmet>
			)}
			<CssBaseline />
			{shouldShowHeader && (
				<>
					<header className="header-container">
						{screenType !== customScreenType.mobile && screenType !== customScreenType.small && (
							<HomeLogo
								className="home-button"
								onClick={() =>
									history.push(
										buildRoutePath(
											screenRoutes.DASHBOARD,
											ACCOUNT_ID_ROUTE_PLACEHOLDER,
											defaultAccountId
										)
									)
								}
							/>
						)}
						<div className="left-items-container">
							<HeaderMainMenu
								items={mainMenuItems}
								onItemClick={onMainMenuClick}
								selectedItemKey={selectedMenuItem}
								screenType={screenType}
							/>
						</div>
						<div className="right-items-container">
							<UploadStatusDialog
								onOpenUploadStatusDialog={handleUploadButtonClick}
								triggerNode={renderUploadButton()}
								visible={openUploadStatusDialog}
								onClose={() => setOpenUploadStatusDialog(false)}
								uploadingFiles={uploadingFiles}
								encodingFiles={encodingFiles}
								finishedJobs={finishedJobs}
								encodingGCFiles={encodingGCFiles}
								finishedGCJobs={finishedGCJobs}
								onClearCompletedFiles={onClearCompletedFiles}
							/>

							<UserInformation
								mobile={
									screenType === customScreenType.mobile ||
									screenType === customScreenType.small ||
									screenType === customScreenType.tablet
								}
								history={history}
							/>
						</div>
						<UploadMediaDialog
							onUploadMedias={() => checkEncodingProcess(qbrickStandardFlows, true)}
							emails={defaultEmails}
						/>
					</header>

					<PermissionInformationDialog
						currentPermissions={permissions}
						permission={permissionInformationDialog}
						onClose={() => dispatch(setPermissionInformationDialog(false))}
					/>
				</>
			)}
		</HeaderContext.Provider>
	);
};

export default MainHeader;
