import { Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ReactRouter from 'react-router';

import { clearBreadCrumbLocationArray, updateBreadCrumbLocationArray } from '../../../actions/breadCrumbActions';
import { showMessage } from '../../../actions/globalActions';
import { getMediaQueryDetails } from '../../../actions/publishActions';
import { mediaNames, messageTypes, NOCATALOG } from '../../../constants/mediaConstants';
import { defaultPaginationRoutePath } from '../../../constants/paginationConstant';
import { screenRoutes } from '../../../constants/routesPath';
import { KEYS } from '../../../layout/nav/navigationItems';
import { isRequestFail } from '../../../services/inspectScreenHelperService';
import { getDetails } from '../../../services/mediaDisplayService';
import InspectPlayerPreviewArea from '../InspectScreen/InspectSections/InspectPlayerPreviewArea';
import useFetchMediaDetails from '../InspectScreen/useFetchMedia';

import MediaDetail from './MediaDetailComponent/MediaDetail';
import { MediaDetailUtils } from './utils';

import './InspectMedia.scss';

type InspectMediaProps = ReactRouter.RouteComponentProps & {};
const baseClassName = 'inspect-media';
const miniBaseClassName = 'inspect-media-mini';

const InspectMedia = ({ match }: InspectMediaProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const inspectingMediaData = useSelector((state: any) => state.inspectScreenReducer.inspectingMediaData);
	const defaultAccountId = useSelector((state: any) => state.session.defaultAccountId);

	const [selectedTab, setSelectedTab] = useState<string>(MediaDetailUtils.ExpandMenuItems[0].value);
	const [refreshData, setRefreshData] = useState<boolean>(true);
	const [selectedPlayerConfig, setSelectedPlayerConfig] = useState<string>("");

	const currentMediaId = useMemo(() => {
		return inspectingMediaData?.id || (match.params as any).mediaId;
	}, [inspectingMediaData?.id, match.params]);

	const { mediaDetails } = useFetchMediaDetails({
		mediaId: currentMediaId,
		mediaName: mediaNames.medias,
		refreshData,
		resetRefreshData: () => setRefreshData(false),
		errorMessage: t('COMMON_MEDIA_NOT_FOUND_OR_NO_PERMISSION'),
	});

	const getCatalogDetails = useCallback(
		(itemId: string, callback?: (_media: any) => void) => {
			dispatch(getMediaQueryDetails(defaultAccountId, itemId, mediaNames.catalogs)).then((media: any) => {
				if (isRequestFail(this, media)) {
					dispatch(showMessage(t('BREADCRUMB_FAILED_FETCH_CATALOG_DETAILS'), messageTypes.error));
					return;
				} else {
					callback?.(getDetails(media));
				}
			});
		},
		[defaultAccountId, dispatch, t]
	);

	const getDetailsForBreadCrumb = useCallback(
		(itemId: string, customHref: any, breadCrumbLocationArray: any[]) => {
			if (itemId === NOCATALOG) {
				return;
			}

			getCatalogDetails(itemId, (media: any) => {
				if (typeof media == 'string') {
					dispatch(showMessage(media + 'for breadcrumb', messageTypes.error));
					return;
				}
				breadCrumbLocationArray.unshift({
					href: `${customHref}${itemId}${defaultPaginationRoutePath}`,
					text: media.title,
				});
				dispatch(updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray));
				if (media && media.parentId) {
					getDetailsForBreadCrumb(media.parentId, customHref, breadCrumbLocationArray);
				}
			});
		},
		[dispatch, getCatalogDetails]
	);

	const updateBreadCrumb = useCallback(() => {
		dispatch(clearBreadCrumbLocationArray());
		const mediaHref = screenRoutes.PUBLISH_MEDIA_LIBRARY + '/medias/';
		const breadCrumbLocationArray = [];
		if (mediaDetails) {
			breadCrumbLocationArray.push({
				href: mediaHref + mediaDetails.id + '/' + 'detail',
				text: mediaDetails.title,
			});
			dispatch(updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray));
			if (mediaDetails.parentId) {
				const customHref = screenRoutes.PUBLISH_MEDIA_LIBRARY + '/catalogs/';
				getDetailsForBreadCrumb(mediaDetails.parentId, customHref, breadCrumbLocationArray);
			}
		}
	}, [dispatch, getDetailsForBreadCrumb, mediaDetails]);


	const killPlayer = () => {
		const playerRef = document.querySelector('#media-detail-player-preview');
		if (playerRef) {
			playerRef.innerHTML = '';
		}
		(window as any).QplayerLoader.killInteractivePlayer();
	};

	const initPlayer = () => {
		if (
			(window as any).QplayerLoader.initiateInteractivePlayer &&
			typeof (window as any).QplayerLoader.initiateInteractivePlayer === 'function'
		) {
			(window as any).QplayerLoader.initiateInteractivePlayer();
		}
	};

	const onPlayerConfigChange = (selectedConfig: any) => {
		setSelectedPlayerConfig(selectedConfig?.id);
		killPlayer();
		setTimeout(initPlayer);
	}

	useEffect(() => {
		if (!mediaDetails) {
			return;
		}
		updateBreadCrumb();
	}, [updateBreadCrumb, refreshData, mediaDetails]);

	useEffect(() => {
		return () => killPlayer();
	}, []);

	return (
		<>
			<Helmet>
				<title>
					{mediaDetails && mediaDetails.title
						? `${t('COMMON_INSPECT')} - ${mediaDetails.title}`
						: t('COMMON_QBRICK_PAGE_TITLE')}
				</title>
			</Helmet>
			<div className={`${baseClassName} ${baseClassName}__container`}>
				<Tabs
					value={selectedTab}
					onChange={(_e, value) => setSelectedTab(value)}
					orientation="vertical"
					className={`${baseClassName}__tab-bar ${miniBaseClassName}__tab-bar`}
					TabIndicatorProps={{
						style: {
							color: '#ffffff',
							backgroundColor: 'transparent',
						},
					}}
				>
					{MediaDetailUtils.ExpandMenuItems.map((item) => (
						<Tab
							key={item.value}
							value={item.value}
							icon={selectedTab === item.value ? item.activeIcon : item.icon}
							style={
								selectedTab === item.value
									? { backgroundColor: '#126CFC1A', opacity: '1' }
									: { opacity: '1' }
							}
							label={item.label}
						/>
					))}
				</Tabs>

				{mediaDetails?.id && (
					<InspectPlayerPreviewArea
						mediaId={mediaDetails?.id}
						mediaDetails={mediaDetails}
						placementType={'medias'}
						embedOnly
						onPlayerConfigChange={onPlayerConfigChange}
					/>
				)}

				<div className="inspect-media__detail-container">
					<div className={`${baseClassName}__player-container`}>
						<div style={{ position: 'relative', height: '100%', width: '100%' }}>
							<div
								id={'media-detail-player-preview'}
								style={{ position: 'absolute', top: '0', left: '0' }}
								title={mediaDetails?.title}
								data-qplayer-account-id={defaultAccountId}
								data-qplayer-media-id={mediaDetails?.id}
								data-qplayer-preset-id={selectedPlayerConfig}
							></div>
						</div>
					</div>
					<div className="inspect-media__detail-container--section">
						<MediaDetail mediaDetails={mediaDetails} isExpandedScreen />
					</div>
				</div>
			</div>
		</>
	);
};

export default InspectMedia;
