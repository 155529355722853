import { Tab, Tabs } from '@material-ui/core';
import React from 'react';

import InspectPlayerPreviewArea from '../../InspectScreen/InspectSections/InspectPlayerPreviewArea';
import MediaDetail from '../MediaDetailComponent/MediaDetail';
import { MediaDetailUtils } from '../utils';

interface IMediaContentProps {
	baseClassName: string;
	mediaDetails: any;
	contentContainerHTMLRef: any;
	mediaDetailRef: any;
	selectedTab: string;
	setSelectedTab: any;
	handleOnDataChanges: () => void;
	onPlayerConfigChange?: (_selectedConfig: any) => void
}

const MediaContent = ({
	baseClassName,
	mediaDetails,
	contentContainerHTMLRef,
	mediaDetailRef,
	selectedTab,
	setSelectedTab,
	handleOnDataChanges,
	onPlayerConfigChange
}: IMediaContentProps) => {
	return (
		<div className={`${baseClassName}__media-content-container`} ref={(r) => (contentContainerHTMLRef.current = r)}>
			<Tabs
				value={selectedTab}
				onChange={(_e, value) => setSelectedTab(value)}
				orientation="vertical"
				className={`${baseClassName}__tab-bar`}
				TabIndicatorProps={{
					style: {
						color: '#ffffff',
						backgroundColor: 'transparent',
					},
				}}
			>
				{MediaDetailUtils.MenuItems.map((item) => (
					<Tab
						key={item.value}
						value={item.value}
						icon={selectedTab === item.value ? item.activeIcon : item.icon}
						style={
							selectedTab === item.value
								? { backgroundColor: '#126CFC1A', opacity: '1' }
								: { opacity: '1' }
						}
						label={item.label}
					/>
				))}
			</Tabs>
			<div className="inspect-media__detail-container">
				{selectedTab === 'detail' && (
					<MediaDetail
						mediaDetails={mediaDetails}
						onUpdatingData={handleOnDataChanges}
						ref={mediaDetailRef}
					/>
				)}
				<InspectPlayerPreviewArea
					mediaId={mediaDetails?.id}
					mediaDetails={mediaDetails}
					placementType={'medias'}
					embedOnly
					onPlayerConfigChange={onPlayerConfigChange}
					hide={!(selectedTab === 'share' && mediaDetails?.id)}
				/>
			</div>
		</div>
	);
};

export default MediaContent;
