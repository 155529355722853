import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	DEFAULT_LAYOUT_TEMPLATE,
	WIDGET_TYPES,
	DEFAULT_WIDGETS_DATA,
	WIDGET_EVENT_CLICK,
} from '../../../constants/scenarioConstant';
import {
	createNewScenarioService,
	addNewSceneToScenarioService,
	addNewWidgetService,
} from '../../../services/scenarioService';
import { generateUUID } from '../../../utils/commonUtil';
import { getDefaultWidgetData } from '../utils/helper';
import { cloneDeep } from 'lodash';

const settingMessageInit = {
	role: 'system' as const,
	content:
		'You are Blubby - the helpful assistant in creating interactive video. You sometimes ask user if they need something else. You like to make jokes.',
};

const assistantMessageInit = {
	role: 'assistant' as const,
	assistantMessage: "Tjena! I'm Blubby, your assistant. What kind of video you want to create?",
	content: '',
};

const scenesSchemaForManuScript = {
	type: 'json_schema',
	json_schema: {
		name: 'get_video',
		description: 'Fetches all scenes for a video.',
		strict: true,
		schema: {
			type: 'object',
			properties: {
				video_name: {
					type: 'string',
					description: 'The name of video.',
				},
				comment_message: {
					type: 'string',
					description: 'The return message from assistant after finish the job or provide useful information',
				},
				scenes: {
					type: 'array',
					description: 'The list of scene',
					items: {
						type: 'object',
						id: {
							type: 'string',
							description: 'The scene unique id',
						},
						name: {
							type: 'string',
							description: 'The scene name',
						},
						voice_over: {
							type: 'string',
							description: 'The scene voice over. Should be at least 100 characters',
						},
						footage: {
							type: 'string',
							description:
								'The description about how the video look like. Should be at least 100 characters ',
						},
					},
					required: ['voice_over', 'footage'],
				},
			},
			additionalProperties: false,
			required: ['video_name', 'comment_message'],
		},
	},
};

const jsonSchemaForConversation = {
	type: 'json_schema',
	json_schema: {
		name: 'normal_conversation',
		description: 'Normal conversation between user and assistant which is Blubby',
		strict: true,
		schema: {
			type: 'object',
			properties: {
				user_action: {
					type: 'string',
					enum: ['chat', 'modifying_video'],
					description:
						'If user want to change video or update scenes or content of the scenes or anything related to video itself. modifying_video should be returned.',
				},
				comment_message: {
					type: 'string',
					description: 'Message from assistant.',
				},
			},
			additionalProperties: false,
			required: ['user_action', 'comment_message'],
		},
	},
};

const fetchingDataFromOpenAi = async (
	messages: MessageType[],
	responseFormat: typeof jsonSchemaForConversation | typeof scenesSchemaForManuScript
) => {
	const response = await fetch('https://api.openai.com/v1/chat/completions', {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization:
				'Bearer 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',
		},
		body: JSON.stringify({
			model: 'gpt-4o',
			messages: messages,
			response_format: responseFormat,
		} as any),
	});

	return response;
};

type MessageType = {
	role: 'system' | 'user' | 'assistant';
	content: string;
	assistantMessage?: string;
};

export const useQbrickAI = (onClose: () => void) => {
	const { defaultAccountId } = useSelector((state) => (state as any).session);
	const [conversationData, setConversationData] = useState<MessageType[]>([]);
	const [videoData, setVideoData] = useState<any>(null);
	const [originalVideoData, setOriginalVideoData] = useState<any>(null);
	const [isSubmitting, setIsSubmit] = useState('');

	const submitChatting = async () => {
		setIsSubmit('qbrickAI');

		const isVideoDataModified = JSON.stringify(videoData) !== JSON.stringify(originalVideoData);

		const conversationToSubmit = conversationData;

		if (isVideoDataModified) {
			conversationToSubmit.push({
				role: 'system',
				content: `User has modified the video data. Assistant alway verify and confirm about the updated video data. This is the new video data in json format: ${JSON.stringify(
					videoData
				)}`,
			});
		}

		const response = await fetchingDataFromOpenAi(conversationToSubmit, jsonSchemaForConversation);

		const result = await response.json();

		if (!result?.choices?.[0]?.message?.content) {
			return;
		}

		const data = JSON.parse(result?.choices?.[0]?.message?.content);

		const userAction = data.user_action;

		if (userAction === 'chat') {
			const newAssistantMessage = {
				role: 'assistant' as const,
				content: result?.choices?.[0]?.message?.content,
				assistantMessage: data.comment_message,
			};
			setConversationData([...conversationToSubmit, newAssistantMessage]);
			setIsSubmit('');
			return;
		}

		if (userAction === 'modifying_video') {
			const result = await submitGenerateManuScript();
			const manuscriptData = JSON.parse(result?.choices?.[0]?.message?.content);
			const newAssistantMessage = {
				role: 'assistant' as const,
				content: result?.choices?.[0]?.message?.content,
				assistantMessage: manuscriptData.comment_message,
			};
			setConversationData((prev) => [...prev, newAssistantMessage]);
			setIsSubmit('');
			setVideoData({ ...manuscriptData });
			setOriginalVideoData({ ...manuscriptData });
			return;
		}
	};

	const submitGenerateManuScript = async () => {
		const response = await fetchingDataFromOpenAi(conversationData, scenesSchemaForManuScript);

		const result = await response.json();

		if (!result?.choices?.[0]?.message?.content) {
			return;
		}

		return result;
	};

	const handleCreateInteract = async () => {
		if (!videoData) {
			return;
		}
		setIsSubmit('createInteract');
		const interactId = generateUUID();
		const assetId = generateUUID();
		const defaultSceneId = generateUUID();

		const createdInteractRefId = await createNewScenarioService(
			defaultAccountId,
			videoData.video_name ?? 'Untitle',
			interactId,
			assetId,
			{
				title: videoData.video_name,
			},
			{ sceneId: defaultSceneId }
		);

		if (!createdInteractRefId || createdInteractRefId.length === 0) {
			throw new Error();
		}

		const allPromises: any[] = [];

		videoData.scenes.forEach((scene: any, index: number) => {
			const { footage, name, voice_over } = scene;

			const isDefaultScene = index === 0;

			const sceneId = isDefaultScene ? defaultSceneId : generateUUID();
			const layoutId = generateUUID();

			const sceneBody = {
				name,
				events: [],
				metadata: {
					position: { x: 400 * index, y: 50 },
				},
				layout: {
					id: layoutId,
					type: 'desktop',
					layoutTemplate: DEFAULT_LAYOUT_TEMPLATE,
				},
			};

			allPromises.push(addNewSceneToScenarioService(defaultAccountId, interactId, sceneId, sceneBody));

			// BG widget video
			const widgetBody = {
				name: 'backgroundVideo',
				widgetTemplateId: generateUUID(),
				start: '00:00:00',
				end: '00:00:05',
				type: WIDGET_TYPES.WIDGET_TYPE_VIDEO,
				settings: {
					mediaId: '66b1b49f-d3b3-4541-8a09-bbf5c6cb92c3',
				},
				style: {
					...DEFAULT_WIDGETS_DATA.style,
				},
				events: [],
			};

			allPromises.push(
				addNewWidgetService(defaultAccountId, interactId, sceneId, layoutId, '1', generateUUID(), widgetBody)
			);

			// BG Text video
			const template = getDefaultWidgetData(
				generateUUID(),
				'textWidget',
				WIDGET_TYPES.WIDGET_TYPE_TEXT,
				{ x: 0, y: 0 },
				1
			);

			const newStyle = {
				...template.style,
				headerLabel: {
					...(template.style as any).headerLabel,
					text: `Background Video: ${footage}`,
				},
				textLabel: {
					...(template.style as any).textLabel,
					text: `Voice over: ${voice_over}`,
				},
				coordinate: {
					x: 412,
					y: 527,
				},
				dimension: {
					h: 373,
					w: 777,
				},
			};
			const textWidgetBody = {
				name: 'textWidget',
				widgetTemplateId: generateUUID(),
				start: '00:00:00',
				end: '00:00:05',
				type: WIDGET_TYPES.WIDGET_TYPE_TEXT,
				settings: {},
				style: newStyle,
				events: [
					{
						id: generateUUID(),
						type: WIDGET_EVENT_CLICK,
						actions: [],
					},
				],
			};

			allPromises.push(
				addNewWidgetService(
					defaultAccountId,
					interactId,
					sceneId,
					layoutId,
					'1',
					generateUUID(),
					textWidgetBody
				)
			);
		});

		setTimeout(() => {
			Promise.all(allPromises)
				.then((resolve) => {
					if (resolve) {
						resetData();
						onClose();
					}
				})
				.catch((error) => {
					resetData();
					onClose();
					throw error;
				});
		}, 3000);
	};

	const handleUserSendMessage = (userText: string) => {
		const userMessage = {
			role: 'user' as const,
			content: userText,
		};
		setConversationData((prev) => [...prev, userMessage]);
	};

	const handleVideoDataChange = (index: number, propertyKey: string, data: string) => {
		const newVideoData = cloneDeep(videoData);
		newVideoData.scenes[index][propertyKey] = data;
		setVideoData(newVideoData);
	};

	const resetData = () => {
		setConversationData([settingMessageInit, assistantMessageInit]);
		setVideoData(null);
		setIsSubmit('');
	};

	useEffect(() => {
		//init
		setConversationData([settingMessageInit, assistantMessageInit]);
	}, []);

	useEffect(() => {
		if (conversationData.length < 2 || conversationData[conversationData.length - 1]?.role !== 'user') {
			return;
		}

		submitChatting();
	}, [conversationData]);

	return {
		videoData,
		conversationData,
		isSubmitting,
		setConversationData,
		handleUserSendMessage,
		handleCreateInteract,
		handleVideoDataChange,
		resetData,
	};
};
