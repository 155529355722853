import React, { Component, createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router';
import { Route, HashRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

import Header from './header/Header';
import InspectMediaMini from '../screens/PublishScreen/InspectMediaScreen/InspectMediaMini';
import SnackBar from '../reusable/Snackbar/Snackbar';
import Global from '../components/Global/Global';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import SSOScreen from '../screens/LoginScreen/SSOScreen';
import Dashboard from '../screens/DashboardScreen/Dashboard';
import ProfileScreen from '../screens/ProfileScreen/ProfileScreen';
import AppScreen from '../screens/AppScreen/AppScreen';
import InspectMediaScreen from '../screens/PublishScreen/InspectScreen/InspectMediaScreen';
import InspectMedia from '../screens/PublishScreen/InspectMediaScreen/InspectMedia';
import InspectCatalogScreen from '../screens/PublishScreen/InspectScreen/InspectCatalogScreen';
import InspectPlaylist from '../screens/PublishScreen/InspectScreen/InspectPlaylist';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, PAGINATION_ROUTE_EXTENSION, screenRoutes } from './../constants/routesPath';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ScenarioMainChart from '../screens/Scenario/ScenarioMainChart';
import InspectScenarioScreen from '../screens/Scenario/InspectScenarioScreen';
import ScenarioEditor from '../screens/Scenario/SceneEditor/SceneEditor';

import AnalyticsBrowsersScreen from '../screens/AnalyticsScreen/AnalyticsBrowsersScreen';
import AnalyticsBrowsersTypeScreen from '../screens/AnalyticsScreen/AnalyticsBrowsersTypeScreen';
import AnalyticsCitiesScreen from '../screens/AnalyticsScreen/AnalyticsCitiesScreen';
import AnalyticsCountriesScreen from '../screens/AnalyticsScreen/AnalyticsCountriesScreen';
import AnalyticsDeviceTypesScreen from '../screens/AnalyticsScreen/AnalyticsDeviceTypesScreen';
import AnalyticsGeneralPerformanceScreen from '../screens/AnalyticsScreen/AnalyticsGeneralPerformanceScreen';
import AnalyticsOperatingSystemsScreen from '../screens/AnalyticsScreen/AnalyticsOperatingSystemsScreen';
import AnalyticsProtocolScreen from '../screens/AnalyticsScreen/AnalyticsProtocolScreen';
import AnalyticsRealTimeScreen from '../screens/AnalyticsScreen/AnalyticsRealTimeScreen';
import AnalyticsRegionsScreen from '../screens/AnalyticsScreen/AnalyticsRegionsScreen';
import AnalyticsSocialScreen from '../screens/AnalyticsScreen/AnalyticsSocialScreen';
import AnalyticsStorageScreen from '../screens/AnalyticsScreen/AnalyticsStorageScreen';
import AnalyticsUrlPerformanceScreen from '../screens/AnalyticsScreen/AnalyticsUrlPerformanceScreen';
import AnalyticsVideoPerformanceScreen from '../screens/AnalyticsScreen/AnalyticsVideoPerformanceScreen';
import AnalyticsVideoPerformanceByMediaIdScreen from '../screens/AnalyticsScreen/AnalyticsVideoPerformanceByMediaIdScreen';
import { checkPermissionGrantedFor } from '../services/componentReusableService';
import { mediaLibraryKeys, mediaNames } from '../constants/mediaConstants';
import MediaLibrary from '../screens/PublishScreen/MediaLibrary/MediaLibrary';
import ScreenRecorderConfigModal from '../components/ScreenRecoder/ScreenRecorderConfigModal';

import { defaultPaginationRoutePath } from '../constants/paginationConstant.js';

import '../screens/PublishScreen/PublishScreen.scss';
import './../responsive.scss';
import './App.scss';
import { buildRoutePath } from '../utils/commonUtil';
import { switchToAccount } from '../actions/sessionActions';
import { localStorageKeys } from '../constants/localStorageKeys';

const theme = createTheme({
	typography: { fontFamily: 'Poppins', button: { textTransform: 'none', fontSize: '13px' } },
	palette: { primary: { main: '#126CFC', dark: '#0053d8' } },
});

const CustomRoute = ({
	exact: exact,
	path: path,
	component: Component,
	extraClassName,
	permissionType,
	userPermissions,
	isLoggedIn,
	switchToAccount,
	...otherProps
}) => {
	const neededPermission = permissionType && userPermissions;
	const permissonGranted = neededPermission || checkPermissionGrantedFor(userPermissions, permissionType);

	const defaultAccountId = localStorage.getItem('defaultAccountId');

	if (!isLoggedIn || !defaultAccountId) {
		<Redirect to={screenRoutes.LOGIN} />;
	}

	const defaultFallbackRoutePath = buildRoutePath(
		`${screenRoutes.PUBLISH_ALL_MEDIA}${defaultPaginationRoutePath}`,
		ACCOUNT_ID_ROUTE_PLACEHOLDER,
		defaultAccountId
	);

	if (neededPermission && !permissonGranted) {
		return <Redirect to={defaultFallbackRoutePath} />;
	}

	const {
		computedMatch: { params },
	} = otherProps;

	const { accountId } = params;

	if (!accountId) {
		return <Redirect to={defaultFallbackRoutePath} />;
	}

	if (accountId !== defaultAccountId) {
		const accounts = JSON.parse(localStorage.getItem(localStorageKeys.ACCOUNTS) ?? '[]');
		const selectedAccount = accounts.find((acc) => acc.accountId === accountId);

		if (selectedAccount) {
			localStorage.setItem('defaultAccountId', selectedAccount.accountId);
			localStorage.setItem('defaultAccountName', selectedAccount.name);
			const newPath = Object.keys(params ?? []).reduce((path, currentKey) => {
				if (currentKey === 'accountId') {
					return path.replace(':accountId', selectedAccount.accountId);
				}

				return path.replace(`:${currentKey}`, params[currentKey] ?? '');
			}, `#${path}`);
			switchToAccount(selectedAccount.accountId, selectedAccount.name).then(() => {
				window.location.replace(`${window.location.origin}${newPath}`);
			});
			return '';
		} else {
			return (
				<Redirect
					to={buildRoutePath(
						`${screenRoutes.PUBLISH_ALL_MEDIA}${defaultPaginationRoutePath}`,
						ACCOUNT_ID_ROUTE_PLACEHOLDER,
						defaultAccountId
					)}
				/>
			);
		}
	}

	//check and redirect accountId if not correct

	if (!neededPermission || (neededPermission && permissonGranted)) {
		return (
			<Route
				exact={exact}
				path={path}
				render={(props) => (
					<div className={extraClassName} style={{ width: '100%', height: '100%' }}>
						<Component {...props} {...otherProps} />
					</div>
				)}
			/>
		);
	}
};

class App extends Component {
	constructor(props) {
		super(props);
		// init screenRecord Modal here because we want to this modal persisten when we move around qvp
		this.screenRecordModalRef = createRef(null);
	}

	componentDidMount() {
		const cookiesLanguage = Cookies.get('selected_language');
		const userLanguage = cookiesLanguage || 'en';
		this.props.i18n?.changeLanguage(userLanguage);
	}

	render() {
		const { isLoggedIn, defaultAccountId, switchToAccount } = this.props;

		const NoMatch = ({ location }) => (
			<div>
				<h3>
					Sorry, we could not find <code>{location.pathname}</code>
				</h3>
			</div>
		);

		if (window.location.hostname === 'localhost' || window.location.hostname === 'stage.video.local') {
		} else {
			if (window.location.protocol != 'https:') {
				window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
			}
		}

		return (
			<ThemeProvider theme={theme}>
				<HashRouter>
					<>
						<Route component={Global} />
						<Route component={SnackBar} />
						<Route component={Header} />
						<Switch>
							<Route exact path="/">
								{this.props.isLoggedIn ? (
									<Redirect
										to={buildRoutePath(
											screenRoutes.DASHBOARD,
											ACCOUNT_ID_ROUTE_PLACEHOLDER,
											defaultAccountId
										)}
									/>
								) : (
									<LoginScreen />
								)}
							</Route>
							<Route exact path={screenRoutes.LOGIN}>
								{this.props.isLoggedIn ? (
									<Redirect
										to={buildRoutePath(
											screenRoutes.DASHBOARD,
											ACCOUNT_ID_ROUTE_PLACEHOLDER,
											defaultAccountId
										)}
									/>
								) : (
									<LoginScreen />
								)}
							</Route>

							<Route exact path={'/sso/:callback'} component={SSOScreen} />

							<CustomRoute
								exact
								path={screenRoutes.DASHBOARD}
								component={Dashboard}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							{/* Media routes */}

							<CustomRoute
								exact
								path={`${screenRoutes.PUBLISH_ALL_MEDIA}${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.medias}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={`${screenRoutes.PUBLISH_LIVE_MEDIA}${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.medias}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/medias/:mediaId/detail'}
								component={InspectMediaScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/medias/:mediaId/detail/:menuKey?'}
								component={InspectMediaScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={
									screenRoutes.PUBLISH_MEDIA_LIBRARY +
									'/medias/:mediaId/detail/:menuKey?/:subMenuKey?'
								}
								component={InspectMediaScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							{/* End Media routes */}

							{/* New inspect Media routes */}
							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/medias/:mediaId/expand'}
								component={InspectMedia}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							{/* End new inspect Media routes */}

							{/* Calalog route */}
							<CustomRoute
								exact
								path={`${screenRoutes.PUBLISH_CATALOGS}${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.catalogs}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={`${screenRoutes.PUBLISH_CATALOGS}/:catalogId${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.catalogs}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_CATALOGS + ':catalogId/detail'}
								component={InspectCatalogScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_CATALOGS + '/:catalogId/detail/:menuKey?'}
								component={InspectCatalogScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_CATALOGS + '/:catalogId/detail/:menuKey?/:subMenuKey?'}
								component={InspectCatalogScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							{/* End catalog routes */}

							{/* Playlist routes */}
							<CustomRoute
								exact
								path={`${screenRoutes.PUBLISH_PLAYLISTS}${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.playlists}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_PLAYLISTS + '/:playlistsId/detail'}
								component={InspectPlaylist}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							{/* End Playlist routes */}

							{/* Smart filter routes */}

							<CustomRoute
								exact
								path={`${screenRoutes.PUBLISH_SMART_FILTER}/:filterId${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaLibraryKeys.smartFilter}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							{/* End Smart filter routes */}

							{/* Scenarios routes */}
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={`${screenRoutes.PUBLISH_SCENARIOS}${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.scenarios}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/interact/:scenarioId/chart'}
								recorderModalRef={this.screenRecordModalRef}
								component={ScenarioMainChart}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/interact/:scenarioId/detail'}
								component={InspectScenarioScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/interact/:scenarioId/detail/:menuKey?'}
								component={InspectScenarioScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={
									screenRoutes.PUBLISH_MEDIA_LIBRARY +
									'/interact/:scenarioId/detail/:menuKey?/:subMenuKey?'
								}
								component={InspectScenarioScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={
									screenRoutes.PUBLISH_MEDIA_LIBRARY +
									'/interact/:scenarioId/detail/scenes/:sceneId/edit'
								}
								component={ScenarioEditor}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							{/* End Scenarios routes */}

							{/* Edited media with adobe express routes */}
							<CustomRoute
								exact
								path={`${screenRoutes.PUBLISH_EDITED_MEDIA}${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.editedMedias}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							{/* End edited media with adobe express routes */}

							{/* Recycle bin routes */}
							<CustomRoute
								path={`${screenRoutes.PUBLISH_RECYCLE_BIN}${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.trashedMedias}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_RECYCLE_BIN + '/:mediaId/detail'}
								component={InspectMediaScreen}
								mediaType={mediaNames.trashedMedias}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_RECYCLE_BIN + '/:mediaId/detail/:menuKey?'}
								component={InspectMediaScreen}
								mediaType={mediaNames.trashedMedias}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_RECYCLE_BIN + '/:mediaId/detail/:menuKey?/:subMenuKey?'}
								component={InspectMediaScreen}
								mediaType={mediaNames.trashedMedias}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							{/* End recycle bin routes */}

							{/* Portal routes */}

							<CustomRoute
								exact
								path={`${screenRoutes.PUBLISH_PORTALS}${PAGINATION_ROUTE_EXTENSION}`}
								component={MediaLibrary}
								mediaType={mediaNames.portals}
							/>

							{/* End portal routes */}

							{/* Analytics */}
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS}
								component={AnalyticsVideoPerformanceScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								path={screenRoutes.ANALYTICS + '/:mediaId'}
								component={AnalyticsVideoPerformanceByMediaIdScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_BROWSERS}
								component={AnalyticsBrowsersScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_BROWSERS_TYPE}
								component={AnalyticsBrowsersTypeScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_CITIES}
								component={AnalyticsCitiesScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_COUNTRIES}
								component={AnalyticsCountriesScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_DEVICE_TYPE}
								component={AnalyticsDeviceTypesScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_GENERAL_PERFORMANCE}
								component={AnalyticsGeneralPerformanceScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_OPERATING_SYSTEMS}
								component={AnalyticsOperatingSystemsScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_PROTOCOL}
								component={AnalyticsProtocolScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_REAL_TIME}
								component={AnalyticsRealTimeScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_REGIONS}
								component={AnalyticsRegionsScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_SOCIAL}
								component={AnalyticsSocialScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_STORAGE}
								component={AnalyticsStorageScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_URL_PERFORMANCE}
								component={AnalyticsUrlPerformanceScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							{/* Apps */}
							<CustomRoute
								exact
								path={screenRoutes.APPS + '/:appId/:configId'}
								component={AppScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							{/* Settings */}
							<CustomRoute
								exact
								path={screenRoutes.SETTINGS}
								extraClassName="profileSettings__wrapper"
								component={ProfileScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.SETTINGS + '/users'}
								component={ProfileScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.SETTINGS + '/notification'}
								component={ProfileScreen}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.SETTINGS + '/support'}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>
							<CustomRoute
								exact
								path={screenRoutes.SETTINGS + '/players'}
								extraClassName="profileSettings__wrapper"
								component={() => <ProfileScreen selectedProfileBlockIndex="8" />}
								isLoggedIn={isLoggedIn}
								switchToAccount={switchToAccount}
							/>

							{/* handle redirect */}
							<CustomRoute
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY}
								component={() => (
									<Redirect to={`${screenRoutes.PUBLISH_ALL_MEDIA}${defaultPaginationRoutePath}`} />
								)}
							/>

							<Route component={NoMatch} />
						</Switch>
						<Route component={InspectMediaMini} />
					</>
				</HashRouter>
				<ScreenRecorderConfigModal ref={this.screenRecordModalRef} />
			</ThemeProvider>
		);
	}
}
const mapStateToProps = ({ session }) => ({
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
	userPermissions: session.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
	switchToAccount: (accountId, accountName) => dispatch(switchToAccount(accountId, accountName)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(App);
