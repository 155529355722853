export const portalThemeSettingType = {
	Styling: 'Styling',
	Playback: 'Playback',
};

export const portalThemeSettingTypeOrder = {
	Styling: 1,
	Playback: 2,
};

export const stylingKeys = ['Logotype', 'Background color', 'Grid bg color'];

export const playerKeys = ['Default player preset'];
