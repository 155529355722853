import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as H from 'history';

import { SearchContext } from '../../../context/SearchContext';

import { HandlerType } from './utils';
import { ToolBar, ToolBarProps } from './ToolBar';
import AdvancedSearchArea, { SEARCH_CONDITION } from './AdvancedSearchArea';
import { mediaViews } from '../../../constants/mediaConstants';

type SearchAreaProps = ToolBarProps & {
	history: H.History;
	advancedSearchAreaRef?: (_: HTMLElement | null) => void;
	onSearch?: (_query: string) => void;
	onSaveNewSearchFilter?: (_filterId: string) => void;
	onSaveFilterAsPlaylistClick?: () => void;
	getClearSearchHandler?: (_handler: HandlerType) => void;
	setShowActionDialog?: (_key: string) => void;
};

const SearchArea: React.FC<SearchAreaProps> = ({
	history,
	view,
	screenType,
	isMobileView,
	sortType,
	isSelectAll,
	selectedMedias,
	mediaFilters,
	advancedSearchAreaRef: advancedSearchAreaHTMLRef,
	isLiveScreen,
	onSelectAllMediaItemsPerPage,
	onToolbarActionClick,
	showUploadDropDownMenu,
	handleSortTypeChange,
	onMediaFilterChange,
	toolbarRef,
	onSearch,
	onSaveNewSearchFilter,
	onSaveFilterAsPlaylistClick,
	onViewChange,
	getClearSearchHandler: getClearSearchHandlerProps,
	mediaType,
	setShowActionDialog,
}) => {
	const newAdvancedSearchAreaRef = useRef<any>();

	const [searchQuery, setSearchQuery] = useState('');
	const [searchInputValue, setSearchInputValue] = useState('');

	const searchContextValue = useMemo(
		() => ({
			searchInputValue,
			setSearchInputValue,
		}),
		[searchInputValue]
	);

	const handleSearch = useCallback(
		(query: string, searchWithinValue: string, keyword?: string) => {
			let newSearchKeyword = keyword ?? searchInputValue ?? '';
			let newSearchQuery = 'q=';

			if (newSearchKeyword) {
				if (newSearchKeyword.charAt(newSearchKeyword.length - 1) === '*') {
					newSearchKeyword = newSearchKeyword.substring(0, newSearchKeyword.length - 1);
				} else if (
					newSearchKeyword.length > 0 &&
					newSearchKeyword.charAt(0) === '"' &&
					newSearchKeyword.charAt(newSearchKeyword.length - 1) === '"'
				) {
					newSearchKeyword = newSearchKeyword.substring(1, newSearchKeyword.length - 1);
				}
			}

			const newKeyword =
				searchWithinValue === SEARCH_CONDITION.exactKeyword
					? `"${newSearchKeyword}"`
					: searchWithinValue === SEARCH_CONDITION.bestMatch
					? `${newSearchKeyword}*`
					: newSearchKeyword;
			newSearchQuery += encodeURIComponent(newKeyword);

			onSearch?.(newSearchQuery + query);
			setSearchQuery(newSearchQuery + query);
		},
		[searchInputValue, onSearch]
	);

	const onSubmitSearch = useCallback((keyWord: string) => {
		setSearchInputValue(keyWord);
		if (!keyWord) {
			newAdvancedSearchAreaRef?.current?.clearSearch();
		}

		requestAnimationFrame(() => newAdvancedSearchAreaRef?.current?.executeSearch());
	}, []);

	const onLoadSelectedSmartFilter = useCallback((data: any, searchWithinValue: string) => {
		if (data && data.q) {
			data.q = decodeURIComponent(data.q);
			let searchKeyword = data.q.split('=')[1];
			let isQInclude = data.q.includes('q=');
			let isAndIncluded = data.q.includes('AND');

			if (isAndIncluded) {
				let queryArray = data.q.split(' AND ');

				if (isQInclude) {
					searchKeyword = queryArray[0].slice(2);
				} else {
					searchKeyword = queryArray[0];
				}
			} else {
				if (isQInclude) {
					searchKeyword = data.q.slice(2);
				} else {
					searchKeyword = data.q;
				}
			}

			switch (searchWithinValue) {
				case SEARCH_CONDITION.bestMatch:
					searchKeyword = searchKeyword.substring(0, searchKeyword.length - 1);
					break;
				case SEARCH_CONDITION.exactKeyword:
					searchKeyword = searchKeyword.substring(1, searchKeyword.length - 1);
					break;
				default:
					break;
			}

			setSearchInputValue(searchKeyword);
			newAdvancedSearchAreaRef?.current?.toggleAdvancedSearch();
		}
	}, []);

	const onClearSearch = useCallback(() => {
		newAdvancedSearchAreaRef?.current?.clearSearch();
		setSearchInputValue('');
		handleSearch('', SEARCH_CONDITION.bestMatch, '');
	}, [handleSearch]);

	useEffect(() => {
		getClearSearchHandlerProps?.(onClearSearch);
	}, [getClearSearchHandlerProps, onClearSearch]);

	return (
		<SearchContext.Provider value={searchContextValue}>
			<ToolBar
				view={view}
				toolbarRef={toolbarRef}
				screenType={screenType}
				isMobileView={isMobileView}
				sortType={sortType}
				handleSortTypeChange={handleSortTypeChange}
				isSelectAll={isSelectAll}
				selectedMedias={selectedMedias}
				mediaType={mediaType}
				onSelectAllMediaItemsPerPage={onSelectAllMediaItemsPerPage}
				advancedSearchAreaRef={newAdvancedSearchAreaRef}
				onToolbarActionClick={onToolbarActionClick}
				showUploadDropDownMenu={showUploadDropDownMenu}
				onMediaFilterChange={onMediaFilterChange}
				mediaFilters={mediaFilters}
				onSubmitSearch={onSubmitSearch}
				onViewChange={onViewChange}
				isLiveScreen={isLiveScreen}
				setShowActionDialog={setShowActionDialog}
			/>
			{!isMobileView && view !== mediaViews.calendar && (
				<AdvancedSearchArea
					ref={newAdvancedSearchAreaRef}
					searchQuery={searchQuery}
					history={history}
					advancedSearchAreaRef={advancedSearchAreaHTMLRef}
					handleSearch={handleSearch}
					onLoadSelectedSmartFilter={onLoadSelectedSmartFilter}
					onSaveNewSearchFilter={onSaveNewSearchFilter}
					onSaveFilterAsPlaylistClick={onSaveFilterAsPlaylistClick}
				/>
			)}
		</SearchContext.Provider>
	);
};

export default SearchArea;
