import { generateUUID } from '../../../utils/commonUtil';

import {
	getTimelineTimeFromTimeInput,
	getSecondsFromTimelineTime,
	convertTimeToWidgetTime,
	getTimelineTimeFromSeconds,
} from '../../../services/timeStampService';
import {
	WIDGET_EVENT_CLICK,
	WIDGET_TYPE_BUTTON,
	WIDGET_TYPE_IMAGE,
	WIDGET_TYPE_TEXT,
	WIDGET_TYPE_HOTSPOT,
} from '../../../constants/scenarioConstant';
import { generateUIId } from '../../../services/elementHelperService';
import { defaultSceneHeight, defaultSceneWidth } from './ScenarioGraph';

const defaultColor = '#126cfc';
const defaultBGColor = '#00000000';
const defaultFont = 'Poppins';
const defaultFontSize = '20';
const defaultFontWeight = 'normal';
const defaultLineHeight = '120';
const defaultPadding = '10';
const defaultFill = 'fill-box';
const defaultCoordinate = { x: 0, y: 0 };
const defaultType = 'video';
export const defaultRadius = '0';
export const defaultDimensionImage = { w: 150, h: 100 };
export const defaultDimensionButton = { w: 150, h: 100 };
export const defaultRotationValue = 0;
export const defaultIconPosition = 'left';
export const defaultIconSize = '20';
export const defaultIconSpacing = '20';
export const defaultTextAlignment = 'center';
export const defaultContentSpacing = '20';
export const defaultBorderType = 'Solid';
export const defaultBorderWidth = '1';
export const defaultBorderColor = '#ffffff';
export const defaultZIndexValue = 0;

const widgetDefaultData = {
	style: {
		fill: defaultFill,
		coordinate: defaultCoordinate,
		dimension: defaultDimensionButton,
		rotation: defaultRotationValue,
		zIndex: defaultZIndexValue,
		textLabel: {
			text: 'Type something',
			font: defaultFont,
			fontSize: defaultFontSize,
			fontWeight: defaultFontWeight,
			color: '#ffffff',
			backgroundColor: defaultColor,
			hoverFontWeight: 'bold',
			hoverColor: defaultColor,
			hoverBackgroundColor: '#ffffff',
		},
		backgroundSettings: {
			backgroundColor: defaultBGColor,
			hoverBackgroundColor: defaultBGColor,
		},
		transformSettings: {
			borderRadiusLeft: defaultRadius,
			borderRadiusTop: defaultRadius,
			borderRadiusRight: defaultRadius,
			borderRadiusBottom: defaultRadius,
			radiusTop: defaultRadius,
			radiusBottom: defaultRadius,
			radiusRight: defaultRadius,
			radiusLeft: defaultRadius,
			hoverRadiusTop: defaultRadius,
			hoverRadiusBottom: defaultRadius,
			hoverRadiusRight: defaultRadius,
			hoverRadiusLeft: defaultRadius,
			paddingTop: defaultPadding,
			paddingBottom: defaultPadding,
			paddingRight: defaultPadding,
			paddingLeft: defaultPadding,
			hoverPaddingTop: defaultPadding,
			hoverPaddingBottom: defaultPadding,
			hoverPaddingRight: defaultPadding,
			hoverPaddingLeft: defaultPadding,
		},
	},
};

const BUTTON_ICON_CONFIG = {
	position: defaultIconPosition,
	size: defaultIconSize,
	spacing: defaultIconSpacing,
};

export const TEXT_WIDGET_HEADER_CONFIG = {
	text: 'Type something',
	font: defaultFont,
	fontSize: defaultFontSize,
	fontWeight: defaultFontWeight,
	lineHeight: defaultLineHeight,
	color: '#ffffff',
	shadowColor: 'transparent',
};

export const TEXT_WIDGET_PARAGRAPH_CONFIG = {
	text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
	font: defaultFont,
	fontSize: defaultFontSize,
	fontWeight: defaultFontWeight,
	lineHeight: defaultLineHeight,
	color: '#ffffff',
	shadowColor: 'transparent',
};

const TEXT_WIDGET_BACKGROUND_CONFIG = {
	backgroundColor: defaultColor,
	borderType: defaultBorderType,
	borderWidth: defaultBorderWidth,
	borderColor: defaultBorderColor,
	hoverBackgroundColor: defaultColor,
	hoverBorderType: defaultBorderType,
	hoverBorderWidth: defaultBorderWidth,
	hoverBorderColor: defaultBorderColor,
};

export const getDefaultWidgetData = (id, name, type, coordinate, zIndex, ratio) => {
	const defaultData = { ...widgetDefaultData };

	if (coordinate) {
		defaultData.style = { ...defaultData.style, coordinate };
	}

	if (zIndex) {
		defaultData.style = { ...defaultData.style, zIndex };
	}

	if (type === WIDGET_TYPE_IMAGE) {
		defaultData.style = {
			...defaultData.style,
			dimension: { ...defaultDimensionImage, h: defaultDimensionImage.w * ratio },
		};
	}

	if (type === WIDGET_TYPE_BUTTON) {
		defaultData.style = { ...defaultData.style, iconConfig: BUTTON_ICON_CONFIG };
	}

	if (type === WIDGET_TYPE_TEXT) {
		defaultData.style = {
			...defaultData.style,
			textAlignment: defaultTextAlignment,
			contentSpacing: defaultContentSpacing,
			headerLabel: TEXT_WIDGET_HEADER_CONFIG,
			textLabel: TEXT_WIDGET_PARAGRAPH_CONFIG,
			backgroundSettings: TEXT_WIDGET_BACKGROUND_CONFIG,
		};
	}

	if (type === WIDGET_TYPE_HOTSPOT) {
		defaultData.style = {
			...defaultData.style,
			backgroundSettings: {
				backgroundColor: '#ffffff4c',
				hoverBackgroundColor: '#ffffff7f',
			},
		};
	}

	return {
		...defaultData,
		id: id ?? generateUUID(),
		type: type ?? defaultType,
		name: name,
		events: [
			{
				id: generateUIId(),
				type: WIDGET_EVENT_CLICK,
				actions: [],
			},
		],
	};
};

export const hashCode = (str) => str.split('').reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);

export const getCurrentEdgeDisplayBehaviourMap = (edges) => {
	const edgesMap = edges.reduce((edgeMap, edge) => {
		const {
			source,
			target,
			data: { name },
		} = edge;

		const key = `${source}-${target}`;
		const reverseKey = `${target}-${source}`;

		if (!edgeMap[key] && !edgeMap[reverseKey]) {
			edgeMap[key] = [name];
		} else if (edgeMap[key]) {
			edgeMap[key] = [...edgeMap[key], name];
		} else if (edgeMap[reverseKey]) {
			edgeMap[reverseKey] = [...edgeMap[reverseKey], name];
		}

		return edgeMap;
	}, {});

	return edgesMap;
};

export const delayAfterSeconds = (seconds) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, [seconds * 1000]);
	});
};
export const getVideoMediaDurationBySecond = (media, uploadFile) => {
	return new Promise((resolve, reject) => {
		if (!media || !uploadFile) {
			reject(404);
		} else {
			if (uploadFile.type.includes('image')) {
				resolve(media);
			} else {
				let videoElement = document.createElement('video');
				videoElement.preload = 'metadata';

				videoElement.onloadedmetadata = () => {
					window.URL.revokeObjectURL(videoElement.src);
					resolve({ ...media, duration: videoElement.duration });
				};
				videoElement.src = URL.createObjectURL(uploadFile);
			}
		}
	});
};

export const getVideoDurationBySecondFromUrl = (mediaUrl) => {
	return new Promise((resolve, reject) => {
		if (!mediaUrl) {
			reject(404);
		} else {
			let videoElement = document.createElement('video');
			videoElement.preload = 'metadata';

			videoElement.onloadedmetadata = () => {
				window.URL.revokeObjectURL(videoElement.src);
				resolve(videoElement.duration);
			};
			videoElement.src = mediaUrl;
		}
	});
};

export const validWidgetDuration = (widgets, duration) => {
	return widgets.map((widget) => {
		const { start, end } = widget;
		let startInSecond = getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(start));
		let endInSecond = getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(end));

		let validStart = start;
		let validEnd = end;

		if (startInSecond > endInSecond) {
			validStart = end;
			validEnd = start;
			startInSecond = getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(validStart));
			endInSecond = getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(validEnd));
		}

		return {
			...widget,
			start: startInSecond < duration ? validStart : '00:00:00.000',
			end: endInSecond < duration ? validEnd : convertTimeToWidgetTime(getTimelineTimeFromSeconds(duration)),
		};
	});
};

export const getValidWidgetPosition = (value, min, max) => {
	if (value < min) {
		return min;
	}

	if (max && value > max) {
		return max;
	}
	return value;
};

export const checkOverlapScenes = (sceneARect, sceneBRect) => {
	return (
		sceneARect.x1 < sceneBRect.x2 &&
		sceneARect.x2 > sceneBRect.x1 &&
		sceneARect.y1 < sceneBRect.y2 &&
		sceneARect.y2 > sceneBRect.y1
	);
};

export const getNewPositionForScene = (newSceneId, newPosition, nodes) => {
	let isOverlap = true;
	let updatedPosition = { ...newPosition };

	while (isOverlap) {
		isOverlap = nodes.some((compareNode) => {
			return (
				checkOverlapScenes(
					{
						x1: updatedPosition.x,
						y1: updatedPosition.y,
						x2: updatedPosition.x + 250,
						y2: updatedPosition.y + 180,
					},
					{
						x1: compareNode.position.x,
						y1: compareNode.position.y,
						x2: compareNode.position.x + 250,
						y2: compareNode.position.y + 180,
					}
				) && newSceneId !== compareNode.id
			);
		});
		if (isOverlap) {
			updatedPosition = { x: updatedPosition.x + 50, y: updatedPosition.y + 50 };
		}
	}

	return updatedPosition;
};

export const moveViewportTo = (position, flowInstance) => {
	const { clientHeight, clientWidth } = document.getElementsByClassName('react-flow__pane')[0];
	flowInstance.current?.setViewport(
		{
			x: -position.x - 250 / 2 + clientWidth / 2,
			y: -position.y - 180 / 2 + clientHeight / 2,
			zoom: 1,
		},
		{ duration: 800 }
	);
};

export const getHelperLines = (change, nodes, distance = 5) => {
	const defaultResult = {
		horizontal: undefined,
		vertical: undefined,
		snapPosition: { x: undefined, y: undefined },
	};
	const nodeA = nodes.find((node) => node.id === change.id);

	if (!nodeA || !change.position) {
		return defaultResult;
	}

	const nodeABounds = {
		left: change.position.x,
		right: change.position.x + (nodeA.measured?.width ?? defaultSceneWidth),
		top: change.position.y,
		bottom: change.position.y + (nodeA.measured?.height ?? defaultSceneHeight),
		width: nodeA.measured?.width ?? defaultSceneWidth,
		height: nodeA.measured?.height ?? defaultSceneHeight,
	};

	let horizontalDistance = distance;
	let verticalDistance = distance;

	return nodes
		.filter((node) => node.id !== nodeA.id)
		.reduce((result, nodeB) => {
			const nodeBBounds = {
				left: nodeB.position.x,
				right: nodeB.position.x + (nodeB.measured?.width ?? defaultSceneWidth),
				top: nodeB.position.y,
				bottom: nodeB.position.y + (nodeB.measured?.height ?? defaultSceneHeight),
				width: nodeB.measured?.width ?? defaultSceneWidth,
				height: nodeB.measured?.height ?? defaultSceneHeight,
			};

			//  |‾‾‾‾‾‾‾‾‾‾‾|
			//  |     A     |
			//  |___________|
			//  |
			//  |
			//  |‾‾‾‾‾‾‾‾‾‾‾|
			//  |     B     |
			//  |___________|
			const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

			if (distanceLeftLeft < verticalDistance) {
				result.snapPosition.x = nodeBBounds.left;
				result.vertical = nodeBBounds.left;
				verticalDistance = distanceLeftLeft;
			}

			//  |‾‾‾‾‾‾‾‾‾‾‾|
			//  |     A     |
			//  |___________|
			//              |
			//              |
			//  |‾‾‾‾‾‾‾‾‾‾‾|
			//  |     B     |
			//  |___________|
			const distanceRightRight = Math.abs(nodeABounds.right - nodeBBounds.right);

			if (distanceRightRight < verticalDistance) {
				result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
				result.vertical = nodeBBounds.right;
				verticalDistance = distanceRightRight;
			}

			//              |‾‾‾‾‾‾‾‾‾‾‾|
			//              |     A     |
			//              |___________|
			//              |
			//              |
			//  |‾‾‾‾‾‾‾‾‾‾‾|
			//  |     B     |
			//  |___________|
			const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

			if (distanceLeftRight < verticalDistance) {
				result.snapPosition.x = nodeBBounds.right;
				result.vertical = nodeBBounds.right;
				verticalDistance = distanceLeftRight;
			}

			//  |‾‾‾‾‾‾‾‾‾‾‾|
			//  |     A     |
			//  |___________|
			//              |
			//              |
			//              |‾‾‾‾‾‾‾‾‾‾‾|
			//              |     B     |
			//              |___________|
			const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

			if (distanceRightLeft < verticalDistance) {
				result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
				result.vertical = nodeBBounds.left;
				verticalDistance = distanceRightLeft;
			}

			//  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
			//  |     A     |     |     B     |
			//  |___________|     |___________|
			const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

			if (distanceTopTop < horizontalDistance) {
				result.snapPosition.y = nodeBBounds.top;
				result.horizontal = nodeBBounds.top;
				horizontalDistance = distanceTopTop;
			}

			//  |‾‾‾‾‾‾‾‾‾‾‾|
			//  |     A     |
			//  |___________|_________________
			//                    |           |
			//                    |     B     |
			//                    |___________|
			const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

			if (distanceBottomTop < horizontalDistance) {
				result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
				result.horizontal = nodeBBounds.top;
				horizontalDistance = distanceBottomTop;
			}

			//  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
			//  |     A     |     |     B     |
			//  |___________|_____|___________|
			const distanceBottomBottom = Math.abs(nodeABounds.bottom - nodeBBounds.bottom);

			if (distanceBottomBottom < horizontalDistance) {
				result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
				result.horizontal = nodeBBounds.bottom;
				horizontalDistance = distanceBottomBottom;
			}

			//                    |‾‾‾‾‾‾‾‾‾‾‾|
			//                    |     B     |
			//                    |           |
			//  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
			//  |     A     |
			//  |___________|
			const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

			if (distanceTopBottom < horizontalDistance) {
				result.snapPosition.y = nodeBBounds.bottom;
				result.horizontal = nodeBBounds.bottom;
				horizontalDistance = distanceTopBottom;
			}

			return result;
		}, defaultResult);
};
